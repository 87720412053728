<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      @opened="setFormData"
      @close="handleClose"
    >
      <my-form
        id="userInfoForm"
        ref="userInfoForm"
        :col-num="2"
        :items="formQuerDef.items"
        :btns="formQuerDef.btns"
        :rules="formQuerDef.rules"
        @btn-click="btnClick"
      />
    </el-dialog>
  </div>
</template>

<script>
import MyForm from '@/components/trina/form/myform/MyForm';

import { saveUserInfo } from '@/api/modules/account';
export default {
  name: 'UserInfo',
  components: {
    MyForm
  },
  props: {
    title: {
      type: String,
      default: '个人信息'
    },
    deliverId: {
      type: Number
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: this.$store.state.user,
      formQuerDef: {
        rules: {
          userName: [
            { required: true, message: '请输入姓名', trigger: 'blur' }
          ]
        },
        items: [
          { prop: 'id', label: 'id', itemType: 'hidden', defaultValue: '' },
          { prop: 'userName', label: '用户姓名', itemType: 't-input', defaultValue: '' },
          { prop: 'loginName', label: '登录账号', itemType: 't-input', defaultValue: '', readonly: true },
          { prop: 'email', label: '邮箱', itemType: 't-input', defaultValue: '' },
          { prop: 'mobile', label: '电话', itemType: 't-input', defaultValue: '' }
        ],
        btns: [
          {
            label: '保存',
            event: 'save',
            showTooltip: true,
            isValidate: true,
            icon: 'el-icon-add'
          },
          {
            label: '取消',
            event: 'cancel',
            showTooltip: true,
            isValidate: true,
            icon: 'el-icon-cancel'
          }
        ]
      }
    };
  },
  methods: {
    setFormData() {
      const self = this;
      this.$nextTick(() => {
        self.$refs.userInfoForm.params = {
          id: this.$store.state.user.uid,
          userName: this.$store.state.user.name,
          loginName: this.$store.state.user.loginName,
          email: this.$store.state.user.email,
          mobile: this.$store.state.user.mobile
        };
      });
    },
    handleClose() {
      this.showDialog = false;
      this.$emit('update:visible', false);
    },
    // 按钮处理
    btnClick(event, params) {

      const self = this;
      if (event === 'save') {
        saveUserInfo(params).then(response => {
          self.handleClose();
          self.refresh(params);
        });
      } else if (event === 'cancel') {
        this.handleClose();
      }
    },
    refresh(params) {
      this.$store.state.user.name = params.userName;
      this.$store.state.user.loginName = params.loginName;
      this.$store.state.user.email = params.email;
      this.$store.state.user.mobile = params.mobile;
    }
  }
};
</script>

<style scoped>

</style>
