<!--
 * @Date: 2019-12-02 13:56:33
 * @Author: Liuwj
 * @LastEditTime: 2021-03-04 21:03:19
 * 图片展示组件
 -->

 <template>
  <div ref="bgWrapper" class="mt-2">
    <viewer class="main-div" :images="fileList">
      <img
        class="main-div"
        v-for="(item, index) in dataList"
        :key="index"
        :src="`${item}`"
        :height="finalPreviewSize"
        :width="finalPreviewSize"
      />
    </viewer>
  </div>
</template>

<script>
export default {
  name: "TImagePreview",
  props: {
    // 服务器上的图片地址
    dataList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 每行图片的数量
    rowCount: {
      type: Number,
      default: 0,
    },
    // 默认的预览图和上传区域的尺寸
    previewSize: {
      type: Number,
      default: 80,
    },
  },
  mounted() {
    if (this.rowCount > 0) {
      const eWidth = parseFloat(
        window.getComputedStyle(this.$refs.bgWrapper).width
      );
      // console.log(eWidth);
      // console.log(window.getComputedStyle(this.$refs.bgWrapper));

      // const eWidth = this.$refs.bgWrapper.clientWidth
      // 根据每行数量计算图片的显示大小

      this.finalPreviewSize = (eWidth - this.rowCount * 30 - 30) / this.rowCount;
      console.log(this.finalPreviewSize);
    } else {
      // 如果没有设置每行数量 取默认值
      this.finalPreviewSize = this.previewSize;
    }
    this.fileList = this.dataList;
  },
  data() {
    return {
      // 图片数据 界面展示
      fileList: [],
      finalPreviewSize: 80,
      // 文件上传数量限制
      finalMaxCount: 0,
      // 图片上传成功或者改变的之后向父视图回掉的方法名 参数为图片地址信息数组
      successFun: "imgDataChange",
    };
  },
};
</script>
<style>
.main-div {
  /* padding-bottom: 8px; */
  margin-right: 8px;
  /* width: 100%; */
  border-radius: 0.33rem;
}
</style>
