<template>
  <div>
    <el-dialog
      width="560px"
      :title="title"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      @close="handleClose"
    >
      <my-form
        id="userInfoForm"
        ref="userInfoForm"
        :col-num="1"
        :items="formQuerDef.items"
        :btns="formQuerDef.btns"
        :rules="formQuerDef.rules"
        @btn-click="btnClick"
      />
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store';
    import MyForm from '@/components/trina/form/myform/MyForm';

    import {
        changePwd
    } from '@/api/modules/account';

    export default {
        name: 'Pwd',
        components: {
            MyForm
        },
        props: {
            title: {
                type: String,
                default: '修改密码'
            },
            deliverId: {
                type: Number
            },
            visible: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                formQuerDef: {
                    rules: {
                      oldPwd: [{
                            required: true,
                            message: '请输入原始密码',
                            trigger: 'blur'
                        }],
                      newPwd: [{
                            required: true,
                            message: '请输入新密码',
                            trigger: 'blur'
                        }],
                      verifyPwd: [{
                            required: true,
                            message: '请输入确认密码',
                            trigger: 'blur',
                            autocomplete:'off'
                        }]
                    },
                    items: [{
                        prop: 'oldPwd',
                        label: '原始密码',
                        itemType: 't-input',
                        type: "password",
                        defaultValue: ''
                    }, {
                        prop: 'newPwd',
                        label: '新密码',
                        itemType: 't-input',
                        type: "password",
                        defaultValue: ''
                    }, {
                        prop: 'verifyPwd',
                        label: '确认密码',
                        itemType: 't-input',
                        type: "password",
                        defaultValue: ''
                    }],
                    btns: [ {
                        label: '保存',
                        event: 'save',
                        showTooltip: true,
                        isValidate: true,
                        icon: 'el-icon-add'
                    },
                    {
                        label: '取消',
                        event: 'cancel',
                        plain: true,
                        showTooltip: true,
                        isValidate: false,
                        // icon: 'el-icon-cancel'
                    },]
                }
            };
        },
        methods: {
            handleClose() {
                store.dispatch('user/getUserInfo').then(res => {

                if(res.expandMap.thisMonthWhetherChangePassword==0){
                    this.$emit('openpwd')
                    this.showDialog = false;
                    this.$emit('update:visible', false);
                    this.$refs['userInfoForm'].resetFields();

                }else{
                    this.showDialog = false;
                    this.$emit('update:visible', false);
                    this.$refs['userInfoForm'].resetFields();
                }
                });

            },
            // 按钮处理
            btnClick(event, params) {
                console.log(event,params,'hdujdeujdjdkj');
                if (event === 'save') {
                    const data = {};
                    data.oldPassword = params.oldPwd;
                    data.newPassword = params.newPwd;
                    //判断两次新密码输入是否正确
                    data.verifyPwd   = params.verifyPwd;
                    if (data.verifyPwd!=data.newPassword){
                      this.$message.error('两次新密码不一致');
                      this.$refs['userInfoForm'].resetFields();
                      return;
                    }
                    if(data.verifyPwd.length<6){
                        this.$message.error('新密码长度要求大于等于6');
                        this.$refs['userInfoForm'].resetFields();
                      return;


                    }
                    changePwd(data).then(rsp => {
                        if (rsp.code === 200) {
                            this.$message.success('密码修改成功,请重新登录！');
                            this.$store.dispatch('user/logout');
                            this.$router.push(`/login?redirect=${this.$route.fullPath}`);
                        }
                    });
                } else if (event === 'cancel') {
                    this.handleClose();


                }
            }
        }
    };
</script>

<style scoped>


</style>
