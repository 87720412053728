var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "90px" } },
        [
          _c("el-form-item", { attrs: { label: "反映辖区：" } }, [
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.form.deptName)),
            ]),
          ]),
          _c("el-form-item", { attrs: { label: "事件类型：" } }, [
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.form.typeName)),
            ]),
          ]),
          _c("el-form-item", { attrs: { label: "事件标签：" } }, [
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.form.labelName)),
            ]),
          ]),
          _c("el-form-item", { attrs: { label: "事件内容：" } }, [
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.form.submitDesc)),
            ]),
          ]),
          _vm.submitFileUrls.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: "事件图片：" } },
                _vm._l(_vm.submitFileUrls, function (item, idx) {
                  return _c("el-image", {
                    key: idx,
                    staticStyle: {
                      width: "100px",
                      height: "100px",
                      "z-index": "2021",
                    },
                    attrs: {
                      src: item,
                      "preview-src-list": _vm.submitFileUrls,
                    },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm.submitVideoFileUrls.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: "事件视频：" } },
                _vm._l(_vm.submitVideoFileUrls, function (item, idx) {
                  return _c("div", { key: idx }, [
                    _c("video", {
                      staticStyle: { width: "200px", height: "200px" },
                      attrs: {
                        src: item,
                        controls: "controls",
                        crossOrigin: "Anonymous",
                      },
                    }),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm.submitAudioFileUrls.length > 0
            ? _c(
                "el-form-item",
                { attrs: { label: "事件音频：" } },
                _vm._l(_vm.submitAudioFileUrls, function (item, idx) {
                  return _c("div", { key: idx }, [
                    _c("audio", {
                      staticClass: "nowAudio",
                      attrs: { src: item, controls: "controls" },
                    }),
                  ])
                }),
                0
              )
            : _vm._e(),
          _c("el-form-item", { attrs: { label: "反映人：" } }, [
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.form.username)),
            ]),
          ]),
          _c("el-form-item", { attrs: { label: "事件地址：" } }, [
            _vm.form.detailedAddress
              ? _c("div", { staticClass: "content" }, [
                  _vm._v(
                    _vm._s(_vm.form.address) +
                      " (" +
                      _vm._s(_vm.form.detailedAddress) +
                      ") "
                  ),
                ])
              : _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.form.address)),
                ]),
          ]),
          _c("el-form-item", { attrs: { label: "联系电话：" } }, [
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.form.submitTel)),
            ]),
          ]),
          _c("el-form-item", { attrs: { label: "反映时间：" } }, [
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.form.createTime)),
            ]),
          ]),
          _c("br"),
          _c(
            "el-timeline",
            { attrs: { reverse: _vm.reverse } },
            _vm._l(_vm.form.feedbackDtoList, function (item, index) {
              return _c(
                "el-timeline-item",
                { key: index, attrs: { timestamp: item.createTime } },
                [
                  _c("label", [_vm._v(_vm._s(item.feedbackUserName))]),
                  _c("br"),
                  _c("br"),
                  _vm._v(" " + _vm._s(item.feedbackContent) + " "),
                  _c("br"),
                  _vm._l(item.feedbackFileUrls, function (img, idx) {
                    return _c("el-image", {
                      key: idx,
                      staticStyle: {
                        width: "100px",
                        height: "100px",
                        "z-index": "2021",
                        "margin-top": "10px",
                      },
                      attrs: {
                        src: img,
                        "preview-src-list": item.feedbackFileUrls,
                      },
                    })
                  }),
                ],
                2
              )
            }),
            1
          ),
          _c("div", { staticClass: "bootContent" }, [
            _c("div", { staticClass: "statustitle" }, [
              this.form.status === 14
                ? _c("span", { staticClass: "titlest" }, [_vm._v(" 事件状态·")])
                : _c("span", { staticClass: "titlest" }, [
                    _vm._v(" 处理进度·"),
                  ]),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 1,
                      expression: "this.form.status === 1",
                    },
                  ],
                },
                [_vm._v("待处理")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 2,
                      expression: "this.form.status === 2",
                    },
                  ],
                },
                [_vm._v("已签收")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 12,
                      expression: "this.form.status === 12",
                    },
                  ],
                },
                [_vm._v("已驳回")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 10,
                      expression: "this.form.status === 10",
                    },
                  ],
                },
                [_vm._v("已移交")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 14,
                      expression: "this.form.status === 14",
                    },
                  ],
                },
                [_vm._v("已撤销")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.form.status === 18,
                      expression: "this.form.status === 18",
                    },
                  ],
                },
                [_vm._v("已处理")]
              ),
            ]),
            this.form.status != 14
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { ref: "stepItem", staticClass: "stepItem" },
                      [
                        _c("div", { staticClass: "taptitle" }, [
                          this.a[0].status == 12
                            ? _c("div", { staticClass: "img" }, [
                                _c("img", {
                                  attrs: { src: _vm.img2, alt: "" },
                                }),
                              ])
                            : _vm._e(),
                          this.a[0].status == 2
                            ? _c("div", { staticClass: "img" }, [
                                _c("img", {
                                  attrs: { src: _vm.img4, alt: "" },
                                }),
                              ])
                            : _vm._e(),
                          this.a[0].status == 1 ||
                          this.a[0].status == 10 ||
                          this.a[0].status == 14 ||
                          this.a[0].status == 18
                            ? _c("div", { staticClass: "img" }, [
                                _c("img", {
                                  attrs: { src: _vm.img1, alt: "" },
                                }),
                              ])
                            : _vm._e(),
                          _c("span", { staticClass: "span" }, [
                            _vm._v("反映对象"),
                          ]),
                        ]),
                        _vm._l(_vm.a, function (item, index) {
                          return _c(
                            "div",
                            { key: index, class: { bdn: _vm.c.length == 0 } },
                            [
                              _c("div", { staticClass: "stepBox" }, [
                                _c("div", { staticClass: "Item1205s" }, [
                                  _c("div", { staticClass: "deptName1205s" }, [
                                    _c("span", [_vm._v(_vm._s(item.deptName))]),
                                  ]),
                                  item.hasOwnProperty("handleTime")
                                    ? _c("div", { staticClass: "time1205s" }, [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.status === 1,
                                                expression: "item.status === 1",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              "待处理·" +
                                                _vm._s(
                                                  _vm.setTime(item.handleTime)
                                                )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.status === 2,
                                                expression: "item.status === 2",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              "已签收·" +
                                                _vm._s(
                                                  _vm.setTime(item.handleTime)
                                                )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.status === 12,
                                                expression:
                                                  "item.status === 12",
                                              },
                                            ],
                                            staticStyle: { color: "red" },
                                          },
                                          [
                                            _vm._v(
                                              "已驳回·" +
                                                _vm._s(
                                                  _vm.setTime(item.handleTime)
                                                )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.status === 10,
                                                expression:
                                                  "item.status === 10",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              "已移交·" +
                                                _vm._s(
                                                  _vm.setTime(item.handleTime)
                                                )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.status === 14,
                                                expression:
                                                  "item.status === 14",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              "已撤销·" +
                                                _vm._s(
                                                  _vm.setTime(item.handleTime)
                                                )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.status === 18,
                                                expression:
                                                  "item.status === 18",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              "已处理·" +
                                                _vm._s(
                                                  _vm.setTime(item.handleTime)
                                                )
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                item.status == 18 &&
                                item.hasOwnProperty("handleRemark") &&
                                item.handleRemark != "null"
                                  ? _c(
                                      "div",
                                      { staticClass: "handleRemark1205s" },
                                      [
                                        _vm._v(
                                          " 处理方式：" +
                                            _vm._s(item.handleRemark) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                item.status == 18 &&
                                _vm.rewardRecord.id &&
                                item.handleRemark
                                  ? _c("div", { staticClass: "gagaga" }, [
                                      _c("div", { staticClass: "Itemgaga" }, [
                                        _c(
                                          "div",
                                          { staticClass: "deptNamegaga" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.rewardRecord.sysDeptName
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("div", { staticClass: "timegaga" }, [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: item.status === 18,
                                                  expression:
                                                    "item.status === 18",
                                                },
                                              ],
                                            },
                                            [
                                              _vm._v(
                                                "打赏积分·" +
                                                  _vm._s(
                                                    _vm.setTime(
                                                      _vm.rewardRecord
                                                        .createTime
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "handleRemarkGaga" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.rewardRecord.rewardPoint
                                              ) +
                                              "分：" +
                                              _vm._s(
                                                _vm.rewardRecord.rewardContent
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                item.status == 12 &&
                                item.hasOwnProperty("handleRemark") &&
                                item.handleRemark != "null"
                                  ? _c("div", { staticClass: "handleRemark" }, [
                                      _vm._v(
                                        " " + _vm._s(item.handleRemark) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                                item.status == 10 &&
                                item.hasOwnProperty("handleRemark") &&
                                item.handleRemark != "null"
                                  ? _c("div", { staticClass: "handleRemark" }, [
                                      _vm._v(
                                        " " + _vm._s(item.handleRemark) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._l(_vm.c, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "stepItem",
                          class: { bdn: index == _vm.c.length - 1 },
                        },
                        [
                          _c("div", { staticClass: "taptitle" }, [
                            item.status == 12
                              ? _c("div", { staticClass: "img" }, [
                                  _c("img", {
                                    attrs: { src: _vm.img2, alt: "" },
                                  }),
                                ])
                              : _vm._e(),
                            item.status == 2
                              ? _c("div", { staticClass: "img" }, [
                                  _c("img", {
                                    attrs: { src: _vm.img4, alt: "" },
                                  }),
                                ])
                              : _vm._e(),
                            item.status == 1 ||
                            item.status == 10 ||
                            item.status == 14 ||
                            item.status == 18
                              ? _c("div", { staticClass: "img" }, [
                                  _c("img", {
                                    attrs: { src: _vm.img1, alt: "" },
                                  }),
                                ])
                              : _vm._e(),
                            _c("span", { staticClass: "span" }, [
                              _vm._v("移交对象"),
                            ]),
                          ]),
                          _c("div", { staticClass: "stepBox" }, [
                            _c("div", { staticClass: "Item" }, [
                              _c("div", { staticClass: "deptName" }, [
                                _c("span", [_vm._v(_vm._s(item.deptName))]),
                              ]),
                              item.hasOwnProperty("handleTime")
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "time",
                                      staticStyle: { "font-size": "12px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.status === 1,
                                              expression: "item.status === 1",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "待处理·" +
                                              _vm._s(
                                                _vm.setTime(item.handleTime)
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.status === 2,
                                              expression: "item.status === 2",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "已签收·" +
                                              _vm._s(
                                                _vm.setTime(item.handleTime)
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.status === 12,
                                              expression: "item.status === 12",
                                            },
                                          ],
                                          staticStyle: { color: "red" },
                                        },
                                        [
                                          _vm._v(
                                            "已驳回·" +
                                              _vm._s(
                                                _vm.setTime(item.handleTime)
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.status === 10,
                                              expression: "item.status === 10",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "已移交·" +
                                              _vm._s(
                                                _vm.setTime(item.handleTime)
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.status === 14,
                                              expression: "item.status === 14",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "已撤销·" +
                                              _vm._s(
                                                _vm.setTime(item.handleTime)
                                              )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.status === 18,
                                              expression: "item.status === 18",
                                            },
                                          ],
                                        },
                                        [
                                          _vm._v(
                                            "已处理·" +
                                              _vm._s(
                                                _vm.setTime(item.handleTime)
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            item.status == 18 &&
                            item.hasOwnProperty("handleRemark") &&
                            item.handleRemark != "null"
                              ? _c("div", { staticClass: "handleRemark" }, [
                                  item.processMode == 1
                                    ? _c("span", [_vm._v("处理方式：")])
                                    : _vm._e(),
                                  _vm._v(_vm._s(item.handleRemark) + " "),
                                ])
                              : _vm._e(),
                            item.status == 18 &&
                            _vm.rewardRecord.id &&
                            item.handleRemark
                              ? _c("div", { staticClass: "gagaga" }, [
                                  _c("div", { staticClass: "Itemgaga" }, [
                                    _c("div", { staticClass: "deptNamegaga" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.rewardRecord.sysDeptName)
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "timegaga",
                                        staticStyle: { "font-size": "12px" },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.status === 18,
                                                expression:
                                                  "item.status === 18",
                                              },
                                            ],
                                          },
                                          [
                                            _vm._v(
                                              "打赏积分·" +
                                                _vm._s(
                                                  _vm.setTime(
                                                    _vm.rewardRecord.createTime
                                                  )
                                                )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "handleRemarkGaga" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.rewardRecord.rewardPoint) +
                                          "分：" +
                                          _vm._s(
                                            _vm.rewardRecord.rewardContent
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            item.status == 12 &&
                            item.hasOwnProperty("handleRemark") &&
                            item.handleRemark != "null"
                              ? _c("div", { staticClass: "handleRemark" }, [
                                  _vm._v(" " + _vm._s(item.handleRemark) + " "),
                                ])
                              : _vm._e(),
                            item.status == 10 &&
                            item.hasOwnProperty("handleRemark") &&
                            item.handleRemark != "null"
                              ? _c("div", { staticClass: "handleRemark" }, [
                                  _vm._v(" " + _vm._s(item.handleRemark) + " "),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }