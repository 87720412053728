import axios from 'axios';
import {
  MessageBox,
  Message
} from 'element-ui';
import store from '@/store';
import {
  getToken
} from '@/utils/auth';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (getToken()) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = getToken();
    }
    return config;
  },
  error => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    const headers = response.headers;
    if ((res instanceof Blob) || (headers && headers['content-disposition'])) {
      return response;
    } else {
      // if the custom code is not 20000, it is judged as an error.
      if (res.code !== 200 && res.code  && res.code !==251 && res.code !==2580&& res.code !== 6666) {
        console.log('res', res);
        if(res.hash){

        }else{
          Message({
            message: '操作失败：' + res.message || 'Error',
            type: 'error',
            duration: 5 * 1000
          });

        }

        // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
        if (res.code === 401 || res.code === 400 || res.code === 402) {
          // to re-login
          MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '确认', {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            store.dispatch('user/logout').then(() => {
              next({
                path: '/'
              });
            });
          });
        }
        //return Promise.reject(new Error(res.message || 'Error'));
        return res;
      } else {
        return res;
      }
    }
  },
  error => {
    console.log('err' + error); // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
);

export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        url,
        {
          params: params,
          headers: {
            Authorization: getToken(),
            language: 'zh'
          }
        }
      )
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Authorization: getToken(),
          language: 'zh'
        }
      })
      .then(
        response => {
          resolve(response.data);
        },
        err => {
          reject(err);
        }
      );
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function upload(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: {
          Authorization: getToken(),
          language: 'zh',
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(
        response => {
          resolve(response.data);
        },
        err => {
          reject(err);
        }
      );
  });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
}

export default service;
