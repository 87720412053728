<template>
  <div
    id="item"
    @click="currentIndex = -1"
    style="max-height: 450px; overflow: auto"
  >
    <div v-for="(item, index) in items" :key="item.id">
      <div class="item77">
        <span class="name" style="font-weight: bold">{{
          index + 1 + ',' + item.systemName
        }}</span>
        <span v-if="item.alias" class="name1" style="font-weight: bold">{{
          `【${item.alias}】`
        }}</span>
        <div
          style="
            flex-grow: 1;
            border-left: 1px solid #00000066;
            padding-left: 15px;
          "
          v-if="currentIndex === item.id"
        >
          <input
            class="input-box"
            type="text"
            maxlength="10"
            v-model="alias"
            placeholder="请输入菜单别名，按下Enter键确认"
            @keyup.enter="addName()"
            @blur="currentIndex = -1"
            @click.stop="handleInputClick(item, index)"
          />
        </div>
        <i class="el-icon-edit" v-else @click.stop="showInput(item)"></i>
        <i
          v-if="item.children.length !== 0 && Nested !== item.id"
          style="float: right; margin-right: 10px; cursor: pointer"
          class="el-icon-arrow-right"
          @click="showNested(item)"
        ></i>
        <i
          v-if="item.children.length !== 0 && Nested == item.id"
          style="float: right; margin-right: 10px; cursor: pointer"
          class="el-icon-arrow-down"
          @click="showNested(item)"
        ></i>
      </div>
      <div v-if="item.children && Nested == item.id">
        <nested-list
          @click="currentIndex = -1"
          :items="item.children"
          @lookItems="lookItems"
        ></nested-list>
      </div>
    </div>
  </div>
</template>
  
  <script>
import NestedList from './NestedList';
import { returnResourceTree } from '@/api/modules/user';
import { updateDataIndex ,defaultDataIndex} from '@/api/modules/system';
import Sortable from 'sortablejs';
export default {
  components: { NestedList },
  data() {
    return {
      data: [],
      items: [],
      currentIndex: -1,
      Nested: -1,
      alias: '',
      aliasIndex: null,
    };
  },
  created() {
    returnResourceTree().then((res) => {
      this.items = res.data[0].children;
      this.data = res.data;
      console.log(res.data);
    });
  },
  mounted() {
    this.$nextTick(() => {
      const container = document.getElementById('item');
      Sortable.create(container, {
        animation: 200,
        fallbackOnBody: true,
        ghostClass: 'blue-background-class',
        onEnd: this.handleSortEnd, // 拖拽结束方法
      });
    });
  },
  methods: {
    lookItems() {
      console.log(this.items, '主');
    },
    showInput(ItemVue) {
      this.currentIndex = ItemVue.id;
    },
    handleInputClick(item, index) {
      console.log(item);
      this.aliasIndex = index;
    },
    showNested(val) {
      if (this.Nested !== val.id) {
        this.Nested = val.id;
      } else {
        this.Nested = -1;
      }
    },
    addName() {
      this.items[this.aliasIndex].alias = this.alias;
      this.alias = '';
      this.currentIndex = -1;
    },
    handleSortEnd(event) {
      const { newIndex, oldIndex } = event;
      console.log(event);
      // 更新items数组的顺序
      this.items.splice(newIndex, 0, this.items.splice(oldIndex, 1)[0]);
      console.log(this.items);
      // 将新的顺序发送给后端进行保存
    },
    updateData() {
      this.data[0].children = this.items;
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      updateDataIndex(this.data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success',
          });
          this.$store.dispatch('SetRouter');
          loading.close();
          returnResourceTree().then((res) => {
            this.items = res.data[0].children;
            this.data = res.data;
            console.log(res.data);
          });
        }
      });
    },
    restore(){
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      defaultDataIndex().then((res) => {
        if (res.code == 200) {
          this.$message({
            message: '还原成功',
            type: 'success',
          });
          this.$store.dispatch('SetRouter');
          loading.close();

          returnResourceTree().then((res) => {
            this.items = res.data[0].children;
            this.data = res.data;
            console.log(res.data);
          });
        }
      });
    },
  },
};
</script>

  <style lang="less" scoped>
.item77 {
  border: 1px solid #dcdcdc;
  font-size: 14px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  span {
    padding: 8px;
    display: inline-block;
  }
}

input {
  border: none;
  box-shadow: none;
  width: 100%;
  outline: none;
  height: 100%;
}
.el-icon-edit {
  font-size: 20px;
  flex: 1;
  cursor: pointer;
}

.blue-background-class {
  background-color: #0052d9ff;
  color: white;
  border: none;
}

.name1 {
  animation: tag-fade-in2 0.5s ease-out forwards;
}

@keyframes tag-fade-in2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>