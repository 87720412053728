var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar", class: { homeNavbar: !_vm.isShowHomeStyle } },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "div",
            { staticClass: "transmit" },
            [
              !_vm.isTransmit
                ? _c("van-icon", {
                    staticClass: "search",
                    attrs: { name: "search", color: _vm.isColor, size: "27" },
                    on: { click: _vm.transmit },
                  })
                : _c(
                    "el-select",
                    {
                      ref: "search",
                      staticClass: "searchSelect",
                      attrs: {
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "输入以快速访问",
                        "remote-method": _vm.remoteMethod,
                      },
                      on: { change: _vm.change, blur: _vm.change },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-right": "20px", "margin-top": "4px" } },
            [
              !_vm.isShowHomeStyle
                ? _c("img", {
                    staticStyle: { cursor: "pointer" },
                    attrs: { width: "32", src: _vm.sort, alt: "" },
                    on: { click: _vm.MenuSorting },
                  })
                : _c("img", {
                    staticStyle: { cursor: "pointer" },
                    attrs: { width: "32", src: _vm.sort2, alt: "" },
                    on: { click: _vm.MenuSorting },
                  }),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "margin-right": "1.33rem" } },
            [
              _vm.monitorShow
                ? _c("el-button", {
                    attrs: { type: "primary", icon: "el-icon-monitor" },
                    on: { click: _vm.toOverview },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  staticClass: "el-icon-bell",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.push("/msgSend")
                    },
                  },
                },
                [
                  _vm.msgNum !== 0
                    ? _c("el-badge", {
                        staticClass: "item",
                        attrs: { value: _vm.msgNum, max: 99 },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-popover",
            { attrs: { placement: "bottom", trigger: "hover" } },
            [
              _c("div", [
                _c("div", { staticClass: "weixin" }, [
                  _c("img", {
                    attrs: {
                      title: "手机扫码联系客服",
                      src: _vm.weixin,
                      alt: "",
                    },
                  }),
                  _vm._v(" 微信扫描二维码 "),
                  _c("br"),
                  _vm._v(" 或 "),
                ]),
                _c("br"),
                _c("br"),
                _c(
                  "div",
                  { staticClass: "wechat" },
                  [
                    _c(
                      "el-button",
                      { attrs: { type: "success" }, on: { click: _vm.wechat } },
                      [
                        _c("van-icon", { attrs: { name: "wechat" } }),
                        _vm._v(" 联系客服 "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-button", {
                staticStyle: { "margin-left": "20px" },
                attrs: {
                  slot: "reference",
                  type: "primary",
                  icon: "el-icon-service",
                },
                slot: "reference",
              }),
            ],
            1
          ),
          _vm.device !== "mobile"
            ? [
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  class: { homeRightMenuItem: _vm.isShowHomeStyle },
                  attrs: { id: "screenfull" },
                }),
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              class: { homeRightMenuItem: _vm.isShowHomeStyle },
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("div", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.name) + " "),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]),
                ]),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.openUserInfo },
                      },
                      [_vm._v("个人信息")]
                    ),
                  ]),
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.changePwd },
                      },
                      [_vm._v("修改密码")]
                    ),
                  ]),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("navbar.logOut")) + " ")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "详情",
                visible: _vm.dialogVisible,
                width: "50%",
                "modal-append-to-body": true,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("ReportTaskDetail", {
                staticClass: "detail",
                attrs: {
                  dialogVisible: _vm.dialogVisible,
                  formData: _vm.rowDataPro,
                  detailId: _vm.detailIdPro,
                },
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "申请注册信息",
                visible: _vm.dialogVisible2,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible2 = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form2",
                  attrs: { model: _vm.form2, "label-width": "110px" },
                },
                [
                  _c("el-form-item", { attrs: { label: "注册辖区：" } }, [
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.form2.deptName)),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "注册人：" } }, [
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.form2.username)),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "手机号：" } }, [
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.form2.phone)),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "性别：" } },
                    [_c("el-tag", [_vm._v(_vm._s(_vm.form2.gender))])],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户身份：" } },
                    [_c("el-tag", [_vm._v(_vm._s(_vm.form2.userGrade))])],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "装备：" } },
                    _vm._l(
                      _vm.form2.equipmentLabelName,
                      function (item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            attrs: { "disable-transitions": false },
                          },
                          [_vm._v(_vm._s(item) + " ")]
                        )
                      }
                    ),
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "技能：" } },
                    _vm._l(_vm.form2.skillLabelName, function (item, index) {
                      return _c(
                        "el-tag",
                        { key: index, attrs: { "disable-transitions": false } },
                        [_vm._v(_vm._s(item) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.taskDetailDia("Register")
                        },
                      },
                    },
                    [_vm._v("查看更多")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "详情",
                visible: _vm.dialogVisible4,
                width: "50%",
                "modal-append-to-body": true,
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible4 = $event
                },
              },
            },
            [
              _c("PublishTaskDetail", {
                staticClass: "detail",
                attrs: {
                  dialogVisible4: _vm.dialogVisible4,
                  formData: _vm.rowData,
                  detailId: _vm.detailId,
                },
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible4 = false
                        },
                      },
                    },
                    [_vm._v("关 闭")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              style: { "text-align": "center" },
              attrs: { visible: _vm.isShow, width: "40%" },
              on: {
                "update:visible": function ($event) {
                  _vm.isShow = $event
                },
              },
            },
            [
              _c(
                "h3",
                {
                  staticStyle: { "font-weight": "bold", "font-size": "20px" },
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [_vm._v(" " + _vm._s(_vm.form3.title) + " ")]
              ),
              _c("div", { staticClass: "content1" }, [
                _c(
                  "pre",
                  {
                    staticClass: "showText",
                    staticStyle: { "white-space": "pre-wrap" },
                  },
                  [_vm._v(_vm._s(_vm.form3.content))]
                ),
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.isDialogVisible.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.notifyDialog,
                title: this.titleTrue,
                "append-to-body": "",
                width: "800px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.notifyDialog = $event
                },
              },
            },
            [
              _c("div", { staticStyle: { width: "100%" } }, [
                _c("span", [_vm._v(_vm._s(this.notifyDesc || "[语音信息]"))]),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "text-align": "center",
                    "margin-top": "20px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.taskDetailDia("ReportTaskTable")
                        },
                      },
                    },
                    [_vm._v("查看详情 ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.notifyDialog2,
                title: "收到二维码注册信息",
                "append-to-body": "",
                width: "800px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.notifyDialog2 = $event
                },
              },
            },
            [
              _c("div", { staticStyle: { width: "100%" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      "申请人：" + this.username + " 手机号：" + this.phone
                    )
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "text-align": "center",
                    "margin-top": "20px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.taskDetailDia("Register")
                        },
                      },
                    },
                    [_vm._v("查看详情 ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.notifyDialog4,
                title: this.titleAll,
                "append-to-body": "",
                width: "800px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.notifyDialog4 = $event
                },
              },
            },
            [
              _c("div", { staticStyle: { width: "100%" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      "任务内容:【" + (this.descAll || "[语音信息]") + "】"
                    )
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "text-align": "center",
                    "margin-top": "20px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.taskDetailDia("TaskTable")
                        },
                      },
                    },
                    [_vm._v("查看详情 ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.notifyDialog5,
                title: this.titleAll,
                "append-to-body": "",
                width: "800px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.notifyDialog5 = $event
                },
              },
            },
            [
              _c("div", { staticStyle: { width: "100%" } }, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      "纠纷内容:【" + (this.descAll || "[语音信息]") + "】"
                    )
                  ),
                ]),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    "text-align": "center",
                    "margin-top": "20px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.taskDetailDia("DisputeMsgTable")
                        },
                      },
                    },
                    [_vm._v("查看详情 ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.MenuSortingVisible,
                title: "菜单排序 ( 上下拖动调整排序 )",
                "append-to-body": "",
                width: "500px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.MenuSortingVisible = $event
                },
              },
            },
            [
              _vm.MenuSortingVisible
                ? _c("MenuSorting", { ref: "MenuSorting" })
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.MenuSortingVisible = false
                        },
                      },
                    },
                    [_vm._v("关 闭")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        color: "rgb(0, 0, 0)",
                        "background-color": "rgba(220, 220, 220, 1)",
                      },
                      attrs: { plain: "" },
                      on: { click: _vm.restore },
                    },
                    [_vm._v("恢复默认排序")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.updateData()
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("user-info", {
            ref: "userInfo",
            attrs: { visible: _vm.dialogShow.userInfoShow },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.dialogShow, "userInfoShow", $event)
              },
            },
          }),
          _c("pwd", {
            attrs: { visible: _vm.dialogShow.pwdShow },
            on: {
              openpwd: _vm.openpwd,
              "update:visible": function ($event) {
                return _vm.$set(_vm.dialogShow, "pwdShow", $event)
              },
            },
          }),
          _c("hint", {
            attrs: { visible: _vm.dialogShow.hintShow },
            on: {
              open: _vm.open,
              "update:visible": function ($event) {
                return _vm.$set(_vm.dialogShow, "hintShow", $event)
              },
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }