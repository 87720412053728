var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _c("div", { staticClass: "sidebar-logo-container" }, [
        _c("a", {
          staticClass: "sidebar-logo-link router-link-active",
          attrs: { href: "#" },
        }),
        _c("div", { staticClass: "sysname" }, [_vm._v(_vm._s(_vm.deptName))]),
        _c("br"),
      ]),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.$route.path,
                collapse: _vm.isCollapse,
                "background-color": "#324B84",
                "text-color": "#ffffff",
                "unique-opened": true,
                "active-text-color": "#03F5FF",
                "collapse-transition": true,
                mode: "vertical",
              },
              on: { select: _vm.handleSelect },
            },
            _vm._l(_vm.vueRouter, function (route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }