<template>
  <div />
</template>
<script>
export default {
  name: 'TButton',
  data() {
    return {

    };
  }
};
</script>
