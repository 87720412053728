import {
    login,
    logout,
    getUserInfo
} from '@/api/modules/user';
import {
    getToken,
    setToken,
    removeToken
} from '@/utils/auth';
import router, {
    resetRouter
} from '@/router';

const state = {
    token: getToken(),
    uid: '',
    name: '',
    username: '',
    loginName: '',
    avatar: '',
    deptId: '',
    deptCode: '',
    deptName: '',
    deptIcon: '',
    department: '',
    email: '',
    mobile: '',
    introduction: '',
    roles: [],
    permissionKeys: [],
    deptBikeIcon: '',
    qperationAuthority: '',
    deptIds: []
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_UID: (state, uid) => {
        state.uid = uid;
    },
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction;
    },
    SET_NAME: (state, name) => {
        state.name = name;
    },
    SET_USERNAME: (state, username) => {
        state.username = username;
    },
    SET_LOGINNAME: (state, loginName) => {
        state.loginName = loginName;
    },
    SET_DEPT_ID: (state, deptId) => {
        state.deptId = deptId;
    },
    SET_DEPT_IDS: (state, deptIds) => {
        state.deptIds = deptIds;
    },
    SET_DEPT_NAME: (state, deptName) => {
        state.deptName = deptName;
    },
    SET_DEPT_CODE: (state, deptCode) => {
        state.deptCode = deptCode;
    },
    SET_DEPT_ICON: (state, deptIcon) => {
        state.deptIcon = deptIcon;
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar;
    },
    SET_MOBILE: (state, mobile) => {
        state.mobile = mobile;
    },
    SET_EMAIL: (state, email) => {
        state.email = email;
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles;
    },
    SET_PERMISSION_KEYS: (state, permissionKeys) => {
        state.permissionKeys = permissionKeys;
    },
    SET_QPERATION_AUTHORITY: (state, qperationAuthority) => {
        state.qperationAuthority = qperationAuthority;
    }

};

const actions = {
    // user login
    login({
        commit
    }, userInfo) {
        const {
            username,
            password
        } = userInfo;
        console.log('------login-----');
        return new Promise((resolve, reject) => {
            login({
                username: username.trim(),
                password: password
            }).then(response => {
                const resData = response.data;
                console.log('login-result', resData)
                if (resData) {
                    commit('SET_TOKEN', resData);
                    setToken(resData);
                    // if (resData.data) {
                    //   commit('SET_UID', resData.data.id);
                    //   commit('SET_NAME', resData.data.accountName);
                    //   commit('SET_PERMISSION_KEYS', resData.data.permissionKeys);
                    //   commit('SET_ROLES', ['admin']);
                    // }
                }

                resolve(response);
            }).catch(error => {
                reject(error);
            });
        });
    },

    // get user info
    getUserInfo({
        commit,
        state
    }) {
      if (state.token ==null){
        return ;
      }
        return new Promise((resolve, reject) => {
            getUserInfo(state.token).then(response => {
                const {
                    data
                } = response;

                if (!data) {
                    reject('Verification failed, please Login again.');
                }

                const {
                    userId,
                    nickname,
                    loginName,
                    username,
                    roleIdList,
                    mobile,
                    email
                } = data;
                const deptId = data.expandMap.deptId;
                const deptCode = data.expandMap.deptCode;
                const deptName = data.expandMap.deptName;
                const qperationAuthority = data.expandMap.qperationAuthority;
                let deptIcon = data.expandMap.deptIcon;
                //为空
                let mapColorValue=data.expandMap.showMapStyle;
                let mapStyleValue=data.expandMap.showHistoryStyle;

                if (mapColorValue === null || mapColorValue === ''|| mapColorValue === undefined) {
                    mapColorValue = 'normal';
                }
                if (mapStyleValue === null || mapStyleValue === ''|| mapStyleValue === undefined) {
                    mapStyleValue = '2D';
                }
                // 1  2
                let showElectronFence = data.expandMap.showRail === 1 ? "true" : "false";
                let showPoint = data.expandMap.showStopTime === 1 ? "true" : "false";
                localStorage.setItem("showElectronFence", showElectronFence)
                localStorage.setItem("mapStyleValue", mapStyleValue)
                localStorage.setItem("showPoint", showPoint)
                localStorage.setItem("mapColorValue", mapColorValue)
                //辖区图标
                // if (!deptIcon) {
                //     deptIcon = 'http://cyoubike.oss-cn-shanghai.aliyuncs.com/logo.png'
                // }

                commit('SET_UID', userId);
                commit('SET_NAME', nickname);
                commit('SET_LOGINNAME', loginName);
                commit('SET_USERNAME', username);
                commit('SET_DEPT_ID', deptId);
                commit('SET_DEPT_NAME', deptName);
                commit('SET_DEPT_CODE', deptCode);
                commit('SET_DEPT_ICON', deptIcon);
                commit('SET_MOBILE', mobile);
                commit('SET_EMAIL', email);
                commit('SET_ROLES', roleIdList);
                commit('SET_QPERATION_AUTHORITY', qperationAuthority);
                // commit('SET_PERMISSION_KEYS', permissionKeys);
                resolve(data);
            }).catch(error => {
                reject(error);
            });
        });
    },

    // user logout
    logout({
        commit,
        state
    }) {
        return new Promise((resolve, reject) => {
            logout(state.token).then(() => {
                commit('SET_TOKEN', '');
                commit('SET_ROLES', []);
                removeToken();
                resetRouter();
                resolve();
            }).catch(error => {
                reject(error);
            });
        });
    },

    // remove token
    resetToken({
        commit
    }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '');
            commit('SET_ROLES', []);
            removeToken();
            resolve();
        });
    },

    setDeptIds({
                 commit
               }, deptIds){
      return new Promise(resolve => {
        commit('SET_DEPT_IDS', deptIds);
        resolve()
      });
    },

    // dynamically modify permissions
    changeRoles({
        commit,
        dispatch
    }, role) {
        return new Promise(async resolve => {
            const token = role + '-token';

            commit('SET_TOKEN', token);
            setToken(token);

            const {
                roles
            } = await dispatch('getInfo');

            resetRouter();

            // generate accessible routes map based on roles
            const accessRoutes = await dispatch('permission/generateRoutes', roles, {
                root: true
            });

            // dynamically add accessible routes
            router.addRoutes(accessRoutes);

            // reset visited views and cached views
            dispatch('tagsView/delAllViews', null, {
                root: true
            });

            resolve();
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
