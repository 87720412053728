var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "DetailDialog" }, [
        _c(
          "div",
          { staticClass: "DetailForm" },
          [
            _vm.isSkeleton
              ? _c("el-skeleton", {
                  staticStyle: { "z-index": "99999999999999999" },
                  attrs: { rows: 10, animated: "" },
                })
              : _c(
                  "el-form",
                  {
                    ref: "form",
                    staticClass: "collapse",
                    attrs: { model: _vm.form, "label-width": "90px" },
                  },
                  [
                    _c("el-form-item", { attrs: { label: "下发辖区：" } }, [
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.submitDeptName)),
                      ]),
                    ]),
                    _c("el-form-item", { attrs: { label: "任务类型：" } }, [
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.typeName)),
                      ]),
                    ]),
                    _c("el-form-item", { attrs: { label: "任务标签：" } }, [
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.taskType)),
                      ]),
                    ]),
                    _vm.form.submitDesc !== []
                      ? _c("el-form-item", { attrs: { label: "任务内容：" } }, [
                          _c("div", { staticClass: "submitDesc" }, [
                            _vm._v(_vm._s(_vm.form.submitDesc)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.form.submitFileUrls.length !== 0
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "任务图片：" } },
                          [
                            _vm.form.submitFileUrls
                              ? _c("TImagePreview", {
                                  attrs: {
                                    "data-list": _vm.form.submitFileUrls,
                                    "row-count": 3,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.submitVideoFileUrls.length !== 0
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "任务视频：" } },
                          _vm._l(
                            _vm.form.submitVideoFileUrls,
                            function (item, idx) {
                              return _c("div", { key: idx }, [
                                _c("video", {
                                  staticStyle: {
                                    width: "270px",
                                    height: "170px",
                                  },
                                  attrs: {
                                    src: item,
                                    controls: "controls",
                                    crossorigin: "Anonymous",
                                  },
                                }),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm.form.submitAudioFileUrls.length !== 0
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "任务音频：" } },
                          _vm._l(
                            _vm.form.submitAudioFileUrls,
                            function (item, idx) {
                              return _c("div", { key: idx }, [
                                _c("audio", {
                                  staticClass: "nowAudio",
                                  attrs: { src: item, controls: "controls" },
                                }),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _c("el-form-item", { attrs: { label: "下发人：" } }, [
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.submitUserName)),
                      ]),
                    ]),
                    _vm.form.address
                      ? _c("el-form-item", { attrs: { label: "任务地址：" } }, [
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.form.address)),
                          ]),
                        ])
                      : _vm._e(),
                    _c("el-form-item", { attrs: { label: "详细地址：" } }, [
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.detailedAddress || "无")),
                      ]),
                    ]),
                    _vm.form.endDate
                      ? _c("el-form-item", { attrs: { label: "截止日期:" } }, [
                          _c("div", { staticClass: "content" }, [
                            _vm._v(_vm._s(_vm.form.endDate)),
                          ]),
                        ])
                      : _vm._e(),
                    _c("el-form-item", { attrs: { label: "创建日期：" } }, [
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.createTime)),
                      ]),
                    ]),
                    _c("el-form-item", { attrs: { label: "状态：" } }, [
                      _c("div", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.form.statusName)),
                      ]),
                    ]),
                    _c("br"),
                  ],
                  1
                ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.isSkeleton,
                expression: "isSkeleton",
              },
            ],
            staticClass: "Detail",
          },
          [
            _c("div", { staticClass: "TaskDetail" }, [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "1.1rem",
                    float: "left",
                    color: "#000000E6",
                    "font-weight": "400",
                    "padding-top": "10px",
                  },
                },
                [_vm._v("完成情况")]
              ),
              _c("div", { staticClass: "DetailNum" }, [
                _c("span", { staticStyle: { color: "#0148A4FF" } }, [
                  _vm._v(" 已签收 "),
                  _c("a", [_vm._v(_vm._s(_vm.mapTaskRecipientDto.已签收))]),
                ]),
                _c("span", { staticStyle: { color: "#00A870FF" } }, [
                  _vm._v(" 已反馈 "),
                  _c("a", [_vm._v(_vm._s(_vm.mapTaskRecipientDto.已反馈))]),
                ]),
                _c("span", { staticStyle: { color: "#FF5454FF" } }, [
                  _vm._v(" 待处理 "),
                  _c("a", [_vm._v(_vm._s(_vm.mapTaskRecipientDto.待处理))]),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                ref: "Task",
                staticClass: "Task",
                style: { height: _vm.myDivHeight + "px" },
              },
              [
                _vm.button
                  ? _c("div", { ref: "button", staticClass: "button" }, [
                      _c(
                        "div",
                        [
                          _vm.isbutton(_vm.TaskIndex)
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "15px" },
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.feedback(_vm.TaskIndex)
                                    },
                                  },
                                },
                                [_vm._v("回复")]
                              )
                            : _vm._e(),
                          _vm.isbutton1(_vm.TaskIndex)
                            ? _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "15px" },
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.evaluations(_vm.TaskIndex)
                                    },
                                  },
                                },
                                [_vm._v("评价")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "screen" },
                  [
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "right",
                          width: "400",
                          trigger: "click",
                        },
                        model: {
                          value: _vm.popoverVisible,
                          callback: function ($$v) {
                            _vm.popoverVisible = $$v
                          },
                          expression: "popoverVisible",
                        },
                      },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            attrs: {
                              model: _vm.screenForm,
                              "label-width": "80px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "所属辖区" } },
                              [
                                _c("t-treeselect", {
                                  ref: "deptId",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    id: "deptId",
                                    name: "deptId",
                                    "no-options-text": "无数据",
                                    placeholder: "选择部门",
                                    type: "valset",
                                    "valset-id": "100",
                                    params: _vm.params222,
                                    clearable: false,
                                  },
                                  model: {
                                    value: _vm.screenForm.deptId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.screenForm, "deptId", $$v)
                                    },
                                    expression: "screenForm.deptId",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "查询范围" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "请选择查询范围" },
                                    model: {
                                      value: _vm.screenForm.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.screenForm, "type", $$v)
                                      },
                                      expression: "screenForm.type",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "查询全部", value: "1" },
                                    }),
                                    _c("el-option", {
                                      attrs: { label: "查询本级", value: "2" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "接收人" } },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请输入想要搜索的接收人姓名",
                                  },
                                  model: {
                                    value: _vm.screenForm.userName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.screenForm, "userName", $$v)
                                    },
                                    expression: "screenForm.userName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onScreenSubmit(false)
                                      },
                                    },
                                  },
                                  [_vm._v("确定")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.onScreenSubmit(true)
                                      },
                                    },
                                  },
                                  [_vm._v("重置")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("van-icon", {
                          attrs: {
                            slot: "reference",
                            size: "22",
                            name: "filter-o",
                          },
                          slot: "reference",
                        }),
                        _c(
                          "span",
                          {
                            attrs: { slot: "reference" },
                            on: {
                              click: function ($event) {
                                _vm.popoverVisible = true
                              },
                            },
                            slot: "reference",
                          },
                          [_vm._v("筛选")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "按人查看", name: "1" } },
                      _vm._l(_vm.taskRecipientDtoList, function (item, i) {
                        return _c(
                          "el-collapse",
                          {
                            key: i,
                            ref: "collapse",
                            refInFor: true,
                            staticClass: "collapse",
                            attrs: { accordion: "" },
                            on: { change: _vm.handleChange },
                            model: {
                              value: _vm.activeNames,
                              callback: function ($$v) {
                                _vm.activeNames = $$v
                              },
                              expression: "activeNames",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              { attrs: { name: i } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-weight": "600" } },
                                    [
                                      _vm._v(
                                        "接收人: " + _vm._s(item.userName) + " "
                                      ),
                                    ]
                                  ),
                                  item.userStatus == 0
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "15px",
                                              },
                                              attrs: { size: "mini" },
                                            },
                                            [_vm._v("此人已被删除")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          item.signStatus == "2"
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "15px",
                                                  },
                                                  attrs: { size: "mini" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.feedback(i)
                                                    },
                                                  },
                                                },
                                                [_vm._v("回复")]
                                              )
                                            : _vm._e(),
                                          item.receive && item.signStatus == "2"
                                            ? _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "15px",
                                                  },
                                                  attrs: { size: "mini" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.evaluations(i)
                                                    },
                                                  },
                                                },
                                                [_vm._v("评价")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                  _c(
                                    "div",
                                    { staticClass: "tag" },
                                    [
                                      item.signStatus == 3
                                        ? _c(
                                            "el-tag",
                                            {
                                              staticStyle: {
                                                float: "right",
                                                "margin-right": "15px",
                                              },
                                              attrs: { effect: "plain" },
                                            },
                                            [_vm._v("已签收")]
                                          )
                                        : _vm._e(),
                                      item.signStatus == 2 ||
                                      item.signStatus == 4
                                        ? _c(
                                            "el-tag",
                                            {
                                              staticStyle: {
                                                float: "right",
                                                "margin-right": "15px",
                                              },
                                              attrs: {
                                                type: "success",
                                                effect: "plain",
                                              },
                                            },
                                            [_vm._v(" 已反馈")]
                                          )
                                        : _vm._e(),
                                      item.signStatus == 1
                                        ? _c(
                                            "el-tag",
                                            {
                                              staticStyle: {
                                                float: "right",
                                                "margin-right": "15px",
                                              },
                                              attrs: {
                                                type: "danger",
                                                effect: "plain",
                                              },
                                            },
                                            [_vm._v("待处理")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm.isLdg
                                  ? _c("div", { staticClass: "dots" }, [
                                      _c("div"),
                                      _c("div"),
                                      _c("div"),
                                    ])
                                  : _c("div", [
                                      _c("div", { staticClass: "DetailTop" }, [
                                        _c("span", [
                                          _vm._v(
                                            "所属辖区: " +
                                              _vm._s(_vm.DetailList.deptName)
                                          ),
                                        ]),
                                        _c("br"),
                                        _c(
                                          "span",
                                          [
                                            _vm._v(
                                              "联系电话: " +
                                                _vm._s(
                                                  _vm.DetailList.LeaderTel
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  "margin-left": "15px",
                                                },
                                                attrs: { size: "mini" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.call(
                                                      _vm.DetailList
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("呼叫")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("br"),
                                        _vm.DetailList.signTime
                                          ? _c("span", [
                                              _vm._v(
                                                "签收时间: " +
                                                  _vm._s(
                                                    _vm.DetailList.signTime
                                                  )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _vm.DetailList.policeFeedback.length !== 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "DetailItem collapse",
                                            },
                                            [
                                              _vm._l(
                                                _vm.DetailList.policeFeedback,
                                                function (item2, index) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#00000066",
                                                            "margin-bottom":
                                                              "8px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item2.feedbackUserName
                                                            ) +
                                                              " - " +
                                                              _vm._s(
                                                                item2.createTime
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item2.feedbackContent
                                                          )
                                                        ),
                                                      ]),
                                                      item2.feedbackFileUrls
                                                        ? _c("TImagePreview", {
                                                            attrs: {
                                                              "data-list":
                                                                item2.feedbackFileUrls,
                                                              "row-count": 3,
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      item2
                                                        .feedbackVideoFileUrls
                                                        .length !== 0
                                                        ? _c("video", {
                                                            staticStyle: {
                                                              width: "270px",
                                                              height: "170px",
                                                            },
                                                            attrs: {
                                                              src: item2.feedbackVideoFileUrls,
                                                              controls:
                                                                "controls",
                                                              crossorigin:
                                                                "Anonymous",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              _vm._l(
                                                _vm.DetailList.evaluateVos,
                                                function (el, inx) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.DetailList
                                                              .evaluateVos
                                                              .length !== 0,
                                                          expression:
                                                            "DetailList.evaluateVos.length !== 0 ",
                                                        },
                                                      ],
                                                      key: inx,
                                                    },
                                                    [
                                                      _c("el-divider"),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            color: "#00000066",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              el.appraiseUserName
                                                            ) +
                                                              " - " +
                                                              _vm._s(
                                                                el.appraisetime
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c("el-rate", {
                                                        attrs: {
                                                          disabled: "",
                                                          texts: _vm.texts,
                                                          "show-text": true,
                                                          "void-color":
                                                            "#000000E6",
                                                          "text-color":
                                                            "#00000099",
                                                        },
                                                        model: {
                                                          value:
                                                            el.appraisegrade,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              el,
                                                              "appraisegrade",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "el.appraisegrade",
                                                        },
                                                      }),
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            el.appraisecontent
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "按辖区查看", name: "2" } },
                      _vm._l(_vm.DeptNameDtoList, function (val, i) {
                        return _c(
                          "el-collapse",
                          {
                            key: i,
                            ref: "collapse2",
                            refInFor: true,
                            staticClass: "collapse",
                            attrs: { accordion: "" },
                            on: { change: _vm.handleChange2 },
                            model: {
                              value: _vm.activeName2,
                              callback: function ($$v) {
                                _vm.activeName2 = $$v
                              },
                              expression: "activeName2",
                            },
                          },
                          [
                            _c(
                              "el-collapse-item",
                              { attrs: { name: i } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("div", { staticClass: "title" }, [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "600",
                                          color: "#000000E6",
                                        },
                                      },
                                      [_vm._v(_vm._s(val.deptName))]
                                    ),
                                    _c("div", { staticClass: "DetailNum" }, [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#0148A4FF" } },
                                        [
                                          _vm._v(" 已签收 "),
                                          _c("a", [
                                            _vm._v(
                                              _vm._s(val.mapTaskDeptDto.已签收)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#00A870FF" } },
                                        [
                                          _vm._v(" 已反馈 "),
                                          _c("a", [
                                            _vm._v(
                                              _vm._s(val.mapTaskDeptDto.已反馈)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#FF5454FF" } },
                                        [
                                          _vm._v(" 待处理 "),
                                          _c("a", [
                                            _vm._v(
                                              _vm._s(val.mapTaskDeptDto.待处理)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._l(
                                  val.taskRecipientDtoList,
                                  function (item, index) {
                                    return _c(
                                      "el-collapse",
                                      {
                                        key: index,
                                        attrs: { accordion: "" },
                                        on: { change: _vm.handleChange3 },
                                        model: {
                                          value: _vm.activeNames,
                                          callback: function ($$v) {
                                            _vm.activeNames = $$v
                                          },
                                          expression: "activeNames",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-collapse-item",
                                          { attrs: { name: index } },
                                          [
                                            _c("template", { slot: "title" }, [
                                              _vm._v(
                                                "接收人: " +
                                                  _vm._s(item.userName) +
                                                  " "
                                              ),
                                              item.userStatus == 0
                                                ? _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "15px",
                                                          },
                                                          attrs: {
                                                            size: "mini",
                                                          },
                                                        },
                                                        [_vm._v("此人已被删除")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    [
                                                      item.signStatus == "2"
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "15px",
                                                              },
                                                              attrs: {
                                                                size: "mini",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.feedback2(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("回复")]
                                                          )
                                                        : _vm._e(),
                                                      item.receive &&
                                                      item.signStatus == "2"
                                                        ? _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "15px",
                                                              },
                                                              attrs: {
                                                                size: "mini",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.evaluations2(
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("评价")]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                              _c(
                                                "div",
                                                { staticClass: "tag" },
                                                [
                                                  item.signStatus == 3
                                                    ? _c(
                                                        "el-tag",
                                                        {
                                                          staticStyle: {
                                                            float: "right",
                                                            "margin-right":
                                                              "15px",
                                                          },
                                                          attrs: {
                                                            effect: "plain",
                                                          },
                                                        },
                                                        [_vm._v("已签收")]
                                                      )
                                                    : _vm._e(),
                                                  item.signStatus == 2 ||
                                                  item.signStatus == 4
                                                    ? _c(
                                                        "el-tag",
                                                        {
                                                          staticStyle: {
                                                            float: "right",
                                                            "margin-right":
                                                              "15px",
                                                          },
                                                          attrs: {
                                                            type: "success",
                                                            effect: "plain",
                                                          },
                                                        },
                                                        [_vm._v(" 已反馈")]
                                                      )
                                                    : _vm._e(),
                                                  item.signStatus == 1
                                                    ? _c(
                                                        "el-tag",
                                                        {
                                                          staticStyle: {
                                                            float: "right",
                                                            "margin-right":
                                                              "15px",
                                                          },
                                                          attrs: {
                                                            type: "danger",
                                                            effect: "plain",
                                                          },
                                                        },
                                                        [_vm._v("待处理")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]),
                                            _vm.isLdg
                                              ? _c(
                                                  "div",
                                                  { staticClass: "dots" },
                                                  [
                                                    _c("div"),
                                                    _c("div"),
                                                    _c("div"),
                                                  ]
                                                )
                                              : _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "DetailTop",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "所属辖区: " +
                                                            _vm._s(
                                                              _vm.DetailList
                                                                .deptName
                                                            )
                                                        ),
                                                      ]),
                                                      _c("br"),
                                                      _c(
                                                        "span",
                                                        [
                                                          _vm._v(
                                                            "联系电话: " +
                                                              _vm._s(
                                                                _vm.DetailList
                                                                  .LeaderTel
                                                              ) +
                                                              " "
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "15px",
                                                              },
                                                              attrs: {
                                                                size: "mini",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.call(
                                                                      _vm.DetailList
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("呼叫")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c("br"),
                                                      _vm.DetailList.signTime
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "签收时间: " +
                                                                _vm._s(
                                                                  _vm.DetailList
                                                                    .signTime
                                                                )
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _vm.DetailList.policeFeedback
                                                    .length !== 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "DetailItem collapse",
                                                        },
                                                        [
                                                          _vm._l(
                                                            _vm.DetailList
                                                              .policeFeedback,
                                                            function (
                                                              item2,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: index },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#00000066",
                                                                          "margin-bottom":
                                                                            "8px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item2.feedbackUserName
                                                                        ) +
                                                                          " - " +
                                                                          _vm._s(
                                                                            item2.createTime
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("p", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item2.feedbackContent
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  item2.feedbackFileUrls
                                                                    ? _c(
                                                                        "TImagePreview",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "data-list":
                                                                                item2.feedbackFileUrls,
                                                                              "row-count": 3,
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  item2
                                                                    .feedbackVideoFileUrls
                                                                    .length !==
                                                                  0
                                                                    ? _c(
                                                                        "video",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "270px",
                                                                              height:
                                                                                "170px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              src: item2.feedbackVideoFileUrls,
                                                                              controls:
                                                                                "controls",
                                                                              crossorigin:
                                                                                "Anonymous",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          _vm._l(
                                                            _vm.DetailList
                                                              .evaluateVos,
                                                            function (el, inx) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm
                                                                          .DetailList
                                                                          .evaluateVos
                                                                          .length !==
                                                                        0,
                                                                      expression:
                                                                        "DetailList.evaluateVos.length !== 0 ",
                                                                    },
                                                                  ],
                                                                  key: inx,
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-divider"
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#00000066",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          el.appraiseUserName
                                                                        ) +
                                                                          " - " +
                                                                          _vm._s(
                                                                            el.appraisetime
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "el-rate",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          "",
                                                                        texts:
                                                                          _vm.texts,
                                                                        "show-text": true,
                                                                        "void-color":
                                                                          "#000000E6",
                                                                        "text-color":
                                                                          "#00000099",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          el.appraisegrade,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              el,
                                                                              "appraisegrade",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "el.appraisegrade",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c("p", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        el.appraisecontent
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                ]),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "el-dialog",
        {
          key: "drawer",
          attrs: {
            title: "回复",
            visible: _vm.drawer,
            "append-to-body": true,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "回复内容" } },
                        [
                          _c("el-input", {
                            attrs: {
                              rows: "4",
                              placeholder: "填写回复内容",
                              maxlength: "长度限制不超过64字",
                              type: "textarea",
                            },
                            model: {
                              value: _vm.feedbacks.feedbackContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.feedbacks, "feedbackContent", $$v)
                              },
                              expression: "feedbacks.feedbackContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.drawer = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.feedbackSub } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          key: "evaluation",
          attrs: {
            title: "发表评价",
            visible: _vm.evaluation,
            "append-to-body": true,
            width: "55%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.evaluation = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "100px" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "评价结果" } },
                          [
                            _c("el-rate", {
                              staticStyle: { "margin-top": "9px" },
                              attrs: { texts: _vm.texts, "show-text": "true" },
                              model: {
                                value: _vm.postAReview.appraiseGrade,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.postAReview,
                                    "appraiseGrade",
                                    $$v
                                  )
                                },
                                expression: "postAReview.appraiseGrade",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "评价内容" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              placeholder: "填写评价内容",
                            },
                            model: {
                              value: _vm.postAReview.appraiseContent,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.postAReview,
                                  "appraiseContent",
                                  $$v
                                )
                              },
                              expression: "postAReview.appraiseContent",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.evaluation = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.evaluationSub },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }