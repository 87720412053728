import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/layout';


export const constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: () => import('@/views/redirect/index')
  }]
},
{
  path: '/login',
  component: () => import('@/views/login/index'),
  hidden: true
},

{
  path: '/overviewPro',
  component: () => import('@/views/overviewPro/index'),
  hidden: true
},

{
  path: '/404',
  component: () => import('@/views/error-page/404'),
  hidden: true
},
{
  path: '/401',
  component: () => import('@/views/error-page/401'),
  hidden: true
},
// 总览全屏
{
  path: '/dashboard/dashboardFullScrenn',
  component: () => import('@/views/dashboard/appNew/totalBigScrenn'),
  hidden: true
},
{
  path: '/register',
  component: () => import('@/views/open-page/register'),
  hidden: true
},
{
  path: '/survey',
  component: () => import('@/views/open-page/survey'),
  hidden: true
},
{
  path: '/disputer',
  component: () => import('@/views/disputer/Index'),
  hidden: true
},
{
  path: '/index2',
  component: () => import('@/views/disputer/index2'),
  hidden: true
},

{
  path: '/customerServiceType',
  component: Layout,
  hidden: true,
  children: [{
    path: '/customerServiceType',
    component: () => import('@/views/customerService/customerServiceType'),
    name: 'customerServiceType',
    meta: {
      title: '服务类型管理',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/publicity',
  component: () => import('@/views/publicityInteraction/publicity'),
  hidden: true
},

{
  path: '/formManager',
  name: 'formManager',
  component: () => import('@/views/FromManager/formManager'),
  hidden: true
},

{
  path: '/SmartController',
  component: Layout,
  hidden: true,
  children: [{
    path: '/SmartController',
    component: () => import('@/views/SmartController'),
    name: 'SmartController',
    meta: {
      title: '智慧助手',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/FromManager',
  component: Layout,
  name: 'FromManager',
  hidden: true,
  children: [{
    path: '/FromManager',
    component: () => import('@/views/FromManager'),
    name: 'FromManager',
    meta: {
      title: '表单管理',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/publicityInteraction',
  component: Layout,
  hidden: true,
  children: [{
    path: '/publicityInteraction',
    component: () => import('@/views/publicityInteraction'),
    name: 'publicityInteraction',
    meta: {
      title: '宣传互动管理',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/PromotionDetails',
  component: Layout,
  hidden: true,
  children: [{
    path: '/PromotionDetails',
    component: () => import('@/views/publicityInteraction/PromotionDetails'),
    name: 'PromotionDetails',
    meta: {
      title: '宣传互动管理',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/UserAllDetails',
  component: Layout,
  hidden: true,
  children: [{
    path: '/UserAllDetails',
    component: () => import('@/views/patrol/UserAllDetails'),
    name: 'UserAllDetails',
    meta: {
      title: '用户详情',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/CreatePromotion',
  component: Layout,
  hidden: true,
  children: [{
    path: '/CreatePromotion',
    component: () => import('@/views/publicityInteraction/CreatePromotion'),
    name: 'CreatePromotion',
    meta: {
      title: '宣传互动管理',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/addCustomerService',
  component: Layout,
  hidden: true,
  children: [{
    path: '/addCustomerService',
    component: () => import('@/views/customerService/addCustomerService'),
    name: 'customerServiceType',
    meta: {
      title: '新增客户服务记录',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/DetailedListForm',
  component: Layout,
  hidden: true,
  children: [{
    path: '/DetailedListForm',
    component: () => import('@/views/factor/DetailedListForm'),
    name: 'DetailedListForm',
    meta: {
      title: '要素详情表单',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '',
  component: Layout,
  redirect: 'customerService',
  children: [{
    path: '/customerService',
    component: () => import('@/views/customerService/customerService'),
    name: 'customerService',
    meta: {
      title: '客户服务记录',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '',
  component: Layout,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: () => import('@/views/dashboard/index'),
    name: 'Dashboard',
    meta: {
      title: '总览',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/safety',
  component: Layout,
  hidden: true,
  children: [{
    path: '/safety',
    component: () => import('@/views/safety/index'),
    name: 'Safety',
    meta: {
      title: '平安联盟',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/msgSend',
  component: Layout,
  hidden: true,
  children: [{
    path: '/msgSend',
    component: () => import('@/views/msgSend/index'),
    name: 'msgSend',
    meta: {
      title: '消息通知',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/publishTaskList',
  component: Layout,
  hidden: true,
  children: [{
    path: '/publishTaskList',
    component: () => import('@/views/publishTaskList/index'),
    name: 'publishTaskList',
    meta: {
      title: '下发任务',
      icon: 'dashboard',
      affix: true
    }
  }]
},  

{
  path: '/reportTaskList',
  component: Layout,
  hidden: true,
  children: [{
    path: '/reportTaskList',
    component: () => import('@/views/reportTaskList/index'),
    name: 'reportTaskList',
    meta: {
      title: '事件信息',
      icon: 'dashboard',
      affix: true
    }
  }]
}, 

{
  path: '/informationPublish',
  component: Layout,
  hidden: true,
  children: [{
    path: '/informationPublish',
    component: () => import('@/views/informationPublish/index'),
    name: 'informationPublish',
    meta: {
      title: '信息发布',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/overview',
  component: Layout,
  hidden: true,
  children: [{
    path: '/overview',
    component: () => import('@/views/overview/index'),
    name: 'Overview',
    meta: {
      title: '勤务总览',
      icon: 'dashboard',
      affix: true
    }
  }]
},

{
  path: '/form',
  component: Layout,
  hidden: true,
  children: [{
    path: '/form',
    component: () => import('@/views/project/my/index'),
    name: 'Form',
    meta: {
      title: '自定义表单',
      icon: 'dashboard',
      affix: true
    }
  },{
    path: '/form/editor',
    component: () => import('@/views/form/editor/index'),
    name: 'editor',
    meta: {
      title: '编辑表单',
      icon: 'dashboard',
      affix: true
    }
  },{
    path: '/form/data',
    component: (resolve) => require(['@/views/form/data'], resolve),
    name: 'editor',
    meta: {
      title: '表单数据',
      icon: 'dashboard',
      affix: true
    }
  }]
},

];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
const createRouter = () => new Router({
  // mode: 'history', // require service support
  routes: constantRoutes,
  scrollBehavior: () => ({y: 0}),
});


const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
