var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          ref: "my-upload",
          class: { hidePlus: _vm.hideUpload },
          attrs: {
            "list-type": _vm.listType,
            action: _vm.action,
            accept: _vm.accept,
            headers: _vm.headers,
            data: _vm.data,
            multiple: _vm.multiple,
            limit: _vm.limit,
            name: _vm.name,
            "auto-upload": _vm.autoUpload,
            disabled: _vm.disabled,
            "preview-file": _vm.handlePreview,
            "file-list": _vm.fileList,
            "on-preview": _vm.onPreview,
            "on-error": _vm.onError,
            "on-progress": _vm.onProgress,
            "on-change": _vm.onChange,
            "before-remove": _vm.beforeRemove,
            "on-remove": _vm.onRemove,
            "on-success": _vm.onSuccess,
            "before-upload": _vm.beforeUpload,
            "on-exceed": _vm.onExceed,
          },
          model: {
            value: _vm.modelValue,
            callback: function ($$v) {
              _vm.modelValue = $$v
            },
            expression: "modelValue",
          },
        },
        [_c("i", { staticClass: "el-icon-plus" })]
      ),
      _c(
        "el-dialog",
        {
          attrs: { "append-to-body": true, visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }