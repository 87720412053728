var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "outer", staticClass: "scrollText" }, [
    _c(
      "div",
      { staticClass: "st-inner", class: { "st-scrolling": _vm.needToScroll } },
      [
        _c("span", { ref: "inner", staticClass: "st-section" }, [
          _vm._v(_vm._s(_vm.text)),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }