const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  size: state => state.app.size,
  device: state => state.app.device,
  appStage: state => state.app.appStage,
  vueRouter: state => state.router.vueRouter,
  isGetModules: state => state.router.isGetModules,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  user: state => state.user,
  uid: state => state.user.uid,
  deptId: state => state.user.deptId,
  mobile: state => state.user.mobile,
  qperationAuthority: state => state.user.qperationAuthority,
  deptCode: state => state.user.deptCode,
  deptName: state => state.user.deptName,
  deptIcon: state => state.user.deptIcon,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  loginName: state => state.user.loginName,
  username: state => state.user.username,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissionKeys: state => state.user.permissionKeys,
  permission_routes: state => state.permission.routes,
  addRouters: state => state.permission.addRouters,
  permission_routes_admin: state => state.permission.routes_admin,
  addRouters_admin: state => state.permission.addRouters_admin,
  errorLogs: state => state.errorLog.logs
};
export default getters;
