import request from '@/utils/request';

// 保存总览信息
export function save(data) {
  return request({
    url: '/web/XyDashboardManager/save',
    method: 'post',
    data
  });
}

// 修改总览信息
export function update(data) {
  return request({
    url: '/web/XyDashboardManager/update',
    method: 'post',
    data
  });
}

// 删除总览信息
export function del(id) {
  return request({
    url: '/web/XyDashboardManager/delete/'+id,
    method: 'get'
  });
}

// 批量删除总览信息
export function batchDel(data) {
  return request({
    url: '/web/XyDashboardManager/batchDelete',
    method: 'post',
    data
  });
}

// 保存角色资源关系
export function getPage(data) {
  return request({
    url: '/web/XyDashboardManager/getPage',
    method: 'post',
    data
  });
}

// 获取总览列表
export function getList(data) {
  return request({
    url: '/web/XyDashboardManager/getList',
    method: 'post',
    data
  });
}

export function getDashBoardByDeptId(sysDeptId){
  return request({
    url: '/web/XyDashboardManager/getDashBoardByDeptId/'+sysDeptId,
    method: 'get'
  });
}
