<template>
  <div>
    <div class="DetailDialog">
      <div class="DetailForm">
        <el-skeleton style="z-index: 99999999999999999;" v-if="isSkeleton" :rows="10" animated />
        <el-form  class="collapse" v-else ref="form" :model="form" label-width="90px">
          <el-form-item label="下发辖区：">
            <div class="content">{{ form.submitDeptName }}</div>
          </el-form-item>
          <el-form-item label="任务类型：">
            <div class="content">{{ form.typeName }}</div>
          </el-form-item>
          <el-form-item label="任务标签：">
            <div class="content">{{ form.taskType }}</div>
          </el-form-item>
          <el-form-item label="任务内容：" v-if="form.submitDesc !== [] ">
            <div class="submitDesc">{{ form.submitDesc }}</div>
          </el-form-item>
          <el-form-item label="任务图片：" v-if="form.submitFileUrls.length !== 0" >
            <!-- <el-image
              style="width: 70px; height: 70px;z-index:2021;"
              v-for="(item,idx) in form.submitFileUrls"
              :key="idx"
              :src="item"
              :preview-src-list="form.submitFileUrls"
            ></el-image> -->
            <TImagePreview
            :data-list="form.submitFileUrls"
             :row-count="3"
             v-if="form.submitFileUrls"
            ></TImagePreview>
          </el-form-item>
          <el-form-item label="任务视频：" v-if="form.submitVideoFileUrls.length !== 0" >
            <div v-for="(item,idx) in form.submitVideoFileUrls" :key="idx">
              <video
                style="width: 270px; height: 170px;"
                :src="item"
                controls="controls"
                crossorigin="Anonymous"
              />
            </div>
          </el-form-item> 
          <el-form-item label="任务音频：" v-if="form.submitAudioFileUrls.length !== 0">
            <div v-for="(item,idx) in form.submitAudioFileUrls" :key="idx">
              <audio class="nowAudio" :src="item" controls="controls"></audio>
            </div>
          </el-form-item>
          <el-form-item label="下发人：">
            <div class="content">{{ form.submitUserName }}</div>
          </el-form-item>
          <el-form-item label="任务地址：" v-if="form.address">
            <div class="content">{{ form.address }}</div>
          </el-form-item>
          <el-form-item label="详细地址：" >
            <div class="content">{{ form.detailedAddress || '无' }}</div>
          </el-form-item>
          <el-form-item label="截止日期:" v-if="form.endDate">
            <div class="content">{{ form.endDate }}</div>
          </el-form-item>
          <el-form-item label="创建日期：">
            <div class="content">{{ form.createTime }}</div>
          </el-form-item>
          <el-form-item label="状态：">
            <div class="content">{{ form.statusName }}</div>
          </el-form-item>
          <br />
        </el-form>
      </div>
      <div class="Detail" v-loading="isSkeleton">
        <div class="TaskDetail">
          <span style="font-size: 1.1rem;float: left;color: #000000E6; font-weight: 400; padding-top: 10px;">完成情况</span>
          <div class="DetailNum">
            <span style="color: #0148A4FF;">
              已签收
              <a>{{mapTaskRecipientDto.已签收}}</a>
            </span>
            <span style="color: #00A870FF;">
              已反馈
              <a>{{mapTaskRecipientDto.已反馈}}</a>
            </span>
            <span style="color: #FF5454FF;">
              待处理
              <a>{{mapTaskRecipientDto.待处理}}</a>
            </span>
          </div>
        </div>
        <div  ref="Task" class="Task"  :style="{ height: myDivHeight + 'px' }">
          <div v-if="button"  ref="button" class="button" >
            <div>
              <el-button v-if="isbutton(TaskIndex)" type="primary" @click.stop="feedback(TaskIndex)" style="margin-left: 15px;" size="mini">回复</el-button>
              <el-button v-if="isbutton1(TaskIndex)" type="primary" @click.stop="evaluations(TaskIndex)" style="margin-left: 15px;" size="mini">评价</el-button>
            </div>
          </div>

          <div class="screen">
            <el-popover
              placement="right"
              v-model="popoverVisible"
              width="400"
              trigger="click"
              >
              <el-form ref="form" :model="screenForm" label-width="80px">
                <el-form-item label="所属辖区">
                  <t-treeselect
                    style="width: 100%"
                    id="deptId"
                    ref="deptId"
                    v-model="screenForm.deptId"
                    name="deptId"
                    no-options-text="无数据"
                    placeholder="选择部门"
                    type="valset"
                    valset-id="100"
                    :params="params222"
                    :clearable="false"
                  />
                </el-form-item>
                <el-form-item label="查询范围">
                  <el-select style="width: 100%;" v-model="screenForm.type" placeholder="请选择查询范围">
                    <el-option label="查询全部" value="1"></el-option>
                    <el-option label="查询本级" value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item  label="接收人">
                  <el-input style="width: 100%;" clearable  placeholder="请输入想要搜索的接收人姓名" v-model="screenForm.userName"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onScreenSubmit(false)">确定</el-button>
                  <el-button  @click="onScreenSubmit(true)">重置</el-button>

                </el-form-item>
              </el-form>
                <van-icon  slot="reference"  size="22" name="filter-o" />
                <span  @click="popoverVisible = true"  slot="reference" >筛选</span>
            </el-popover>
            
          </div>
          <el-tabs  v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="按人查看" name="1">
              <el-collapse
                class="collapse"
                ref="collapse"
                v-model="activeNames"
                accordion
                @change="handleChange"
                v-for="(item,i) in  taskRecipientDtoList"
                :key="i"
              >
                <el-collapse-item :name="i">
                  <template slot="title">
                    <span style="font-weight: 600;">接收人: {{item.userName}} </span>
                                 

                    <div v-if="item.userStatus == 0" >
                      <el-button  style="margin-left: 15px;" size="mini">此人已被删除</el-button>
                    </div>
                    <div v-else>
                      <el-button v-if="item.signStatus == '2'" @click.stop="feedback(i)" style="margin-left: 15px;" size="mini">回复</el-button>
                      <el-button v-if="item.receive && item.signStatus == '2'" @click.stop="evaluations(i)" style="margin-left: 15px;" size="mini">评价</el-button>
                    </div>
                    <div class="tag" >
                      <el-tag style="float: right;margin-right: 15px;"  v-if="item.signStatus == 3"   effect="plain">已签收</el-tag>
                      <el-tag style="float: right;margin-right: 15px;"  v-if="item.signStatus == 2 ||item.signStatus == 4"   type="success" effect="plain"> 已反馈</el-tag>
                      <el-tag style="float: right;margin-right: 15px;"   v-if="item.signStatus == 1"  type="danger"  effect="plain">待处理</el-tag>
                    </div>
                  </template>
                  <div class="dots" v-if="isLdg">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div v-else>
                    <div class="DetailTop">
                      <span>所属辖区: {{DetailList.deptName}}</span>
                      <br />
                      <span>联系电话: {{DetailList.LeaderTel}}   <el-button   @click.stop="call(DetailList)" style="margin-left: 15px;" size="mini">呼叫</el-button></span>
                      <br />
                      <span v-if="DetailList.signTime">签收时间: {{DetailList.signTime}}</span>
                    </div>
                    <div class="DetailItem collapse" v-if="DetailList.policeFeedback.length !== 0" >
                      <!-- 反馈内容 -->
                      <div v-for="(item2,index) in DetailList.policeFeedback" :key="index" >
                        <div style="color: #00000066;margin-bottom: 8px;">{{item2.feedbackUserName}} - {{item2.createTime}} </div>
                          <p>{{item2.feedbackContent}}</p>
                        <TImagePreview
                        :data-list="item2.feedbackFileUrls"
                        :row-count="3"
                        v-if="item2.feedbackFileUrls"
                        ></TImagePreview>
                        <video
                          v-if="item2.feedbackVideoFileUrls.length !== 0"
                          style="width: 270px; height: 170px;"
                          :src="item2.feedbackVideoFileUrls"
                          controls="controls"
                          crossorigin="Anonymous"
                        />
                      </div>
                      <!-- 评价结果 -->
                      <div  v-show="DetailList.evaluateVos.length !== 0 " v-for="(el,inx) in DetailList.evaluateVos" :key="inx">
                        <el-divider></el-divider>
                        <div style="color: #00000066;">{{el.appraiseUserName}} - {{el.appraisetime}} </div>
                        <el-rate
                          v-model="el.appraisegrade"
                          disabled
                          :texts="texts"
                          :show-text="true"
                          void-color="#000000E6"
                          text-color="#00000099"
                          >
                        </el-rate>
                        <p>{{el.appraisecontent}}</p>
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <el-tab-pane label="按辖区查看" name="2">
            <!-- 父级折叠面板 -->
              <el-collapse
                class="collapse"
                ref="collapse2"
                v-model="activeName2"
                accordion
                @change="handleChange2"
                v-for="(val,i) in DeptNameDtoList"
                :key="i"
              >
              <el-collapse-item :name="i">
                  <!-- 父级的标题 -->
                  <template slot="title"  >
                    <div class="title" >
                      <span style="font-weight: 600; color: #000000E6;">{{val.deptName}}</span>
                      <div class="DetailNum">
                        <span style="color: #0148A4FF; ">
                          已签收
                          <a>{{val.mapTaskDeptDto.已签收}}</a>
                        </span>
                        <span style="color: #00A870FF;">
                          已反馈
                          <a>{{val.mapTaskDeptDto.已反馈}}</a>
                        </span>
                        <span style="color: #FF5454FF;">
                          待处理
                          <a>{{val.mapTaskDeptDto.待处理}}</a>
                        </span>
                      </div>
                    </div>
                  </template>

                  <!-- 子级折叠面板 -->
                  <el-collapse
                    v-model="activeNames"
                    accordion
                    @change="handleChange3"
                    v-for="(item,index) in val.taskRecipientDtoList"
                    :key="index"
                  >
                <el-collapse-item :name="index">
                  <template slot="title">接收人: {{item.userName}}
                    <div v-if="item.userStatus == 0" >
                      <el-button  style="margin-left: 15px;" size="mini">此人已被删除</el-button>
                    </div>
                    <div v-else>
                      <el-button v-if="item.signStatus == '2'" @click.stop="feedback2(index)" style="margin-left: 15px;" size="mini">回复</el-button>
                      <el-button v-if="item.receive && item.signStatus == '2'" @click.stop="evaluations2(index)" style="margin-left: 15px;" size="mini">评价</el-button>
                    </div>
                    <div class="tag" >
                      <el-tag style="float: right;margin-right: 15px;"  v-if="item.signStatus == 3"   effect="plain">已签收</el-tag>
                      <el-tag style="float: right;margin-right: 15px;"  v-if="item.signStatus == 2 ||item.signStatus == 4"   type="success" effect="plain"> 已反馈</el-tag>
                      <el-tag style="float: right;margin-right: 15px;"   v-if="item.signStatus == 1"  type="danger"  effect="plain">待处理</el-tag>
                    </div>
                  </template>
                  <div class="dots" v-if="isLdg">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div v-else>
                    <div class="DetailTop">
                      <span>所属辖区: {{DetailList.deptName}}</span>
                      <br />
                      <span>联系电话: {{DetailList.LeaderTel}}  <el-button   @click.stop="call(DetailList)" style="margin-left: 15px;" size="mini">呼叫</el-button></span>
                      <br />
                      <span v-if="DetailList.signTime">签收时间: {{DetailList.signTime}}</span>
                    </div>
                    <div class="DetailItem collapse" v-if="DetailList.policeFeedback.length !== 0" >
                      <!-- 反馈内容 -->
                      <div v-for="(item2,index) in DetailList.policeFeedback" :key="index" >

                        <div style="color: #00000066;margin-bottom: 8px;">{{item2.feedbackUserName}} - {{item2.createTime}} </div>
                          <p>{{item2.feedbackContent}}</p>

                        <TImagePreview
                        :data-list="item2.feedbackFileUrls"
                        :row-count="3"
                        v-if="item2.feedbackFileUrls"
                        ></TImagePreview>
                        <video
                        v-if="item2.feedbackVideoFileUrls.length !== 0"
                          style="width: 270px; height: 170px;"
                          :src="item2.feedbackVideoFileUrls"
                          controls="controls"
                          crossorigin="Anonymous"
                        />
                      </div>
                      <div  v-show="DetailList.evaluateVos.length !== 0 " v-for="(el,inx) in DetailList.evaluateVos" :key="inx">
                        <el-divider></el-divider>
                        <div style="color: #00000066;">{{el.appraiseUserName}} - {{el.appraisetime}} </div>
                        <el-rate
                          v-model="el.appraisegrade"
                          disabled
                          :texts="texts"
                          :show-text="true"
                          void-color="#000000E6"
                          text-color="#00000099"
                          >
                        </el-rate>
                        <p>{{el.appraisecontent}}</p>
                      </div>

                    </div>
                  </div>
                </el-collapse-item>
                </el-collapse>

              </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <el-dialog title="回复"  key="drawer"  :visible.sync="drawer"  :append-to-body="true"  width="600px">
      <el-form label-width="100px">
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="回复内容">
              <el-input rows="4" v-model="feedbacks.feedbackContent" placeholder="填写回复内容" maxlength="长度限制不超过64字" type="textarea"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <br />
        <!-- <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="图片">
              <TUploadPic v-model="file" @on-success="getfile" />
            </el-form-item>
          </el-col>
        </el-row> -->
        <!-- <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="反馈人">{{feedbacks.feedbackUserName}}</el-form-item>
          </el-col>
        </el-row> -->
        <!-- <el-row :gutter="20">
          <el-col :span="10">
            <el-form-item label="联系电话">{{feedbacks.feedbackTel}}</el-form-item>
          </el-col>
        </el-row> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="drawer = false">取 消</el-button>
        <el-button type="primary" @click="feedbackSub">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="发表评价" key="evaluation" :visible.sync="evaluation"  :append-to-body="true"  width="55%">
      <el-form label-width="100px">
        <el-row :gutter="20">
          <el-col :span="10">
            <div>
              <el-form-item label="评价结果">
                <el-rate
                  style="margin-top: 9px"
                  :texts="texts"
                  v-model="postAReview.appraiseGrade"
                  show-text="true"
                ></el-rate>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="评价内容">
              <el-input type="textarea" placeholder="填写评价内容" v-model="postAReview.appraiseContent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="evaluation = false">取 消</el-button>
        <el-button type="primary" @click="evaluationSub">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import TUploadPic from '@/components/trina/base/upload/TUploadPic';
import TImagePreview from "@/components/trina/TrinaUpload/TImagePreview.vue";
import TTreeselect from '@/components/trina/base/select/TTreeSelect';

import {
  getPoliceCirculationDetail,
  taskRecipient,
  getIssuerFeedbackList,
  signAndFeedbackTaskTwo,
  saveTaskAppraise
} from '@/api/modules/policeCirculation';

export default {
  name: 'PublishTaskDetail',
  components: {
    TImagePreview,
    TUploadPic,
    TTreeselect
  },
  props: {
    dialogVisible: {
      type: Boolean
    },
    detailId: {
      type: Number
    },
    formData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    const deptId = sessionStorage.getItem('deptId') ;
    return {
      popoverVisible:false,
      filteredTaskRecipientDtoList: [],
      filteredDeptNameDtoList: [],
      screentaskRecipientDtoList : [],
      screenDeptNameDtoList: [],
      screenForm: {
        deptId:'',
        type: '1',
        userName: ''
      },
      params222: {
        rootId: deptId,
      },
      screenVisible:false,
      myDivHeight: 0,
      mapTaskRecipientDto:{},
      buttonData:{},
      TaskData:{},
      target : null,
      observer: null,
      DeptNameIndex: null,
      button:false,
      Recipient:false,
      TaskIndex:null,
      userName:'',
      isSkeleton: true,
      isLdg: true,
      DetailList: [],
      activeName2: '1',
      activeName: '1',
      DeptNameDtoList: [],
      taskRecipientDtoList: [],
      file: null,
      myUpload: null,
      texts: ['非常差', '比较差', '一般', '好', '非常好'],
      evaluation: false,
      drawer: false,
      postAReview: {
        receiveId: '',
        appraiseContent: '',
        appraiseDeptId: this.$store.state.user.deptId,
        appraiseGrade: 0,
        equipment: 0,
        onSiteDisposal: 0,
        arrivalTime: 0,
        radioResponse: 0,
        appraiseTel: this.$store.state.user.mobile,
        appraiseUserId: this.$store.state.user.uid,
        appraiseUserName: this.$store.state.user.name,
        id: ''
      },
      feedbacks: {
        fileIds: [],
        feedbackContent: '',
        xyPoliceCirculationId: '',
        reply: '',
        feedbackTel: this.$store.state.user.mobile,
        feedbackUserName: this.$store.state.user.name,
        feedbackUserId: this.$store.state.user.uid
      },
      activeNames: '',
      taskRecipient: [],
      dataId: 0,
      form: {},
      reverse: true,
      circulation_type: null
    };
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        console.log('弹框开启');
        this.getDetailInfo(this.$props.detailId);
        this.button = false
      }else{
        console.log('弹框关闭');
        this.isSkeleton = true;
        this.DeptNameDtoList = []
        this.taskRecipientDtoList = []
        this.form = {}
        this.activeNames = ''
        this.button = false
      }
    },
    detailId(val) {
      console.log('id变化');
      this.dataId = val;
    },
    activeName(val){
      console.log('切换查看方式');
      this.activeNames = ''
      this.observer.disconnect(); // 停止观察之前的元素
      this.$refs.button.className = 'button'
      this.button = false

    },
  },
  created() {
    console.log(this.$props.detailId,'11111111111');
  },
  async mounted() {
    this.userName = sessionStorage.getItem('userName')
    await this.getDetailInfo(this.$props.detailId)
    // 创建观察对象监视目标元素是否在界面中
      this.observer = new IntersectionObserver(
        entries => {
            const entry = entries[0];
            if (entry.isIntersecting) {
              console.log('DOM元素进入视口');
                setTimeout(() => {
                 this.$refs.button.className = 'button'
                }, 100);
                this.button = false
            } else {
              console.log('DOM元素离开视口');
                this.button = true
                setTimeout(() => {
                  this.$refs.button.className = 'button buttonPay'
                }, 100);
            }
        },
      { threshold: 0 }
      );
  },
  methods: {
    getData(){
      return this.form
    },
    onScreenSubmit(flag) {
        if(flag){
          
          this.screenVisible = false

          this.screenForm = {
            deptId:sessionStorage.getItem('deptId'),
            type: '1',
            userName: ''
          }

          this.taskRecipientDtoList = JSON.parse(JSON.stringify(this.filteredTaskRecipientDtoList))
          this.DeptNameDtoList  = JSON.parse(JSON.stringify(this.filteredDeptNameDtoList))

          return
        }


        let userName = this.screenForm.userName.trim();
        let deptId = sessionStorage.getItem('deptId');
        
        if (this.screenForm.deptId == deptId) {
            if (this.activeName == 1) {
                if (this.screenForm.type == '1') {
                    if (userName !== '') {
                        this.taskRecipientDtoList   = JSON.parse(JSON.stringify(this.filteredTaskRecipientDtoList)).filter(item => item.userName.includes(userName));
                    } else {
                        this.taskRecipientDtoList   = JSON.parse(JSON.stringify(this.filteredTaskRecipientDtoList));
                    }
                } else {
                    this. taskRecipientDtoList   = JSON.parse(JSON.stringify(this.filteredTaskRecipientDtoList)).filter(item => item.deptId == deptId);
                }
            } else {
                if (this.screenForm.type == '1') {
                    if (userName !== '') {
                        this.DeptNameDtoList   = JSON.parse(JSON.stringify(this.filteredDeptNameDtoList)).filter(dept => {
                            let filteredRecipients = dept.taskRecipientDtoList.filter(item => item.userName.includes(userName));
                            return filteredRecipients.length !== 0;
                        }).map(dept => ({
                            ...dept,
                            taskRecipientDtoList: dept.taskRecipientDtoList.filter(item => item.userName.includes(userName))
                        }));
                    } else {
                        this.DeptNameDtoList  = JSON.parse(JSON.stringify(this.filteredDeptNameDtoList));
                    }
                } else {
                    this.DeptNameDtoList  = JSON.parse(JSON.stringify(this.filteredDeptNameDtoList)).filter(item => item.deptName == sessionStorage.getItem('deptName'));
                }
            }
            return;
        }
        
        if (this.activeName == 1) {
            this.taskRecipientDtoList = JSON.parse(JSON.stringify(this.filteredTaskRecipientDtoList)).filter(item => {
                let userNameMatch = userName === '' || item.userName.includes(userName);
                let deptMatch = this.screenForm.deptId === '' || item.deptId === this.screenForm.deptId;
                let typeMatch = this.screenForm.type === '1' || (this.screenForm.type === '2' && item.deptId === this.screenForm.deptId);
                return userNameMatch && deptMatch && typeMatch;
            });
        } else if (this.activeName == 2) {
            this.DeptNameDtoList  = JSON.parse(JSON.stringify(this.filteredDeptNameDtoList)).map(dept => {
                let filteredRecipients = dept.taskRecipientDtoList.filter(item => {
                    let userNameMatch = userName === '' || item.userName.includes(userName);
                    let deptMatch = this.screenForm.deptId === '' || item.deptId === this.screenForm.deptId;
                    let typeMatch = this.screenForm.type === '1' || (this.screenForm.type === '2' && item.deptId === this.screenForm.deptId);
                    return userNameMatch && deptMatch && typeMatch;
                });
                return {
                    ...dept,
                    taskRecipientDtoList: filteredRecipients
                };
            }).filter(dept => dept.taskRecipientDtoList.length > 0);
        }

        this.popoverVisible = false;
    },
    call(val){
      console.log(val);
      let data = {
        userName:val.LeaderName,
        id:val.userId
      }


      this.$confirm('请选择呼叫' + val.LeaderName + '的方式', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '视频',
        cancelButtonText: '语音',
      })
        .then(() => {
          this.$bus.emit('handleCall', 'video', data, false);
        })
        .catch((action) => {
          if (action === 'cancel') {
            this.$bus.emit('handleCall', 'audio', data, false);
          } else {

          }
        });


    },
    isbutton(val){
      if( this.taskRecipientDtoList[val].signStatus == '2' && this.taskRecipientDtoList[val].userStatus !== 0 ){
        return true
      }else{
         return  false
      }
    },
    isbutton1(val){
      if( this.taskRecipientDtoList[val].receive && this.taskRecipientDtoList[val].signStatus == '2' && this.taskRecipientDtoList[val].userStatus !== 0 ){
        return true
      }else{
         return  false
      }
    },
    handleChange2(val){
      try {
        let index = val
        this.activeNames = ''
        this.DeptNameIndex = index
        this.buttonData = this.DeptNameDtoList[this.DeptNameIndex]
        console.log(this.buttonData);
        console.log(index,'1111111111');
      } catch (error) {
        this.observer.disconnect();
        this.$refs.button.className = 'button'
      }
    },
    handleChange3(val){
      this.TaskIndex = val
      this.TaskData = this.buttonData.taskRecipientDtoList[this.TaskIndex]
      console.log(this.TaskData);
      this.TaskItem(this.DeptNameDtoList[this.DeptNameIndex].taskRecipientDtoList[val]);
    },
    handleChange(val) {
      // 捕获折叠面板展开和收起
      // 由于el-collapse-item上绑定的事件，所以要获取到当前点击的是哪个元素
      // 通过this.$refs.collapse[index].$el.querySelector('.el-collapse-item__header')获取到想要监视的元素
      // 通过this.observer.observe(this.target);开始观察目标元素
      // 通过this.observer.disconnect();停止观察之前的元素
      try {
        this.TaskIndex = val
        console.log(this.TaskIndex);
        this.button = false
        this.TaskItem(this.taskRecipientDtoList[val]);
        if(this.activeName == '2'){ return}
        this.target = this.$refs.collapse[this.TaskIndex].$el.querySelector('.el-collapse-item__header');
        if (this.target) {
            this.observer.observe(this.target);
            console.log(this.target);
        }
      } catch (error) {
        this.observer.disconnect();
        // this.$refs.button.className = 'button'
        this.button = false
      }

    },
    TaskItem(val) {
      console.log(val);
      if (val) {
        this.isLdg = true;
        getIssuerFeedbackList(val.submitUserId, val.id,val.receiveId).then(m => {
          console.log('________________', m);
          this.DetailList = m.data;
          setTimeout(() => {
            this.isLdg = false;
          }, 200);
        });
      }else{
        this.isLdg = false;
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.onScreenSubmit(false)
    },
    evaluationSub() {
      saveTaskAppraise(this.postAReview).then(res => {
        if (res.code == 200) {
          this.Recipient = true
          this.getDetailInfo(this.detailId);
          this.evaluation = false;
          this.$message({
            type: 'success',
            message: res.data
          });
        }
        this.postAReview = {
          appraiseContent: '',
          appraiseDeptId: this.$store.state.user.deptId,
          appraiseGrade: '',
          appraiseTel: this.$store.state.user.mobile,
          appraiseUserId:this.$store.state.user.uid,
          appraiseUserName: this.$store.state.user.name,
          id: ''
        };
      });
      console.log(this.postAReview);
    },
    feedbackSub() {
      signAndFeedbackTaskTwo(this.feedbacks).then(res => {
        if (res.code == 200) {
          this.Recipient = true
          this.getDetailInfo(this.detailId);
          this.drawer = false;
          this.$message({
            type: 'success',
            message: "回复成功"
          });
        }
        this.myUpload.clearFiles();
        this.feedbacks = {
          fileIds: [],
          feedbackContent: '',
          xyPoliceCirculationId: '',
          reply: '',
          feedbackTel: this.$store.state.user.mobile,
          feedbackUserName: this.$store.state.user.name,
          feedbackUserId: this.$store.state.user.uid
        };
      });
      console.log(this.feedbacks);
    },
    getfile(res, file, fileList, myUpload) {
      this.feedbacks.fileIds.push(res.data.fileId);
      this.myUpload = myUpload;
    },
    // 按人查看评价
    evaluations(m) {
      this.TaskIndex = m
      console.log('okkokok1========', this.taskRecipientDtoList);
      this.evaluation = true;
      this.postAReview.receiveId= this.taskRecipientDtoList[m].receiveId
      this.postAReview.id = this.taskRecipientDtoList[m].submitUserId;
      // this.postAReview.appraiseUserId = this.taskRecipientDtoList[m].id;
    },
    // 按人查看反馈
    feedback(s) {
      this.drawer = true;
      this.TaskIndex = s
      console.log('okkokok2========', s);
      console.log(this.taskRecipientDtoList[s].id);
      this.feedbacks.xyPoliceCirculationId = this.taskRecipientDtoList[s].submitUserId;
      this.feedbacks.reply = this.taskRecipientDtoList[s].id;
      console.log("ggg",this.form.id);
      console.log(this.taskRecipientDtoList[s].id);
    },
    // 按辖区查看维度评价
    evaluations2(m) {
      this.TaskIndex = m
      console.log('okkokok3========', this.taskRecipientDtoList);
      this.evaluation = true;
      this.postAReview.receiveId = this.DeptNameDtoList[this.DeptNameIndex].taskRecipientDtoList[m].receiveId
      this.postAReview.id = this.DeptNameDtoList[this.DeptNameIndex].taskRecipientDtoList[m].submitUserId;
      // this.postAReview.appraiseUserId = this.DeptNameDtoList[this.DeptNameIndex].taskRecipientDtoList[m].id;
    },
    // 按辖区查看维度反馈
    feedback2(s) {
      this.drawer = true;
      this.TaskIndex = s
      console.log('okkokok========', s);
      console.log(this.DeptNameDtoList[this.DeptNameIndex].taskRecipientDtoList[s]);
      console.log(this.DeptNameDtoList[this.DeptNameIndex].taskRecipientDtoList[s].id);
      this.feedbacks.xyPoliceCirculationId = this.form.id;
      this.feedbacks.reply = this.DeptNameDtoList[this.DeptNameIndex].taskRecipientDtoList[s].id;
      console.log(this.form.id);
      console.log(this.DeptNameDtoList[this.DeptNameIndex].taskRecipientDtoList[s].id);
    },
    // 详情
   async getDetailInfo(id) {
      if(this.Recipient) {
        this.isSkeleton = true;
        let res = await taskRecipient({ id: id })
        this.DeptNameDtoList = res.data.mapTaskRecipientDeptNameDtoList;
        
        this.filteredDeptNameDtoList = res.data.mapTaskRecipientDeptNameDtoList;
        this.filteredTaskRecipientDtoList = res.data.taskRecipientDtoList;
        
        this.taskRecipientDtoList = res.data.taskRecipientDtoList;
        this.isSkeleton = false;
        this.handleChange(this.TaskIndex)
        this.Recipient = false
        return
      }else{
        let res = await taskRecipient({ id: id })
        let resData =  await getPoliceCirculationDetail(id)
        console.log(res.data, '接收人');
        this.form = resData.data;
        this.DeptNameDtoList = res.data.mapTaskRecipientDeptNameDtoList;

        this.filteredDeptNameDtoList = res.data.mapTaskRecipientDeptNameDtoList;
        this.filteredTaskRecipientDtoList = res.data.taskRecipientDtoList;

        this.taskRecipientDtoList = res.data.taskRecipientDtoList;
        this.mapTaskRecipientDto = res.data.mapTaskRecipientDto;
        if (this.form.status) {
            if (this.form.status == 1) {
              this.form.statusName = '待处理';
            } else if (this.form.status == 2) {
              this.form.statusName = '待评价';
            } else if (this.form.status == 3) {
              this.form.statusName = '执行中';
            } else if (this.form.status == 4) {
              this.form.statusName = '已评价';
            } else if (this.form.status == 5) {
              this.form.statusName = '已反馈';
            } else if (this.form.status == 6) {
              this.form.statusName = '已终止';
            } else if (this.form.status == 7) {
              this.form.statusName = '已终止';
            } else if (this.form.status == 8) {
              this.form.statusName = '已终止';
            }else if (this.form.status == 20) {
              this.form.statusName = '提前终止';
            }
        }
        this.isSkeleton = false;
        console.log(this.isSkeleton);

      }
      console.log(this.taskRecipientDtoList, '辖区');
      let flag = this.taskRecipientDtoList.some(item => item.signStatus == 2)

      if(flag){
        localStorage.setItem('taskRecipientDtoList','1')
      }else{
        localStorage.setItem('taskRecipientDtoList','0')
      };
      console.log(localStorage.getItem('taskRecipientDtoList'),'看看');
      this.$nextTick(() => {

        console.log(this.$refs.form.$el.offsetHeight,'开开开');
        let height = this.$refs.form.$el.offsetHeight;
        this.myDivHeight = height - 28;

      });

    }
  }
};
</script>
<style lang="scss"  scoped>
.collapse{
  animation: tag-fade-in 0.8s ease-out forwards;
}
.DetailDialog {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  animation: tag-fade-in 0.8s ease-out forwards;
  .Detail {
    flex: 1.1;
    .TaskDetail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 2.33rem;
      padding-right: 10px;
      margin-bottom: 10px;
      .DetailNum {
        display: flex;
        align-items: center;
        span {
          margin-left: 1rem;
          font-size: 1rem;

          a {
            font-size: 1.5rem;
            margin-left: 8px;


          }
        }
      }
    }
    .Task {
      width: 100%;
      border: 1px solid #e7e7e7ff;
      overflow: auto;
      position: relative;
      // padding-top: 10px;
      .DetailTop {
        animation: tag-fade-in 0.8s ease-out forwards;
        opacity: 0;
        span {
          display: inline-block;
          margin-bottom: 10px;
          color: #00000099;
        }
      }
      .DetailItem{
        margin-left:10px;
        background-color: #F4F4F4FF;
        padding: 10px;
        width: 90%;
        border-radius: 0.33rem;
        margin-bottom: 10px;
        p {
          margin-top: 8px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .DetailForm {
    flex: 1;
    margin-top: 1rem;
  }
}

@keyframes tag-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.status {
  border-radius: 7px;
  font-size: 10px !important;
  line-height: 14px;
  padding: 0 5px;
  color: #fff;
  width: 48px;
  float: right;
}
.status.red {
  background-color: #ff5252;
}
.status.green {
  background-color: #087406;
}
.status.yellow {
  background-color: #fce308;
}
.content {
  float: left;
}
/deep/ .el-image-viewer__wrapper {
  z-index: 2221 !important;
}
/deep/ .el-form-item {
  margin-bottom: 1.3rem !important;
}
/deep/ .el-tabs__item {
  padding-left: 15px !important;
  text-align: center;
}
/deep/ .el-tabs__header {
  position: sticky !important;
  z-index: 99 !important;
  background-color: #fff;
  top: 0px;
  margin: 0 !important;
}
/deep/ .el-collapse-item__header {
  padding-left: 0.98vw !important;
  line-height: 15px;
  display: flex;

  .tag {
    flex: 2;
    font-weight: normal;
  }

}
/deep/ .el-collapse-item__content {
  padding-left: 15px !important;
  padding-bottom: 0 !important;
}
.el-divider--horizontal {
  margin: 10px 0;
}
/deep/ .el-skeleton{
  margin-top:30px;
  width: 75%;
  .el-skeleton__item {
    height: 13px;
    margin-bottom: 8px;
  }
  :nth-child(3){
    height: 70px;
  }
  :nth-child(4){
    height: 150px;
  }
}
.dots {
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: 10px;
  opacity: 0.5;
  margin-bottom: 15px;
}

.dots div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #0148a4ff;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}
.submitDesc{
  width: 95%;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.nowAudio{
  width: 95%;
}
.button{
  position: sticky;
  top: 0px;
  float: right;
  z-index: 1;
  transition: all 0.5s;
  opacity: 0;
  padding-right: 30px;
}
.buttonPay{
  opacity: 1;
  top: 45px;
}

.screen{
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  z-index: 999;
  right: 20px;
  top: 10px;
}

.title{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 0.5vw;
  align-items: center;
  margin-bottom: 0 !important;
  margin-top: 10px;
  .DetailNum{
    span {
      margin-left: 0.5vw;
      font-size: 0.5vw;
      a {
        font-size: 1.2vw !important;
        }
    }
  }
}
</style>
