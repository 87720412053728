import {
  asyncRoutes,
  constantRoutes,
  adminRoutes
} from '@/router';

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  // if (route.meta && route.meta.roles) {
  //   return roles.some(role => route.meta.roles.includes(role))
  // } else {
  //   return true
  // }
  return true;
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = [];
  console.log('--filterAsyncRoutes--', routes);
  routes.forEach(route => {
    const tmp = {
      ...route
    };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

const state = {
  routes: [],
  addRoutes: [],
  routes_admin: [],
  addRoutes_admin: []
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = state.routes = constantRoutes.concat(routes);
  },
  SET_ROUTES_ADMIN: (state, routes) => {
    state.addRoutes_admin = state.routes_admin = adminRoutes.concat(routes);
  }
};

const actions = {
  generateRoutes({
    commit
  }, permissionKeys) {
    return new Promise(resolve => {
      console.log('--generateRoutes--', permissionKeys);
      let accessedRoutes = [];
      // if (roles.includes('admin')) {
      accessedRoutes = asyncRoutes || [];
      // } else {
      //   accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      // }
      console.log('--accessedRoutes--', accessedRoutes);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  },
  generateAdminRoutes({
    commit
  }, permissionKeys) {
    return new Promise(resolve => {
      console.log('--generateRoutes--', permissionKeys);
      let accessedRoutes = [];
      // if (roles.includes('admin')) {
      accessedRoutes = adminRoutes || [];
      // } else {
      //   accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
      // }
      console.log('--accessedRoutes--', accessedRoutes);
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
