import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
  return sessionStorage.getItem("token");
}

export function setToken(token) {
  return sessionStorage.setItem("token", token);
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getLanguage() {
  return localStorage.getItem("language");
}
