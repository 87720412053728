var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "div",
      staticStyle: { padding: "1.33rem" },
      attrs: { id: "item2" },
      on: {
        click: function ($event) {
          _vm.currentIndex = -1
        },
      },
    },
    _vm._l(_vm.items, function (item, index) {
      return _c("div", { key: item.id }, [
        _c(
          "div",
          {
            staticClass: "item77",
            style: { animationDelay: `${(index / 3) * 0.1}s` },
          },
          [
            _c(
              "span",
              { staticClass: "name", staticStyle: { "font-weight": "bold" } },
              [_vm._v(_vm._s(item.systemName))]
            ),
            item.alias
              ? _c(
                  "span",
                  {
                    staticClass: "name1",
                    staticStyle: { "font-weight": "bold" },
                  },
                  [_vm._v(_vm._s(`【${item.alias}】`))]
                )
              : _vm._e(),
            _vm.currentIndex === item.id
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "flex-grow": "1",
                      "border-left": "1px solid #00000066",
                      "padding-left": "15px",
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.alias,
                          expression: "alias",
                        },
                      ],
                      staticClass: "input-box",
                      attrs: {
                        type: "text",
                        maxlength: "10",
                        placeholder: "请输入菜单别名，按下Enter键确认",
                      },
                      domProps: { value: _vm.alias },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.addName()
                        },
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleInputClick(item, index)
                        },
                        blur: function ($event) {
                          _vm.currentIndex = -1
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.alias = $event.target.value
                        },
                      },
                    }),
                  ]
                )
              : _c("i", {
                  staticClass: "el-icon-edit",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.showInput(item, index)
                    },
                  },
                }),
            item.children.length !== 0 && _vm.Nested !== item.id
              ? _c("i", {
                  staticClass: "el-icon-arrow-right",
                  staticStyle: {
                    float: "right",
                    "margin-right": "10px",
                    cursor: "pointer",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showNested(item)
                    },
                  },
                })
              : _vm._e(),
            item.children.length !== 0 && _vm.Nested == item.id
              ? _c("i", {
                  staticClass: "el-icon-arrow-down",
                  staticStyle: {
                    float: "right",
                    "margin-right": "10px",
                    cursor: "pointer",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.showNested(item)
                    },
                  },
                })
              : _vm._e(),
          ]
        ),
        item.children && _vm.Nested == item.id
          ? _c(
              "div",
              [
                _c("NestedList", {
                  attrs: { items: item.children },
                  on: {
                    click: function ($event) {
                      _vm.currentIndex = -1
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }