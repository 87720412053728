export const formProps = {
  size: {
    type: String,
    default: '',
    validator: sizeValidator
  },
  labelWidth: {
    type: Number,
    default: 100
  },
  labelPosition: {
    type: String,
    default: 'right'
  },
  inline: {
    type: Boolean,
    default: false
  },
  hideRequiredAsterisk: {
    type: Boolean,
    default: false
  },
  itemWidth: Number,
  colNum: {
    type: Number,
    default: 1
  },
  title: {
    type: String,
    default: ''
  },
  formStyle: {
    type: String,
    default: 'width:100%;'
  },
  items: {
    type: Array,
    required: true
  },
  rules: Object,
  btns: Array
};

function sizeValidator(value) {
  const methodTypes = ['medium', 'small', 'mini'];
  const valid = methodTypes.indexOf(value.toLowerCase()) !== -1 || value === '';
  if (!valid) {
    throw new Error(`Size must be one of ['large', 'small', 'mini']`);
  }
  return valid;
}
