<template>
  <div />
</template>

<script>
import { getToken } from '@/utils/auth';
export default {
  name: 'TBaseUpload',
  props: {
    value: {
      required: true
    }, // 接受外部v-model传入的值
    action: {
      type: String,
      default: '/web/api/v1/file/upload'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 3
    },
    multiple: {
      type: Boolean,
      default: false
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    headers: {
      type: Object,
      default: () => {
        return {
          Authorization: getToken(),
          language: 'zh'
        };
      }
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    name: {
      type: String,
      default: 'file'
    },
    accept: {
      // 接受上传的文件类型(thumbnail-mode 模式下此参数无效）
      type: String,
      default: ''
    },
    // fileList: {
    //   type: Array,
    //   default: () => {
    //     return []
    //   }
    // },
    limitFileSize: {
      // 单个文件限制(单位KB),默认1024KB  *200
      type: Number,
      default: 204800
    }
  },
  data() {
    return {
      modelValue: '',
      fileList: [],
      hideUpload: false
    };
  },
  watch: {
    modelValue(val, oldVal) {
      // console.log('TBaseUpload-modelValue-', val, oldVal);
      if (val != oldVal) {
        this.$emit('input', this.modelValue);
        //搞不懂为什么每次进来都要给空  上面默认值不行吗
        this.fileList = [];
        if (this.modelValue instanceof Array && this.modelValue.length > 0) {
          this.modelValue.forEach((item, index) => {
            this.fileList.push({
              name: 'name' + index,
              url: item
            });
          });
        } else {
          // 删除时
          if (this.modelValue) {
            this.fileList = [
              {
                name: 'name',
                url: this.modelValue
              }
            ];
          }
        }
        this.hideUpload = this.fileList.length >= this.limit;
      }
    },
    value(val, oldVal) {
      // console.log('TBaseUpload-value-', val, oldVal);
      this.modelValue = val;
    },
    change() {
      // console.log('TControl-' + this.name, this.modelValue);
      this.$emit('change', this.name, this.modelValue);
    }
  },
  methods: {
    emitEventHandler(event) {
      this.$emit(event, ...Array.from(arguments).slice(1));
    }
  }
};
</script>

<style scoped></style>
