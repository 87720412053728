<template>
  <el-checkbox-group
    :ref="name"
    v-model="modelValue"
    :name="name"
    @change="change"
  >
    <el-checkbox
      v-for="(option, idx) in groupData"
      :key="idx"
      :label="option.value"
    >{{ option.label }}</el-checkbox>
  </el-checkbox-group>
</template>
<script>

import TBaseGroup from '@/components/trina/base//TBaseGroup';
export default {
  name: 'TCheckbox',
  extends: TBaseGroup,
  props: {
    name: {
      type: String,
      default: 'TCheckbox'
    },
    defaultValue: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      modelValue: []
    };
  },
  mounted() {
    this.setSelectValue();
  },
  methods: {
    change() {
      this.$emit('change', this.name, this.modelValue);
    },
    setSelectValue() {
      this.mvalue = this.modelValue = this.defaultValue;
    }
  }
};
</script>

<style scoped>
</style>
