<template>
  <el-radio-group
    :ref="name"
    v-model="modelValue"
    :name="name"
    @change="change"
  >
    <el-radio
      v-for="(option, idx) in groupData"
      :key="idx"
      :label="option.value"
    >
      {{ option.label }}
    </el-radio>
  </el-radio-group>
</template>
<script>
import TBaseGroup from '@/components/trina/base//TBaseGroup'
export default {
  name: 'TRadio',
  extends: TBaseGroup,
  props: {
    name: {
      type: String,
      default: 'TRadio'
    }
  },
  data() {
    return {}
  },
  methods: {
    change() {
      this.$emit('change', this.name, this.modelValue)
    }
  }
}
</script>

<style scoped>
</style>
