import FileSaver from "file-saver";
import * as XLSX from "xlsx";
//表单导出封装纯表单无分页
export default{
  install (Vue, options) {
  Vue.prototype.exportExcels = function (tableAll) {
    //for循环导出多个表格 防止sheet页重复
    for(let i=0;i<tableAll.length;i++){
      let table = tableAll[i].table;
      let name = tableAll[i].name;
      let wb = XLSX.utils.table_to_book(document.querySelector(table), {
        sheet: name
      });
      let wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], {
            type: "application/octet-stream"
          }),
          name + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      // return wbout;
    }
  }}
}
