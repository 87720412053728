<template>
  <div>
    <el-form ref="form" :model="form" label-width="90px">
      <el-form-item label="反映辖区：">
        <div class="content">{{ form.deptName }}</div>
      </el-form-item>
      <el-form-item label="事件类型：">
        <div class="content">{{ form.typeName }}</div>
      </el-form-item>
      <el-form-item label="事件标签：">
        <div class="content">{{ form.labelName }}</div>
      </el-form-item>
      <el-form-item label="事件内容：">
        <div class="content">{{ form.submitDesc }}</div>
      </el-form-item>
      <el-form-item label="事件图片：" v-if="submitFileUrls.length > 0">
        <el-image
          style="width: 100px; height: 100px; z-index: 2021"
          v-for="(item, idx) in submitFileUrls"
          :key="idx"
          :src="item"
          :preview-src-list="submitFileUrls"
        >
        </el-image>
      </el-form-item>
      <el-form-item label="事件视频：" v-if="submitVideoFileUrls.length > 0">
        <div v-for="(item, idx) in submitVideoFileUrls" :key="idx">
          <video
            style="width: 200px; height: 200px"
            :src="item"
            controls="controls"
            crossOrigin="Anonymous"
          />
        </div>
      </el-form-item>
      <el-form-item label="事件音频：" v-if="submitAudioFileUrls.length > 0">
        <div v-for="(item, idx) in submitAudioFileUrls" :key="idx">
          <audio class="nowAudio" :src="item" controls="controls"></audio>
        </div>
      </el-form-item>
      <el-form-item label="反映人：">
        <div class="content">{{ form.username }}</div>
      </el-form-item>
      <el-form-item label="事件地址：">
        <div class="content" v-if='form.detailedAddress'>{{ form.address }} ({{ form.detailedAddress }})
        </div>
        <div class="content" v-else>{{ form.address }}</div>
      </el-form-item>
<!--      <el-form-item label="详细地址：">-->
<!--        <div class="content">{{ form.detailedAddress }}</div>-->
<!--      </el-form-item> -->
      <el-form-item label="联系电话：">
        <div class="content">{{ form.submitTel }}</div>
      </el-form-item>
      <el-form-item label="反映时间：">
        <div class="content">{{ form.createTime }}</div>
      </el-form-item>
      <br />
      <el-timeline :reverse="reverse">
        <el-timeline-item
          v-for="(item, index) in form.feedbackDtoList"
          :key="index"
          :timestamp="item.createTime"
        >
          <label>{{ item.feedbackUserName }}</label>
          <br />
          <br />
          {{ item.feedbackContent }}
          <br />
          <el-image
            style="width: 100px; height: 100px; z-index: 2021; margin-top: 10px"
            v-for="(img, idx) in item.feedbackFileUrls"
            :key="idx"
            :src="img"
            :preview-src-list="item.feedbackFileUrls"
          >
          </el-image>
        </el-timeline-item>
      </el-timeline>
      <!-- 事件流程 -->
      <div class="bootContent">
        <div class="statustitle">
          <span class="titlest" v-if="this.form.status === 14"> 事件状态·</span>
          <span class="titlest" v-else> 处理进度·</span>
          <span v-show="this.form.status === 1">待处理</span>
          <span v-show="this.form.status === 2">已签收</span>
          <span v-show="this.form.status === 12">已驳回</span>
          <span v-show="this.form.status === 10">已移交</span>
          <span v-show="this.form.status === 14">已撤销</span>
          <span v-show="this.form.status === 18">已处理</span>
        </div>
        <div v-if="this.form.status != 14">
          <div ref="stepItem" class="stepItem">
            <div class="taptitle">
              <div class="img" v-if="this.a[0].status == 12">
                <img :src="img2" alt />
              </div>
              <div class="img" v-if="this.a[0].status == 2">
                <img :src="img4" alt />
              </div>
              <div
                class="img"
                v-if="
                  this.a[0].status == 1 ||
                  this.a[0].status == 10 ||
                  this.a[0].status == 14 ||
                  this.a[0].status == 18
                "
              >
                <img :src="img1" alt />
              </div>
              <span class="span">反映对象</span>
            </div>
            <div
              v-for="(item, index) in a"
              :key="index"
              :class="{ bdn: c.length == 0 }"
            >
              <div class="stepBox">
                <div class="Item1205s">
                  <div class="deptName1205s ">
                    <span>{{ item.deptName }}</span>
                  </div>
                  <div
                    class="time1205s"
                    v-if="item.hasOwnProperty('handleTime')"
                  >
                    <span v-show="item.status === 1"
                      >待处理·{{ setTime(item.handleTime) }}</span
                    >
                    <span v-show="item.status === 2"
                      >已签收·{{ setTime(item.handleTime) }}</span
                    >
                    <span v-show="item.status === 12" style="color: red"
                      >已驳回·{{ setTime(item.handleTime) }}</span
                    >
                    <span v-show="item.status === 10"
                      >已移交·{{ setTime(item.handleTime) }}</span
                    >
                    <span v-show="item.status === 14"
                      >已撤销·{{ setTime(item.handleTime) }}</span
                    >
                    <span v-show="item.status === 18"
                      >已处理·{{ setTime(item.handleTime) }}</span
                    >
                  </div>
                </div>
                <!-- 本人处理 -->
                <div v-if=" item.status == 18 &&
                    item.hasOwnProperty('handleRemark') &&
                    item.handleRemark != 'null'
                  "
                  class="handleRemark1205s"
                >
                  处理方式：{{ item.handleRemark }}
                </div>
                <div  class="gagaga" v-if=" item.status == 18 && rewardRecord.id&&item.handleRemark">
                  <div class="Itemgaga">
                    <div class="deptNamegaga">
                      <span>{{ rewardRecord.sysDeptName }}</span>
                    </div>
                    <div class="timegaga" >
                    <span v-show="item.status === 18">打赏积分·{{ setTime(rewardRecord.createTime) }}</span>
                    </div>
                  </div>
                  <div class="handleRemarkGaga">
                    {{rewardRecord.rewardPoint}}分：{{ rewardRecord.rewardContent }}
                  </div>
                </div>
                <!-- 驳回事件 -->
                <div
                  v-if="
                    item.status == 12 &&
                    item.hasOwnProperty('handleRemark') &&
                    item.handleRemark != 'null'
                  "
                  class="handleRemark"
                >
                  {{ item.handleRemark }}
                </div>
                <!-- 移交事件 -->
                <div
                  v-if="
                    item.status == 10 &&
                    item.hasOwnProperty('handleRemark') &&
                    item.handleRemark != 'null'
                  "
                  class="handleRemark"
                >
                  {{ item.handleRemark }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="stepItem"
            v-for="(item, index) in c"
            :key="index"
            :class="{ bdn: index == c.length - 1 }"
          >
            <div class="taptitle">
              <div class="img" v-if="item.status == 12">
                <img :src="img2" alt />
              </div>
              <div class="img" v-if="item.status == 2">
                <img :src="img4" alt />
              </div>
              <div
                class="img"
                v-if="
                  item.status == 1 ||
                  item.status == 10 ||
                  item.status == 14 ||
                  item.status == 18
                "
              >
                <img :src="img1" alt />
              </div>
              <span class="span">移交对象</span>
            </div>
            <div class="stepBox">
              <div class="Item">
                <div class="deptName">
                  <span>{{ item.deptName }}</span>
                </div>
                <div
                  class="time"
                  style="font-size: 12px"
                  v-if="item.hasOwnProperty('handleTime')"
                >
                  <span v-show="item.status === 1"
                    >待处理·{{ setTime(item.handleTime) }}</span
                  >
                  <span v-show="item.status === 2"
                    >已签收·{{ setTime(item.handleTime) }}</span
                  >
                  <span v-show="item.status === 12" style="color: red"
                    >已驳回·{{ setTime(item.handleTime) }}</span
                  >
                  <span v-show="item.status === 10"
                    >已移交·{{ setTime(item.handleTime) }}</span
                  >
                  <span v-show="item.status === 14"
                    >已撤销·{{ setTime(item.handleTime) }}</span
                  >
                  <span v-show="item.status === 18"
                    >已处理·{{ setTime(item.handleTime) }}</span
                  >
                </div>
              </div>
              <!-- 本人处理 -->
              <div
                v-if="
                  item.status == 18 &&
                  item.hasOwnProperty('handleRemark') &&
                  item.handleRemark != 'null'
                "
                class="handleRemark"
              >
                <span v-if="item.processMode == 1">处理方式：</span
                >{{ item.handleRemark }}
              </div>
              <div  class="gagaga" v-if=" item.status == 18 && rewardRecord.id&&item.handleRemark">
                <div class="Itemgaga">
                  <div class="deptNamegaga">
                    <span>{{ rewardRecord.sysDeptName }}</span>
                  </div>
                  <div class="timegaga" style="font-size: 12px">
                    <span v-show="item.status === 18">打赏积分·{{ setTime(rewardRecord.createTime) }}</span>
                  </div>
                </div>
                <div class="handleRemarkGaga">
                  {{rewardRecord.rewardPoint}}分：{{ rewardRecord.rewardContent }}
                </div>
              </div>
              <!-- 驳回事件 -->
              <div
                v-if="
                  item.status == 12 &&
                  item.hasOwnProperty('handleRemark') &&
                  item.handleRemark != 'null'
                "
                class="handleRemark"
              >
                {{ item.handleRemark }}
              </div>
              <!-- 移交事件 -->
              <div
                v-if="
                  item.status == 10 &&
                  item.hasOwnProperty('handleRemark') &&
                  item.handleRemark != 'null'
                "
                class="handleRemark"
              >
                {{ item.handleRemark }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import {
  getPropertyReportListParticulars,
  getPropertyRole,
  getPropertyRoles,
} from '@/api/modules/policeCirculation';
export default {
  name: 'PublishTaskDetail',
  props: {
    dialogVisible: {
      type: Boolean,
    },
    detailId: {
      type: String,
    },
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    rewardRecord: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      a: [],
      b: [],
      c: [],
      ReflectStatusList: [],
      img1: require('@/assets/待处理.png'),
      img2: require('@/assets/已驳回.png'),
      img4: require('@/assets/已签收.png'),
      submitAudioFileUrls: [],
      submitFileUrls: [],
      submitVideoFileUrls: [],
      dataId: '',
      form: {},
      reverse: true,
      typedata: [],
      ziti: '',
      rewardRecord: {}
  };
  },
  watch: {
    detailId(val) {
      this.dataId = val;
      console.log(val, '1111111111');
    },
    formData(val) {
      console.log(val);
      this.form = val;
    },
    rewardRecord(val){
      this.rewardRecord = val;
    },
    dialogVisible(val) {
      // 弹框开启
      if (val) {
        console.log('弹框开启', val);

        this.getDetailInfo(this.detailId);
        this.a = [];
        this.c = [];
      }
    },
  },
  mounted() {
    this.form = this.formData;
    this.dataId = this.detailId;
    // getRewardRecord({source:"intelligence", bizId: this.detailId}).then(res => {
    //   if(res.data && res.data.length == 0){
    //     this.rewardRecord = res.data
    //   }
    // });
    this.getDetailInfo(this.dataId);
  },

  methods: {
    async getPropertyRole() {
      const params = {
        withoutRepetitionId: this.dataId,
      };
      const res = await getPropertyRole(params);
      this.typedata = res.data;
      this.$emit('typedata', res.data);
      console.log(res, 'typedata');
    },

    async getPropertyRoles() {
      const params = {
        withoutRepetitionId: this.dataId,
        status: this.form.status,
      };
      const res = await getPropertyRoles(params);
      this.ziti = res.data;
      this.$emit('ziti', res.data);
      console.log(res, 'ziti');
    },

    setTime(val) {
      let time = val.substring(5).replace('-', ' / ');
      return time;
    },

    async getDetailInfo(id) {
      let reflectDept = {
        withoutRepetitionId: id,
      };

      const resData = await getPropertyReportListParticulars(reflectDept);
      console.log(resData.data);
      this.policeCirculationOtherInformationDtos = resData.data.policeCirculationOtherInformationDtos[0];
      this.submitAudioFileUrls = resData.data.submitAudioFileUrls;
      this.submitVideoFileUrls = resData.data.submitVideoFileUrls;
      this.submitFileUrls = resData.data.submitFileUrls;
      this.form = resData.data.policeCirculationListDtos[0];
      this.ReflectStatusList = resData.data.policeCirculationOtherInformationDtos;
      console.log(
        resData.data.fanYingObj,
        '您当前没有此事件的处理权限，只能查看处理的进度哦～'
      );
      this.a = resData.data.fanYingObj;
      this.c = resData.data.notFanYingObj;

      // 判断进度条
      await this.getPropertyRole();
      await this.getPropertyRoles();

      if (this.form.status) {
        if (this.form.status == 1) {
          this.form.statusName = '待反馈';
        } else if (this.form.status == 2) {
          this.form.statusName = '已完成';
        } else if (this.form.status == 12) {
          this.form.statusName = '被驳回';
        }
        if (this.form.status == 10) {
          this.form.statusName = '已移交';
        }
        if (this.form.status == 14) {
          this.form.statusName = '已撤销';
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  float: left;
}
.bootContent {
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;

  .statustitle {
    padding: 12px 0;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 16px;
    .titlest {
    }
  }
}
.statust1 {
  font-size: 8px !important;
  padding: 2px 4px;
  color: #0148a4;
  width: 1.83rem;
  border: 0.5px solid #0148a4;
}
.statust2 {
  width: 1.83rem;
  font-size: 8px !important;
  padding: 2px 4px;
  color: #00a870;
  border: 0.5px solid #00a870;
}
.status12 {
  width: 1.83rem;
  font-size: 8px !important;
  padding: 2px 4px;
  color: #ff6a6a;
  border: 0.5px solid #ff6a6a;
}
.status10 {
  width: 1.83rem;
  font-size: 8px !important;
  padding: 2px 4px;
  color: #7fa2d1;
  border: 0.5px solid #7fa2d1;
}
.status14 {
  width: 1.83rem;
  font-size: 8px !important;
  padding: 2px 4px;
  color: #000000a1;
  border: 0.5px solid #000000a1;
}
.status18 {
  width: 1.83rem;
  font-size: 8px !important;
  padding: 2px 4px;
  color: #0148a4;
  border: 0.5px solid #0148a4;
}
.stepItem {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;

  .taptitle {
    display: flex;

    .img {
      width: 22px;
      height: 22px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .span {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);
      line-height: 16px;
    }
    span {
      line-height: 1.88rem;
      padding-left: 1rem;
    }
  }
}
.stepBox {
  border-left: 0.15rem solid #c5c5c5;
  margin-left: 0.6rem;
  padding-left: 1.7rem;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  // line-height: 1.83rem;
  .handleRemark1205s {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    padding: 8px 0;
    color: rgba(0, 0, 0, 0.9);
    // line-height: 14px;
  }
  .gagaga{
    margin-top: 30px;
    .Itemgaga{
      display: flex;
      flex-direction: row;
      flex-wrap:wrap;
      justify-content:space-between;
      width: 100%;
      .deptNamegaga{
        color: black;
      }
      .timegaga{
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .handleRemarkGaga{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      padding: 8px 0;
      color: rgba(0, 0, 0, 0.9);
    }
  }
  .Item1205s {
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content:space-between;
    width: 100%;
    .deptName1205s {
      color: black;
    }
    .time1205s {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
.bdn {
  .stepBox {
    border-left: none !important;
  }
}
/deep/ .el-image-viewer__wrapper {
  z-index: 2221 !important;
}
/deep/ .el-form-item {
  margin-bottom: 2px !important;
}
</style>
