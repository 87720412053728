
export const props = {
  options: {
    type: Array,
    default: ()=>[]
    },
  SetDeptId: {
    //设置 true为允许默认选择当前辖区。
    type: Boolean,
    default: true
  },
  defaultValue:{
    //设置默认值
    type: [String, Number],
    default: null
  },
  multiple: {
    //设置 true为允许选择多个选项（又名多重选择模式）。
    type: Boolean,
    default: false
  },
  allowClearingDisabled: {
    //即使禁用了选定的节点，是否也允许重置值。
    type: Boolean,
    default: false
  },
  allowSelectingDisabledDescendants: {
    //选择/取消选择祖先节点时，是否应该选择/取消选择其禁用的后代。您可能希望将其与 allowClearingDisabled 结合使用。
    type: Boolean,
    default: false
  },
  alwaysOpen: {
    //菜单是否应始终打开。
    type: Boolean,
    default: false
  },
  appendToBody: {
    //将菜单追加到 <body />.
    type: Boolean,
    default: false
  },
  async: {
    //是否启用 异步搜索模式.
    type: Boolean,
    default: false
  },
  autoFocus: {
    //自动将组件聚焦。
    type: Boolean,
    default: false
  },
  autoLoadRootOptions: {
    //在装入时自动加载root选项。设置false, 为时，打开菜单时将加载根选项。
    type: Boolean,
    default: false
  },
  autoDeselectAncestors: {
    //当用户取消选择节点时，将自动取消选择其祖先。仅适用于平面模式。
    type: Boolean,
    default: false
  },
  autoDeselectDescendants: {
    //当用户取消选择节点时，将自动取消选择其后代。仅适用于平面模式。
    type: Boolean,
    default: false
  },
  autoSelectAncestors: {
    //当用户选择节点时，将自动选择其祖先。仅适用于平面模式。
    type: Boolean,
    default: false
  },
  autoSelectDescendants: {
    //当用户选择一个节点时，将自动选择其后代。仅适用于平面模式。
    type: Boolean,
    default: false
  },
  backspaceRemoves: {
    //Backspace如果没有文本输入，是否删除最后一项。
    type: Boolean,
    default: true
  },
  beforeClearAll: {
    //在清除所有输入字段之前处理的功能。返回 false 到要清除的停止值
    type: Boolean,
    default: false
  },
  branchNodesFirst: {
    //在叶节点之前显示分支节点。
    type: Boolean,
    default: false
  },
  /**
   * Should cache results of every search request?
   * 是否为 异步搜索模式缓存每个搜索请求的结果。
   */
  cacheOptions: {
    type: Boolean,
    default: true
  },
  /**
   * Show an "×" button that resets value?
   * 是否显示重置值的“×”按钮。
   */
  clearable: {
    type: Boolean,
    default: true
  },
  /**
   * Title for the "×" button when `multiple: true`.
   * :multiple="true"时“×”按钮的标题
   */
  clearAllText: {
    type: String,
    default: '清空'
  },

  /**
   * Whether to clear the search input after selecting.
   * Use only when `multiple` is `true`.
   * For single-select mode, it **always** clears the input after selecting an option regardless of the prop value.
   */
  clearOnSelect: {
    type: Boolean,
    default: false
  },

  /**
   * Title for the "×" button.
   */
  clearValueText: {
    type: String,
    default: '清空'
  },

  /**
   * Whether to close the menu after selecting an option?
   * Use only when `multiple` is `true`.
   */
  closeOnSelect: {
    type: Boolean,
    default: true
  },

  /**
   * How many levels of branch nodes should be automatically expanded when loaded.
   * Set `Infinity` to make all branch nodes expanded by default.
   */
  defaultExpandLevel: {
    type: Number,
    default: 0
  },

  /**
   * The default set of options to show before the user starts searching. Used for async search mode.
   * When set to `true`, the results for search query as a empty string will be autoloaded.
   * @type {boolean|node[]}
   */
  defaultOptions: {
    default: false
  },

  /**
   * Whether pressing delete key removes the last item if there is no text input.
   */
  deleteRemoves: {
    type: Boolean,
    default: true
  },

  /**
   * Delimiter to use to join multiple values for the hidden field value.
   */
  delimiter: {
    type: String,
    default: ','
  },

  /**
   * Only show the nodes that match the search value directly, excluding its ancestors.
   *
   * @type {Object}
   */
  flattenSearchResults: {
    type: Boolean,
    default: false
  },

  /**
   * Prevent branch nodes from being selected?
   */
  disableBranchNodes: {
    type: Boolean,
    default: false
  },

  /**
   * Disable the control?
   */
  disabled: {
    type: Boolean,
    default: false
  },

  /**
   * Disable the fuzzy matching functionality?
   */
  disableFuzzyMatching: {
    type: Boolean,
    default: false
  },

  /**
   * Whether to enable flat mode or not. Non-flat mode (default) means:
   *   - Whenever a branch node gets checked, all its children will be checked too
   *   - Whenever a branch node has all children checked, the branch node itself will be checked too
   * Set `true` to disable this mechanism
   */
  flat: {
    type: Boolean,
    default: false
  },

  /**
   * Will be passed with all events as the last param.
   * Useful for identifying events origin.
   */
  instanceId: {
    type: String | Number,
    default: `<auto-incrementing number>$$`
  },

  /**
   * Joins multiple values into a single form field with the `delimiter` (legacy mode).
   */
  joinValues: {
    type: Boolean,
    default: false
  },

  /**
   * Limit the display of selected options.
   * The rest will be hidden within the limitText string.
   */
  limit: {
    type: Number,
    default: Infinity
  },

  /**
   * Function that processes the message shown when selected elements pass the defined limit.
   * @type {function(number): string}
   */
  limitText: {
    type: Function,
    default: function limitTextDefault(count) {
      // eslint-disable-line func-name-matching
      return `and ${count} more`;
    }
  },

  /**
   * Text displayed when loading options.
   */
  loadingText: {
    type: String,
    default: '加载中...'
  },

  /**
   * Used for dynamically loading options.
   * @type {function({action: string, callback: (function((Error|string)=): void), parentNode: node=, instanceId}): void}
   */
  loadOptions: {
    type: Function
  },

  /**
   * Which node properties to filter on.
   */
  matchKeys: {
    type: Array,
    default: () => {
      ['label'];
    }
  },

  /**
   * Sets `maxHeight` style value of the menu.
   */
  maxHeight: {
    type: Number,
    default: 300
  },

  /**
   * Set `true` to allow selecting multiple options (a.k.a., multi-select mode).
   */
  // multiple: {
  //   type: Boolean,
  //   default: false
  // },

  /**
   * Generates a hidden <input /> tag with this field name for html forms.
   */
  name: {
    type: String
  },

  /**
   * Text displayed when a branch node has no children.
   */
  noChildrenText: {
    type: String,
    default: '无子节点.'
  },

  /**
   * Text displayed when there are no available options.
   */
  noOptionsText: {
    type: String,
    default: '无数据.'
  },

  /**
   * Text displayed when there are no matching search results.
   */
  noResultsText: {
    type: String,
    default: '无数据...'
  },

  /**
   * By default (`auto`), the menu will open below the control. If there is not
   * enough space, vue-treeselect will automatically flip the menu.
   * You can use one of other four options to force the menu to be always opened
   * to specified direction.
   * Acceptable values:
   *   - `"auto"`
   *   - `"below"`
   *   - `"bottom"`
   *   - `"above"`
   *   - `"top"`
   */
  openDirection: {
    type: String,
    default: 'auto',
    validator(value) {
      const acceptableValues = ['auto', 'top', 'bottom', 'above', 'below'];
      return acceptableValues.includes(value);
    }
  },

  /**
   * Whether to automatically open the menu when the control is clicked.
   */
  openOnClick: {
    type: Boolean,
    default: true
  },

  /**
   * Whether to automatically open the menu when the control is focused.
   */
  openOnFocus: {
    type: Boolean,
    default: false
  },

  /**
   * Array of available options.
   * @type {node[]}
   */
  // options: {
  //   type: Array
  // },

  /**
   * Field placeholder, displayed when there's no value.
   */
  placeholder: {
    type: String,
    default: '请选择'
  },

  /**
   * Applies HTML5 required attribute when needed.
   */
  required: {
    type: Boolean,
    default: false
  },

  /**
   * Text displayed asking user whether to retry loading children options.
   */
  retryText: {
    type: String,
    default: '重试?'
  },

  /**
   * Title for the retry button.
   */
  retryTitle: {
    type: String,
    default: '点击重试'
  },

  /**
   * Enable searching feature?
   */
  searchable: {
    type: Boolean,
    default: true
  },

  /**
   * Search in ancestor nodes too.
   */
  searchNested: {
    type: Boolean,
    default: false
  },

  /**
   * Text tip to prompt for async search.
   */
  searchPromptText: {
    type: String,
    default: '搜索...'
  },

  /**
   * Whether to show a children count next to the label of each branch node.
   */
  showCount: {
    type: Boolean,
    default: false
  },

  /**
   * Used in conjunction with `showCount` to specify which type of count number should be displayed.
   * Acceptable values:
   *   - "ALL_CHILDREN"
   *   - "ALL_DESCENDANTS"
   *   - "LEAF_CHILDREN"
   *   - "LEAF_DESCENDANTS"
   */
  showCountOf: {
    type: String,
    default: 'ALL_CHILDREN',
    validator(value) {
      const acceptableValues = [
        'ALL_CHILDREN',
        'ALL_DESCENDANTS',
        'LEAF_CHILDREN',
        'LEAF_DESCENDANTS'
      ];
      return acceptableValues.includes(value);
    }
  },

  /**
   * Whether to show children count when searching.
   * Fallbacks to the value of `showCount` when not specified.
   * @type {boolean}
   */
  showCountOnSearch: null,

  /**
   * In which order the selected options should be displayed in trigger & sorted in `value` array.
   * Used for multi-select mode only.
   * Acceptable values:
   *   - "ORDER_SELECTED"
   *   - "LEVEL"
   *   - "INDEX"
   */
  sortValueBy: {
    type: String,
    default: 'ORDER_SELECTED',
    validator(value) {
      const acceptableValues = ['ORDER_SELECTED', 'LEVEL', 'INDEX'];
      return acceptableValues.includes(value);
    }
  },

  /**
   * Tab index of the control.
   */
  tabIndex: {
    type: Number,
    default: 0
  },

  /**
   * The value of the control.
   * Should be `id` or `node` object for single-select mode, or an array of `id` or `node` object for multi-select mode.
   * Its format depends on the `valueFormat` prop.
   * For most cases, just use `v-model` instead.
   * @type {?Array}
   */
  value: null,

  /**
   * Which kind of nodes should be included in the `value` array in multi-select mode.
   * Acceptable values:
   *   - "ALL" - Any node that is checked will be included in the `value` array
   *   - "BRANCH_PRIORITY" (default) - If a branch node is checked, all its descendants will be excluded in the `value` array
   *   - "LEAF_PRIORITY" - If a branch node is checked, this node itself and its branch descendants will be excluded from the `value` array but its leaf descendants will be included
   *   - "ALL_WITH_INDETERMINATE" - Any node that is checked will be included in the `value` array, plus indeterminate nodes
   */
  valueConsistsOf: {
    type: String,
    default: 'BRANCH_PRIORITY',
    validator(value) {
      const acceptableValues = [
        'ALL',
        'BRANCH_PRIORITY',
        'LEAF_PRIORITY',
        'ALL_WITH_INDETERMINATE'
      ];
      return acceptableValues.includes(value);
    }
  },

  /**
   * Format of `value` prop.
   * Note that, when set to `"object"`, only `id` & `label` properties are required in each `node` object in `value` prop.
   * Acceptable values:
   *   - "id"
   *   - "object"
   */
  valueFormat: {
    type: String,
    default: 'id'
  },

  /**
   * z-index of the menu.
   */
  zIndex: {
    type: [Number, String],
    default: 99999
  }

};

