import request from '@/utils/request';

export function getUploadToken() {
  return request({
    url: '/web/qiniu/file/token',
    method: 'get'
  });
}

export function saveFileMeta(data) {
  return request({
    url: '/web/qiniu/file/meta',
    method: 'post',
    data
  });
}

