<template>
  <div :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <div class="sidebar-logo-container">
      <a href="#" class="sidebar-logo-link router-link-active">
      </a>

      <div class="sysname">{{ deptName }}</div>
      <br>
      <!-- <div class="sysname1">
        <div class="search-box">
          <input
            class="search-txt"
            v-model="searchName"
            @input="wd"
            @focus="wq"
            type="text"
            placeholder="输入以快速访问"
          />
          <div class="search-div">
            <i class="el-icon-search"></i>
          </div>
        </div>
        <transition name="el-fade-in-linear">
          <div v-show="show2" class="transition-box">
            <div class="loader" v-show="show">
              <div class="outer"></div>
              <div class="middle"></div>
              <div class="inner"></div>
            </div>
            <div v-if="noData">
              <h3>没有相关数据或名称不对</h3>
            </div>
            <span v-else v-for="(item, index) in routeArr" :key="index">
              <router-link class="button" @click="toPath" :to="item.path">{{
                item.name
              }}</router-link>
            </span>
          </div>
        </transition>
      </div> -->
    </div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        @select="handleSelect"
        :default-active="$route.path"
        :collapse="isCollapse"
        background-color="#324B84"
        text-color="#ffffff"
        :unique-opened="true"
        active-text-color="#03F5FF"
        :collapse-transition="true"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in vueRouter"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from './Logo';
import SidebarItem from './SidebarItem';
import variables from '@/styles/variables.scss';
import { getMenu } from '@/api/modules/user';
export default {
  data() {
    return {
      noData: false,
      ly: [],
      routeArr: [],
      routeArr2: [],
      searchName: '',
      isShow: false,
      deptImg: '',
      show: true,
      show2: false,
      listData: [],
      list: [
        { name: '技能添加' },
        { name: '装备添加' },
        { name: '装备添加' },
        { name: '装备添加' },
        { name: '装备添加' },
        { name: '装备添加' },
        { name: '装备添加' },
        { name: '装备添加' },
        { name: '装备添加' },
        { name: '装备添加' },
        { name: '789' },
        { name: '111' },
        { name: '222' },
        { name: '333' },
      ],
    };
  },
  components: { SidebarItem, Logo },
  watch: {
    $route(to, from) {
      this.show2 = false;
      this.show = false;
    },
  },
  computed: {
    ...mapGetters(['vueRouter', 'sidebar', 'deptName', 'deptIcon']),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // if set path, the sidebar will highlight the path you set
      console.log(route);
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
    deptIcon1() {
      return this.$store.getters.deptIcon || '';
    },
  },
  created() {
    // this.listData = this.routeArr;
  },
  mounted() {
    this.ly = this.vueRouter;
    console.log();
    this.deptImg = this.$store.getters.deptIcon;
    console.log('deptImg', this.deptImg);
  },
  methods: {
    handleSelect() {
      // 从菜单切换界面清掉列表分页
      localStorage.removeItem('pageIndex');
    },
    toPath() {
      this.show2 = false;
      this.show = false;
    },
    wq() {
      this.wd();
    },
    // 监听输入查询菜单
    wd(e) {
      console.log(e.target.value);
      if (e.target.value == '') {
        this.show2 = false;
        this.show = false;
      }
      this.show = true;
      this.show2 = true;
      // 获取路由菜单
      getMenu().then((res) => {
        this.ly = res.data[0].children;
        // 遍历最深层的子菜单
        let route = this.ly.map((item) => {
          return item.children;
        });
        // 再遍历最深层的,只要名称和路径并push到arr数组
        let arr = [];
        route.forEach((item) => {
          item.forEach((item2) => {
            let obj = {
              name: item2.name,
              path: item2.value,
            };
            arr.push(obj);
          });
        });
        this.routeArr2 = arr;
        // console.log(this.routeArr2,'这是嘛呀');
        this.show = false;
      });
      // 模糊查询
      let arr = [];
      for (let index = 0; index < this.routeArr2.length; index++) {
        // indexOf找到数组中指定的项
        if (this.routeArr2[index].name.indexOf(e.target.value) >= 0) {
          arr.push(this.routeArr2[index]); //返回满足条件的
        }
      }

      this.routeArr = arr;
      console.log(this.routeArr, '成了');
      if (e.target.value == '') {
        this.show2 = false;
        this.show = false;
      }
    },
  },
};
</script>

<style lang="scss"  scoped>
.sidebar-logo-container {
  position: relative;
  width: 100%;
  /* height: 50px; */
  line-height: 20px;
  background: #324b84;
  text-align: center;
  overflow: hidden;
}

.sidebar-logo-container .sidebar-logo-link {
  width: 100%;
  height: 80%;
}

.sidebar-logo-container .sidebar-logo-link .sidebar-logo {
  width: 80%;
  height: 80%;
  padding: 5;
  text-align: center;
  vertical-align: middle;
  margin-right: 12px;
}

.sidebar-container.has-logo .el-scrollbar {
  /* height: calc(100% - 50px); */
  background: rgba(244, 248, 252, 1);
}

.sysname {
  color: white;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 800;
  width: 100%;
  word-break: break-all;
  word-wrap: break-wor;
}
.sysname1 {
  color: white;
  font-size: 17px;
  overflow: hidden;
  margin-top: 120px;
  margin-bottom: -90px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 800;
  width: 100%;
  word-break: break-all;
  word-wrap: break-wor;
}
#app .sidebar-container {
  background: #324b84;
}
.search-box {
  position: absolute;
  top: 79%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  background: #2f3640;
  height: 30px;
  border-radius: 15px;
  padding: 10px;
  transition: 0.4s;
}
// .search-btn {
// 	// color: #e84118;
//   // position: absolute;
// 	// float: right !important;
//   background: white!important;
//   // top: -px;
//   top: -35px;
//   position: relative;
// 	border-radius: 50%;
//   opacity: 0;
// 	// background: #2f3640 ;
// 	display: flex ;
// 	justify-content: center !important;
// 	align-items: center !important;
// 	transition: 0.1s;
// 	text-decoration: none;
// }
.search-txt {
  // display: inline-block;
  float: left !important;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  overflow: hidden;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  line-height: 30px;
  width: 0 !important;
}
.search-box:hover .search-txt {
  width: 150px !important;
  padding: 0 6px !important;
  z-index: 9999;
}
.search-box:hover .search-btn {
  // position: absolute
  position: relative;
  opacity: 1;
  float: right !important;
  background: white !important;
  // top: 5px;
  top: -35px;
  // right: 0px;
  width: 40px !important;
  height: 40px !important;
  line-height: 30px !important;
}
.search-box:hover {
  width: 150px;
  .search-div {
    opacity: 0;
    display: none;
  }
}
.search-div {
  opacity: 1;
  transition: 0.9s;
  // float: right;
  // line-height: 30px;
}
.transition-box {
  border-radius: 10px;
  position: fixed;
  top: 93px;
  left: 14.5%;
  width: 200px;
  height: 200px;
  padding-top: 10px;
  // background-color:#324B84;
  backdrop-filter: blur(1.5px) !important;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  overflow: auto;
  // overflow: hidden;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.9);
  // align-items: center;
}
.loader {
  position: relative;
  left: -4px;
  top: 92px;
}

.outer,
.middle,
.inner {
  border: 3px solid transparent;
  border-top-color: #3cefff;
  border-right-color: #3cefff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.outer {
  width: 3.5em;
  height: 3.5em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  animation: spin 2s linear infinite;
}

.middle {
  width: 2.1em;
  height: 2.1em;
  margin-left: -1.05em;
  margin-top: -1.05em;
  animation: spin 1.75s linear reverse infinite;
}

.inner {
  width: 0.8em;
  height: 0.8em;
  margin-left: -0.4em;
  margin-top: -0.4em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.button {
  z-index: 1;
  position: relative;
  font-size: inherit;
  font-family: inherit;
  // color: black;
  color: #161616;
  // padding: 0.5em 1em;
  outline: none;
  border: none;
  // background-color: hsl(236, 32%, 26%);
  // backdrop-filter: blur(1px) !important;
  overflow: hidden;
  transition: color 0.4s ease-in-out;
}

.button::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #409eff;
  transform-origin: center;
  transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
  transition: transform 0.45s ease-in-out;
}

.button:hover {
  cursor: pointer;
  color: #161616;
}

.button:hover::before {
  transform: translate3d(-50%, -50%, 0) scale3d(15, 15, 15);
}
</style>
