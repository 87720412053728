<template>
  <div />
</template>
<script>
export default {
  name: 'TBaseControl',
  props: {
    value: {
      required: true
    }, // 接受外部v-model传入的值
    name: {
      type: String,
      default: 'TBaseControl'
    },
    controlStyle: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '-'
    },
    defaultValue: {
      type: String,
      default: ''
    },
    prefixIcon: {
      type: String,
      default: ''
    },
    suffixIcon: {
      type: String,
      default: ''
    },
    clearIcon: {
      type: String,
      default: 'el-icon-circle-close'
    },
    className: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      modelValue: ''
    }
  },
  watch: {
    modelValue(val, oldVal) {
      // console.log('-modelValue-', val, oldVal);
      if (val != oldVal) {
        this.$emit('input', this.modelValue);
      }
    },
    value(val, oldVal) {
      // console.log('-value-', val, oldVal);
      this.modelValue = val;
    },
    change() {
      // console.log('TControl-' + this.name, this.modelValue);
      this.$emit('change', this.name, this.modelValue);
    }
  }
}
</script>
<style scoped>
</style>
