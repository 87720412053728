var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("treeselect", {
    ref: _vm.name,
    attrs: {
      id: _vm.name,
      name: _vm.name,
      multiple: _vm.multiple,
      options: _vm.groupData,
      placeholder: _vm.phdrValue,
      clearable: _vm.clearable,
      disabled: _vm.disabled,
    },
    on: {
      open: _vm.open,
      close: _vm.close,
      input: _vm.input,
      select: _vm.select,
      deselect: _vm.deselect,
      clear: _vm.clear,
      "search-change": _vm.searchChange,
    },
    scopedSlots: _vm._u([
      {
        key: "option-label",
        fn: function ({ node }) {
          return _c(
            "p",
            {
              staticStyle: {
                overflow: "hidden",
                "white-space": "nowrap",
                "text-overflow": "ellipsis",
                width: "90%",
              },
              attrs: { title: node.label },
            },
            [[_vm._v(" " + _vm._s(node.label))]],
            2
          )
        },
      },
    ]),
    model: {
      value: _vm.modelValue,
      callback: function ($$v) {
        _vm.modelValue = $$v
      },
      expression: "modelValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }