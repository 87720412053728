import request from '@/utils/request';

//获取所有资源信息
export function getUnreadAlarmTasks() {
  return request({
    url: 'app/v1/police/getUnReadAlarmTasks',
    method: 'get'
  });
}
//获取所有资源信息
export function getUnReadPoliceTasks() {
  return request({
    url: 'app/v1/police/getUnReadPoliceTasks',
    method: 'get'
  });
}
export function getUnReadApprovers() {
  return request({
    url: 'app/v1/police/getUnReadApprovers',
    method: 'get'
  });
}
export function getUnReadPingServe() {
  return request({
    url: '/web/ping/getUnReadPingServe',
    method: 'POST'
  });
}
// 获取当前用户的资源信息
export function deleteUnReadApprovers(approveId) {
  return request({
    url: 'app/v1/police/deleteUnReadApprovers?approveId=' + approveId,
    method: 'get'
  });
}
// 已读数据
export function updateUnReadApprovers(approveId) {
  return request({
    url: 'app/v1/police/updateUnReadApprovers?approveId=' + approveId,
    method: 'get'
  });
}
// 已读数据
export function updateUnReadPolice(policeId) {
  return request({
    url: 'app/v1/police/updateUnReadPolice?policeId=' + policeId,
    method: 'get'
  });
}
// 获取当前用户的资源信息
export function deleteUnreadAlarmTask(taskId) {
  return request({
    url: 'app/v1/police/deleteUnReadAlarmTask?taskId=' + taskId,
    method: 'get'
  });
}
export function getPoliceCirculationDetailsWithId(id) {
  return request({
    url: '/app/v1/police/getPoliceCirculationDetailsWithId?id=' + id,
    method: 'get'
  });
}
// 已读数据
export function updateUnReadAlarmTask(taskId) {
  return request({
    url: 'app/v1/police/updateUnReadAlarmTask?taskId=' + taskId,
    method: 'get'
  });
}


export function batchDeleteUnReadAlarmTask(data) {
  return request({
    url: '/app/v1/police/batchDeleteUnReadAlarmTask',
    method: 'POST',
    data
  });
}
