// let allkey = []
const pageIndexKey = 'allPageIndex'
function setItem(key, pageIndex,isClear=false) {
    let allPageIndex = {}
    console.log(isClear,907098670896897678,'---------------------------------------')
    if (!isClear){
        let strAllPageIndex = localStorage.getItem('allPageIndex')
        allPageIndex = (strAllPageIndex && JSON.parse(strAllPageIndex)) || {}
        allPageIndex = {...allPageIndex,[key]:pageIndex}
    }
    localStorage.setItem(pageIndexKey, JSON.stringify(allPageIndex))
    // allkey.push(key)
}
function getItem(key) {
    console.log(JSON.parse(localStorage.getItem(pageIndexKey)),897969876978)
    return JSON.parse(localStorage.getItem(pageIndexKey))[key]
}
// function removeItem(key) {
//     localStorage.removeItem(key)
// }
// function clearAllItem() {
//     allkey.forEach(key => {
//         console.log(key)
//         removeItem(key)
//     })
// }

export {
    // allkey,
    setItem,
    getItem,
    // removeItem,
    // clearAllItem,
}