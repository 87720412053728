<template>
  <el-select
    :id="name"
    :ref="name"
    v-model="modelValue"
    :name="name"
    :size="size"
    :disabled="disabled"
    :placeholder="placeholder"
    :multiple="multiple"
    :clearable="clearable"
    :value-key="valueKey"
    :filterable="filterable"
    :class="className"
    @change="change"
    @clear="clear"
  >
    <el-option
      v-for="(option, optionIndex) in groupData"
      :key="optionIndex + '_local'"
      :value="typeof option === 'object' ? option[valueKey || 'value'] : option"
      :label="typeof option === 'object' ? option[labelKey || 'label'] : option"
    >
    <span style="float: left">{{ typeof option === 'object' ? option[labelKey || 'label'] : option }}</span>
    <!-- isPopover针对安全守护选择图标添加预览效果 -->
    <el-popover
      v-if="isPopover"
      title="图标预览效果"
      placement="right"
      width="200px"
      style="position: relative; padding: 0px !important;"
      trigger="hover"
    >
      <div>
        <img src="@/assets/安全守护.png" width="260px" alt />
        <img  :src="typeof option === 'object' ? option[idKey || 'id'] : option" width="80" class="img" />
      </div>
      <img
        slot="reference"
        style="float: right; width:23.5px;  margin-top:5px"
        :src="typeof option === 'object' ? option[idKey || 'id'] : option"
        alt
      />
    </el-popover>
    </el-option>

  </el-select>
</template>
<script>
import TBaseGroup from '@/components/trina/base//TBaseGroup';
export default {
  name: 'TSelect',
  extends: TBaseGroup,
  props: {
    name: {
      type: String,
      default: 'TSelect'
    },
    isPopover: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    autoSelected: {
      // 针对select有效
      type: Boolean,
      default: false
    },
    clearable: {
      // 针对select有效
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      svalue: ''
    };
  },
  mounted() {},
  methods: {
    setSelectValue() {
      if (this.defaultValue) {
        this.modelValue = this.defaultValue;
      }
    },
    change() {
      console.log('TSelect-', this.modelValue);
      let selectObj = {};
      selectObj = this.groupData.find(item => {
        return item.value === this.modelValue;
      });
      this.$emit('change', this.name, this.modelValue, selectObj);
    },
    clear() {
      this.modelValue = '';
      this.$emit('clear');
    }
  }
};
</script>

<style scoped>
.img {
  position: absolute;
  left: 0;
  top: 65px;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
