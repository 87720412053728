import { login, getMenu } from "@/api/modules/user";
import Router from 'vue-router'

const CODE_OK = "200";
const addRoutes = v => {
    let obj = {
        name: v.code,
        path: v.value,
        meta: {
            title: v.title ? v.title : v.name,
            icon: v.iconClass ? v.iconClass : ''
        },
        component: resolve => require(["@/layout"], resolve),
        redirect: (v.children && v.children.length > 0) ? ((v.children[0].children && v.children[0].children.length > 0) ? v.children[0].children[0].value : v.children[0].value) : v.value,
        hidden: (v.isHidden == 1) ? true : false,
        children: v.children ? childRoutes(v, v.children) : []
    };
    return obj;
};
const childRoutes = (prarent, child) => {
    let children = []
    if (!child) {
        children = [prarent] // 无子菜单遍历自身
    } else {
        children = child // 有子菜单
    }
    let arr = [];
    children.forEach(v => {
        arr.push({
            path: v.value,
            name: v.code,
            component: resolve => require([`@/views${v.value}`], resolve),
            component_name: `@/views${v.value}`,
            meta: {
                title: v.title ? v.title : v.name,
                icon: v.iconClass ? v.iconClass : ''
            },
            hidden: (v.isHidden == 1) ? true : false,
            children: v.children ? childRoutes(v, v.children) : []
        });
    });
    return arr;
};

const app = {
    state: {
        logo: "123",
        menuRouter: [],
        vueRouter: [],
        isCollapsed: false,
        isGetModules: false,
        userName: "",
        userAvatar: "",
        loggedIn: "",
        storeId: "",
        loadingText: ""
    },
    mutations: {
        SETMENU: (state, status) => {
            let arr = []
            if (status.length <= 0) {
                return
            }
            status.forEach(v => {
                arr.push(addRoutes(v));
            });
            state.vueRouter = arr;
            console.log(state.vueRouter)
        },
        SET_ISGETMODULES: (state, isGetModules) => {
            state.isGetModules = isGetModules;
        },
    },
    actions: {
        async SetRouter({ commit, getters }, router) {
            let res = await getMenu();
            if (res.code == CODE_OK) {
                await commit("SETMENU", res.data[0].children);
                await commit("SET_ISGETMODULES", true); //此状态说明已设置好了路由
            } else if (res.code != CODE_OK) {
                console.log("设置路由状态码不为0");
                // localStorage.clear();
                Object.keys(localStorage).forEach(v => {
                    if (v != "language") {
                        localStorage.removeItem(v);
                    }
                });
                location.href = "/";
            }
            return res;
        },
        LogOut() {
            // localStorage.clear();
            Object.keys(localStorage).forEach(v => {
                if (v != "language") {
                    localStorage.removeItem(v);
                }
            });
            location.href = "/";
        }
    }
};

export default app;
