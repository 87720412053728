
<template>
  <div>
  
    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      @close="handleClose"
    >
    <div class="button444" @click="updatapwd">
      <img src="../../assets/button@2x.png" alt="">
    </div>
    </el-dialog>
  </div>
</template>

<script>
    import MyForm from '@/components/trina/form/myform/MyForm';


    export default {
   
        components: {
            MyForm
        },
        props: {
            deliverId: {
                type: Number
            },
            visible: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
              
            };
        },
        methods: {
            handleClose() {
            
               
            },
            updatapwd(){
              this.$emit('open')
              console.log('修改密码');
            }
            // 按钮处理
          
        }
    };
</script>

<style scoped lang="scss">

/deep/ .el-dialog{
  background: rgba(0, 0, 0, 0.5);
  background-image: url(../../assets/4@2x.png);
  opacity: 0.9;
  width: 805px;
  height: 507px;
}
.button444{
    position: relative;
    top: 380px;
    left: 330px;
}

</style>