var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: _vm.name,
      class: _vm.className,
      attrs: {
        id: _vm.name,
        name: _vm.name,
        size: _vm.size,
        disabled: _vm.disabled,
        placeholder: _vm.placeholder,
        multiple: _vm.multiple,
        clearable: _vm.clearable,
        "value-key": _vm.valueKey,
        filterable: _vm.filterable,
      },
      on: { change: _vm.change, clear: _vm.clear },
      model: {
        value: _vm.modelValue,
        callback: function ($$v) {
          _vm.modelValue = $$v
        },
        expression: "modelValue",
      },
    },
    _vm._l(_vm.groupData, function (option, optionIndex) {
      return _c(
        "el-option",
        {
          key: optionIndex + "_local",
          attrs: {
            value:
              typeof option === "object"
                ? option[_vm.valueKey || "value"]
                : option,
            label:
              typeof option === "object"
                ? option[_vm.labelKey || "label"]
                : option,
          },
        },
        [
          _c("span", { staticStyle: { float: "left" } }, [
            _vm._v(
              _vm._s(
                typeof option === "object"
                  ? option[_vm.labelKey || "label"]
                  : option
              )
            ),
          ]),
          _vm.isPopover
            ? _c(
                "el-popover",
                {
                  staticStyle: {
                    position: "relative",
                    padding: "0px !important",
                  },
                  attrs: {
                    title: "图标预览效果",
                    placement: "right",
                    width: "200px",
                    trigger: "hover",
                  },
                },
                [
                  _c("div", [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/安全守护.png"),
                        width: "260px",
                        alt: "",
                      },
                    }),
                    _c("img", {
                      staticClass: "img",
                      attrs: {
                        src:
                          typeof option === "object"
                            ? option[_vm.idKey || "id"]
                            : option,
                        width: "80",
                      },
                    }),
                  ]),
                  _c("img", {
                    staticStyle: {
                      float: "right",
                      width: "23.5px",
                      "margin-top": "5px",
                    },
                    attrs: {
                      slot: "reference",
                      src:
                        typeof option === "object"
                          ? option[_vm.idKey || "id"]
                          : option,
                      alt: "",
                    },
                    slot: "reference",
                  }),
                ]
              )
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }