<template>
  <div
    id="item2"
    class="div"
    style="padding: 1.33rem"
    @click="currentIndex = -1"
  >
    <div v-for="(item, index) in items" :key="item.id">
      <div class="item77" :style="{ animationDelay: `${(index / 3) * 0.1}s` }">
        <span class="name" style="font-weight: bold">{{
          item.systemName
        }}</span>
        <span v-if="item.alias" class="name1" style="font-weight: bold">{{
          `【${item.alias}】`
        }}</span>
        <div
          style="
            flex-grow: 1;
            border-left: 1px solid #00000066;
            padding-left: 15px;
          "
          v-if="currentIndex === item.id"
        >
          <input
            class="input-box"
            type="text"
            maxlength="10"
            v-model="alias"
            placeholder="请输入菜单别名，按下Enter键确认"
            @keyup.enter="addName()"
            @click.stop="handleInputClick(item, index)"
            @blur="currentIndex = -1"
          />
        </div>
        <i v-else class="el-icon-edit" @click.stop="showInput(item, index)"></i>
        <i
          v-if="item.children.length !== 0 && Nested !== item.id"
          style="float: right; margin-right: 10px; cursor: pointer"
          class="el-icon-arrow-right"
          @click="showNested(item)"
        ></i>
        <i
          v-if="item.children.length !== 0 && Nested == item.id"
          style="float: right; margin-right: 10px; cursor: pointer"
          class="el-icon-arrow-down"
          @click="showNested(item)"
        ></i>
      </div>
      <div v-if="item.children && Nested == item.id">
        <NestedList
          @click="currentIndex = -1"
          :items="item.children"
        ></NestedList>
      </div>
    </div>
  </div>
</template>
  
  <script>
import NestedList from './NestedList';
import Sortable from 'sortablejs';
export default {
  components: { NestedList },
  name: 'NestedList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      const container = document.getElementById('item2');
      console.log(container, '看看有啥');
      Sortable.create(container, {
        animation: 200,
        fallbackOnBody: true,
        ghostClass: 'blue-background-class',
        onEnd: this.handleSortEnd, // 拖拽结束方法
      });
    });
  },
  data() {
    return {
      currentIndex: -1,
      Nested: -1,
      alias: '',
      aliasIndex: null,
    };
  },
  methods: {
    showInput(item, index) {
      this.currentIndex = item.id;
    },
    handleInputClick(item, index) {
      console.log(item);
      this.aliasIndex = index;
    },
    showNested(val) {
      if (this.Nested !== val.id) {
        this.Nested = val.id;
      } else {
        this.Nested = -1;
      }
    },
    addName() {
      this.items[this.aliasIndex].alias = this.alias;
      this.alias = '';
      this.currentIndex = -1;
    },
    handleSortEnd(event) {
      const { newIndex, oldIndex } = event;
      console.log(event);
      // 更新items数组的顺序
      this.items.splice(newIndex, 0, this.items.splice(oldIndex, 1)[0]);
      console.log(this.items, '子');
      this.$emit('lookItems');
      // 将新的顺序发送给后端进行保存
    },
  },
};
</script>
<style lang="less" scoped>
.item77 {
  border: 1px solid #dcdcdc;
  font-size: 14px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  opacity: 0;
  animation: tag-fade-in 0.5s ease-out forwards;

  span {
    padding: 8px;
    display: inline-block;
  }
}
input {
  border: none;
  box-shadow: none;
  width: 100%;
  outline: none;
  height: 100%;
}
.el-icon-edit {
  font-size: 20px;
  flex: 1;
  cursor: pointer;
}
.div {
  // animation: tag-fade-in2 0.5s ease-out forwards;
}

@keyframes tag-fade-in {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.blue-background-class {
  background-color: #0052d9ff;
  color: white;
  border: none;
}

.name1 {
  animation: tag-fade-in2 0.5s ease-out forwards;
}

@keyframes tag-fade-in2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
  