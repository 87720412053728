<template>
    <div class="scrollText" ref="outer">
        <div class="st-inner" :class="{'st-scrolling': needToScroll}">
            <span class="st-section" ref="inner">{{text}}</span>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      needToScroll: false,
      text: ""
    };
  },
  mounted() {
    this.startCheck();
  },
  beforeDestroy() {
    this.stopCheck();
  },
  methods: {
    // 检查当前元素是否需要滚动
    check() {
      this.setText();
      this.$nextTick(() => {
        let flag = this.isOverflow();
        this.needToScroll = flag;
        if (flag) {
          // 如果需要滚动，根据内容长度动态调整动画时间
          this.adjustScrollSpeed();
        }
      });
    },
 
    // 判断子元素宽度是否大于父元素宽度，超出则需要滚动，否则不滚动
    isOverflow() {
      let outer = this.$refs.outer;
      let inner = this.$refs.inner;
      let outerWidth = this.getWidth(outer);
      let innerWidth = this.getWidth(inner);
      return innerWidth > outerWidth;
    },
 
    // 获取元素宽度
    getWidth(el) {
      let { width } = el.getBoundingClientRect();
      return width;
    },
 
    // 获取到父组件传过来的内容复传给this.text
    setText() {
      const extractTextFromVNodes = (vnodes, text = '') => {
        for (let vnode of vnodes) {
          if (vnode.text) {
            text += vnode.text;
          } else if (vnode.children) {
            text = extractTextFromVNodes(vnode.children, text);
          }
        }
        return text;
      };

      this.text = extractTextFromVNodes(this.$slots.default || []);

    },
 
    // 增加定时器，隔一秒check一次
    startCheck() {
      this._checkTimer = setInterval(this.check, 1500);
      this.check();
    },

    // 关闭定时器
    stopCheck() {
      clearInterval(this._checkTimer);
    },
    
    // 动态调整滚动速度
    adjustScrollSpeed() {
      let inner = this.$refs.inner;
      let outer = this.$refs.outer;
      let scrollWidth = inner.scrollWidth;
      let viewportWidth = outer.offsetWidth;
      let distanceToScroll = scrollWidth + viewportWidth; // 总滚动距离是内部宽度加上视口宽度
      let speed = 10; // 可以根据需要调整基础速度，数值越大滚动越慢
      let duration = (distanceToScroll / speed).toFixed(2); // 动态计算持续时间

      // 应用动态计算的动画持续时间
      let innerElement = this.$refs.inner.parentElement;
      innerElement.style.animationDuration = `${duration}s`;
    },
  }
};
</script>
<style lang="scss" scoped>
.scrollText {
  overflow: hidden;
  white-space: nowrap;
}
.st-inner {
  display: inline-block;
}
.st-scrolling .st-section {
  padding: 0 5px;
}
 
// 向左匀速滚动动画
.st-scrolling {
  animation-name: scroll;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
 
@keyframes scroll {
  0% {
    transform: translate3d(0%, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
</style>
 