import Vue from 'vue';

Vue.directive('dialogDrag', {
  bind(el) {
    el.style.cursor = 'move';

    let startX, startY, initialLeft, initialTop;

    el.onmousedown = (e) => {
      startX = e.clientX;
      startY = e.clientY;
      const rect = el.getBoundingClientRect();
      initialLeft = rect.left;
      initialTop = rect.top;

      document.onmousemove = (event) => {
        const dx = event.clientX - startX;
        const dy = event.clientY - startY;

        const newLeft = initialLeft + dx;
        const newTop = initialTop + dy;

        el.style.left = newLeft + 'px';
        el.style.top = newTop + 'px';
      };

      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
  },
});
