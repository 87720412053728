<template>
  <div :class="{ homeNavbar: !isShowHomeStyle }" class="navbar">
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <div class="transmit">

        <!-- <ThemePicker></ThemePicker> -->

        <van-icon
          class="search"
          v-if="!isTransmit"
          @click="transmit"
          name="search"
          :color="isColor"
          size="27"
        />
        <el-select
          ref="search"
          class="searchSelect"
          v-else
          v-model="value"
          filterable
          remote
          reserve-keyword
          @change="change"
          @blur="change"
          placeholder="输入以快速访问"
          :remote-method="remoteMethod"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div style="margin-right: 20px; margin-top: 4px">
        <img
          v-if="!isShowHomeStyle"
          style="cursor: pointer"
          width="32"
          :src="sort"
          @click="MenuSorting"
          alt=""
        />
        <img
          v-else
          style="cursor: pointer"
          width="32"
          :src="sort2"
          @click="MenuSorting"
          alt=""
        />
      </div>

      <div style="margin-right: 1.33rem">
        <el-button
          v-if="monitorShow"
          type="primary"
          @click="toOverview"
          icon="el-icon-monitor"
        ></el-button>
        <!-- <i  v-if="dashboardId == 1" @click="$router.push('/overviewPro')"  style="font-size: 24px;margin-right: 1.33rem;cursor: pointer;"   :class="{'el-icon-monitor':true, homeRightMenuItem: isShowHomeStyle }" ></i> -->
      </div>
      <div>
        <el-button
          type="primary"
          class="el-icon-bell"
          @click="$router.push('/msgSend')"
        >
          <el-badge class="item" v-if="msgNum !== 0" :value="msgNum" :max="99">
          </el-badge>
        </el-button>
      </div>

      <el-popover placement="bottom" trigger="hover">
        <div>
          <div class="weixin">
            <img title="手机扫码联系客服" :src="weixin" alt="" />
            微信扫描二维码
            <br />
            或
          </div>
          <br />
          <br />
          <div class="wechat">
            <!-- <a href="https://work.weixin.qq.com/kfid/kfc99af74a45c522599">联系客服 </a> -->
            <el-button @click="wechat" type="success">
              <van-icon name="wechat" />
              联系客服
            </el-button>
          </div>
        </div>
        <el-button
          slot="reference"
          style="margin-left: 20px"
          type="primary"
          icon="el-icon-service"
        ></el-button>
      </el-popover>

      <template v-if="device !== 'mobile'">
        <!-- <search id="header-search" class="right-menu-item" /> -->

        <!-- <error-log class="errLog-container right-menu-item hover-effect" /> -->

        <screenfull
          id="screenfull"
          class="right-menu-item hover-effect"
          :class="{ homeRightMenuItem: isShowHomeStyle }"
        />

        <!-- <el-tooltip :content="$t('navbar.size')" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip>-->

        <!-- <lang-select class="right-menu-item hover-effect" /> -->
      </template>

      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
        :class="{ homeRightMenuItem: isShowHomeStyle }"
      >
        <div class="avatar-wrapper">
          <!-- <img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar">
          -->
          <div>
            <span>{{ name }} <i class="el-icon-caret-bottom" /></span>
          </div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- <router-link to="/profile/index">
            <el-dropdown-item>
              {{ $t('navbar.profile') }}
            </el-dropdown-item>
          </router-link>-->

          <el-dropdown-item>
            <span style="display: block" @click="openUserInfo">个人信息</span>
          </el-dropdown-item>
          <el-dropdown-item>
            <span style="display: block" @click="changePwd">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided>
            <span style="display: block" @click="logout">
              {{ $t('navbar.logOut') }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dialog
        title="详情"
        :visible.sync="dialogVisible"
        width="50%"
        :modal-append-to-body="true"
      >
        <ReportTaskDetail
          :dialogVisible="dialogVisible"
          :formData="rowDataPro"
          :detailId="detailIdPro"
          class="detail"
        />
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <el-dialog
        title="申请注册信息"
        :visible.sync="dialogVisible2"
        width="50%"
      >
        <el-form ref="form2" :model="form2" label-width="110px">
          <el-form-item label="注册辖区：">
            <div class="content">{{ form2.deptName }}</div>
          </el-form-item>
          <el-form-item label="注册人：">
            <div class="content">{{ form2.username }}</div>
          </el-form-item>
          <el-form-item label="手机号：">
            <div class="content">{{ form2.phone }}</div>
          </el-form-item>
          <el-form-item label="性别：">
            <el-tag>{{ form2.gender }}</el-tag>
          </el-form-item>
          <el-form-item label="用户身份：">
            <el-tag>{{ form2.userGrade }}</el-tag>
          </el-form-item>
          <el-form-item label="装备：">
            <el-tag
              v-for="(item, index) in form2.equipmentLabelName"
              :key="index"
              :disable-transitions="false"
              >{{ item }}
            </el-tag>
          </el-form-item>
          <el-form-item label="技能：">
            <el-tag
              v-for="(item, index) in form2.skillLabelName"
              :key="index"
              :disable-transitions="false"
              >{{ item }}
            </el-tag>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="taskDetailDia('Register')"
            >查看更多</el-button
          >
        </span>
      </el-dialog>

      <el-dialog
        title="详情"
        :visible.sync="dialogVisible4"
        width="50%"
        :modal-append-to-body="true"
        :close-on-click-modal="false"
      >
        <PublishTaskDetail
          :dialogVisible4="dialogVisible4"
          :formData="rowData"
          :detailId="detailId"
          class="detail"
        />
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible4 = false">关 闭</el-button>
        </span>
      </el-dialog>

      <el-dialog
        :visible.sync="isShow"
        width="40%"
        :style="{ 'text-align': 'center' }"
      >
        <h3 slot="title" style="font-weight: bold; font-size: 20px">
          {{ form3.title }}
        </h3>
        <div class="content1">
          <pre class="showText" style="white-space: pre-wrap">{{
            form3.content
          }}</pre>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click.stop="isDialogVisible"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        :visible.sync="notifyDialog"
        :title="this.titleTrue"
        append-to-body
        width="800px"
      >
        <div style="width: 100%">
          <span>{{ this.notifyDesc || '[语音信息]' }}</span>
        </div>
        <div style="width: 100%; text-align: center; margin-top: 20px">
          <el-button type="primary" @click="taskDetailDia('ReportTaskTable')"
            >查看详情
          </el-button>
        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="notifyDialog2"
        title="收到二维码注册信息"
        append-to-body
        width="800px"
      >
        <div style="width: 100%">
          <span>{{
            '申请人：' + this.username + ' 手机号：' + this.phone
          }}</span>
        </div>
        <div style="width: 100%; text-align: center; margin-top: 20px">
          <el-button type="primary" @click="taskDetailDia('Register')"
            >查看详情
          </el-button>
        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="notifyDialog4"
        :title="this.titleAll"
        append-to-body
        width="800px"
      >
        <div style="width: 100%">
          <span>{{ '任务内容:【' + (this.descAll || '[语音信息]') + '】' }}</span>
        </div>
        <div style="width: 100%; text-align: center; margin-top: 20px">
          <el-button type="primary" @click="taskDetailDia('TaskTable')"
            >查看详情
          </el-button>
        </div>
      </el-dialog>
      <el-dialog
        :visible.sync="notifyDialog5"
        :title="this.titleAll"
        append-to-body
        width="800px"
      >
        <div style="width: 100%">
          <span>{{ '纠纷内容:【' +(this.descAll || '[语音信息]')  + '】' }}</span>
        </div>
        <div style="width: 100%; text-align: center; margin-top: 20px">
          <el-button type="primary" @click="taskDetailDia('DisputeMsgTable')"
          >查看详情
          </el-button>
        </div>
      </el-dialog>

      <el-dialog
        :visible.sync="MenuSortingVisible"
        title="菜单排序 ( 上下拖动调整排序 )"
        append-to-body
        width="500px"
      >
        <MenuSorting ref="MenuSorting" v-if="MenuSortingVisible"></MenuSorting>
        <span slot="footer" class="dialog-footer">
          <el-button @click="MenuSortingVisible = false">关 闭</el-button>
          <el-button
            @click="restore"
            plain
            style="
              color: rgb(0, 0, 0);
              background-color: rgba(220, 220, 220, 1);
            "
            >恢复默认排序</el-button
          >
          <el-button type="primary" @click="updateData()">保存</el-button>
        </span>
      </el-dialog>

      <user-info ref="userInfo" :visible.sync="dialogShow.userInfoShow" />
      <pwd @openpwd="openpwd" :visible.sync="dialogShow.pwdShow" />
      <hint @open="open" :visible.sync="dialogShow.hintShow"></hint>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import MenuSorting from './MenuSorting';

import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import Screenfull from '@/components/Screenfull';
import {
  deleteUnreadAlarmTask,
  deleteUnReadApprovers,
  getPoliceCirculationDetailsWithId,
  updateUnReadAlarmTask,
  updateUnReadApprovers,
  updateUnReadPolice,
} from '@/api/modules/taskAlarm';
import { getMenu } from '@/api/modules/user';
import UserInfo from './UserInfo';
import Pwd from './Pwd';
import Hint from './Hint';
import ThemePicker from './ThemePicker';

import {
  getApproversDetail,
  getReadText,
} from '@/api/modules/policeCirculation';
import TButton from '@/components/trina/base/TButton';
import PublishTaskDetail from '@/views/policeCirculation/PublishTaskDetail';
import ReportTaskDetail from '@/views/policeCirculation/ReportTaskDetail';
import {
  alarmMsgCount,
  getUnReadPingCount,
  pliceMsgCount,
  populationMsgCount,
  registerMsgCount,
} from '@/api/modules/system';
import { getInfoById } from '@/api/modules/dept';
import { getDashBoardByDeptId } from '@/api/modules/dashboardList';

export default {
  components: {
    TButton,
    Breadcrumb,
    Hamburger,
    Screenfull,
    ThemePicker,
    PublishTaskDetail,
    ReportTaskDetail: ReportTaskDetail,
    UserInfo,
    Pwd,
    Hint,
    MenuSorting,
  },
  data() {
    return {
      monitorShow: false,
      dashboardId: 0,
      dashboard: {},
      MenuSortingVisible: false,
      sort: require('@/assets/icons/svg/MenuSorting.svg'),
      sort2: require('@/assets/菜单排序.png'),
      time: null,
      list: [],
      options: [],
      isTransmit: false,
      isColor: this.isShowHomeStyle ? '#fff' : '',
      value: '',
      restaurants: [],
      weixin: require('@/assets/qrcode.png'),
      isShow: false,
      activeName: 'first',
      form: {},
      form2: {},
      form4: {},
      detailId: 0,
      detailIdPro: '',
      rowData: {},
      rowDataPro: {},
      form3: {},
      notifyId: '',
      withId: '',
      notifyDesc: '',
      titleTrue: '',
      username: '',
      phone: '',
      userApproveId: '',
      policeId: '',
      titleAll: '',
      descAll: '',
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      notifyDialog: false,
      notifyDialog2: false,
      notifyDialog4: false,
      notifyDialog5: false,
      visible: false,
      msgNum: '',
      msgNum2: '',
      gridData: [],
      policeData: [],
      approverData: [],
      pingData: [],
      dialogShow: {
        userInfoShow: false,
        pwdShow: false,
        hintShow: false,
        oldPwd: '',
        verifyPwd: '',
        newPwd: '',
      },
    };
  },
  beforeDestroy() {},

  mounted() {
    this.$bus.$on('renew', () => {
      this.renew();
    });
    setTimeout(() => {
      this.getAlarmTasks();
    }, 1000);
    this.init();
    this.init2();
    this.init5();
    this.init4();
    this.$bus.on('allCount', (val) => {
      this.msgNum = val;
    });
    getInfoById(sessionStorage.getItem('deptId')).then((res) => {
      this.dashboardId = res.data.dashboardId;
    });

    function flattenMenu(menu, parentLabel = '') {
      let arr = [];
      menu.forEach((item) => {
        switch (item.id) {
          case '3736626485363712':
            sessionStorage.setItem('sszh', item.name);
            break;
          case '2105':
            sessionStorage.setItem('ssjk', item.name);
            break;
          case '20':
            sessionStorage.setItem('znxg', item.name);
            break;
          case '37366264853637':
            sessionStorage.setItem('sszh1', item.name);
            break;
        }
        let label = parentLabel ? `${parentLabel} > ${item.name}` : item.name;
        if (item.children && item.children.length > 0) {
          arr = arr.concat(flattenMenu(item.children, label));
        } else {
          let obj = {
            label: label,
            value: item.value,
          };
          arr.push(obj);
        }
      });

      return arr;
    }

    getMenu().then((res) => {
      let ly = res.data[0].children;
      let arr = flattenMenu(ly);
      this.list = arr;
      //遍历list，从里面找到id等于10 的对象
      let ggg = this.list.filter((item) => {
        return item.value == 10;
      });
      //
      console.log(ggg, '这是嘛呀666');
      let flag = res.data[0].children.find((item) => item.name == '总览');
      if (flag) {
        this.monitorShow = true;
        getDashBoardByDeptId(sessionStorage.getItem('deptId')).then((res) => {
          this.dashboard = res.data;
        });
      } else {
        this.monitorShow = false;
      }
    });
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'name', 'device']),
    isShowHomeStyle() {
      return (
        this.$route.path == '/dashboard' ||
        this.$route.path == '/safety' ||
        this.$route.path == '/overview'
      );
    },
  },
  watch: {
    isShowHomeStyle(val) {
      this.isColor = val ? '#fff' : '';
    },
    isTransmit(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.search.focus();
        }, 200);
      }
    },
  },
  methods: {
    renew() {
      getInfoById(sessionStorage.getItem('deptId')).then((res) => {
        this.dashboardId = res.data.expandMap.dashboardId;
      });
    },
    toOverview() {
      if (this.dashboardId == 1) {
        this.$router.push({
          path: '/overviewPro',
        });
      } else {
        this.$router.push({
          path: '/dashboard/dashboardFullScrenn',
          query: {},
        });
      }
    },
    restore() {
      this.$confirm('确定要恢复系统默认的排序吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$refs.MenuSorting.restore();
          this.MenuSortingVisible = false;
        })
        .catch(() => {});
    },
    updateData() {
      this.$confirm('确定要保存吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$refs.MenuSorting.updateData();
          this.MenuSortingVisible = false;
        })
        .catch(() => {});
    },
    MenuSorting() {
      this.MenuSortingVisible = true;
    },
    change(item) {
      // 清除延时器
      this.value = '';
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        this.isTransmit = false;
      }, 500);
      localStorage.removeItem('pageIndex');
      this.$router.push(item);
    },
    remoteMethod(query) {
      if (query !== '') {
        this.options = this.list.filter((item) => {
          return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      } else {
        this.options = [];
      }
    },
    transmit() {
      this.isTransmit = true;
    },
    wechat() {
      window.location.href =
        'https://work.weixin.qq.com/kfid/kfc99af74a45c522599';
    },
    formatDate(row, column, cellValue, index) {
      const date = new Date(cellValue);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    isDialogVisible() {
      console.log('isDialogVisible');
      this.isShow = false;
      console.log(this.this.isShow);
    },
    replace(cal) {
      console.log(cal);
      let val = cal.replace(/\n/gm, '<br>');
      return val;
    },
    //小铃铛标签页
    handleClick(tab, event) {
      console.log(tab, event);
    },
    updatePWD() {
      store.dispatch('user/getUserInfo').then((res) => {
        console.log(
          res.expandMap.thisMonthWhetherChangePassword,
          '这是什么！！！！！！！！！'
        );
        if (res.expandMap.thisMonthWhetherChangePassword == 0) {
          this.dialogShow.hintShow = true;
        } else {
          this.dialogShow.hintShow = false;
        }
      });
    },
    init() {
      //要反映
      this.$bus.on('taskAlarmTrigger', (val) => {
        console.log('触发了弹框鸭', val);
        if (val.flag == true) {
          // if (this.msgNum == '') {
          //   this.msgNum = 0;
          // }
          // if (val.hasRead == 0) {
          //   this.msgNum += 1;
          // }
          // this.msgNum += 1;
          this.notifyId = val.id;
          this.notifyDesc = val.rowDataPro.msgContent;
          this.titleTrue = val.rowDataPro.msgTitle;
          this.notifyDialog = true;
          this.rowDataPro = val.rowDataPro;
          this.getAlarmTasks();
          setTimeout(() => {
            this.notifyDialog = false;
          }, 10000);
        } else {
          //this.msgNum > 0 并且未读val.hasRead==0
          // if (this.msgNum > 0 && val.hasRead == 0) {
          //   this.msgNum -= 1;
          // }
          // if (this.msgNum == 0) {
          //   this.msgNum = '';
          // }
        }
      });
    },
    init2() {
      this.$bus.on('userApproverTrigger', (val) => {
        console.log('userApproverTrigger', val);
        if (val.flag == true) {
          // if (this.msgNum == '') {
          //   this.msgNum = 0;
          // }
          // if (val.hasRead == 0) {
          //   this.msgNum += 1;
          // }
          this.userApproveId = val.id;
          this.username = val.userName;
          this.phone = val.mobile;
          this.notifyDialog2 = true;
          this.getAlarmTasks();
          setTimeout(() => {
            this.notifyDialog2 = false;
          }, 10000);
        } else {
          // if (this.msgNum > 0 && val.hasRead == 0) {
          //   this.msgNum -= 1;
          // }
          // if (this.msgNum == 0) {
          //   this.msgNum = '';
          // }
        }
      });
    },
    init4() {
      this.$bus.on('userPoliceTrigger', (val) => {
        if (val.flag == true) {
          // if (this.msgNum == '') {
          //   this.msgNum = 0;
          // }
          // if (val.hasRead == 0) {
          //   this.msgNum += 1;
          // }
          console.log(val, '任务消息');
          this.policeId = val.allData.id;
          this.rowData = val.allData;
          this.titleAll = val.allData.msgTitle;
          this.descAll = val.allData.msgContent;
          this.notifyDialog4 = true;
          this.getAlarmTasks();
          setTimeout(() => {
            this.notifyDialog4 = false;
          }, 10000);
        } else {
          // if (this.msgNum > 0 && val.hasRead == 0) {
          //   this.msgNum -= 1;
          // }
          // if (this.msgNum == 0) {
          //   this.msgNum = '';
          // }
        }
      });
    },init5() {
      //纠纷消息通知弹窗
      this.$bus.on('messageDispute', (val) => {
        if (val.flag == true) {
          console.log(val, '纠纷消息');
          this.policeId = val.allData.id;
          this.rowData = val.allData;
          this.titleAll = val.allData.msgTitle;
          this.descAll = val.allData.msgContent;
          this.notifyDialog5 = true;
          // this.getAlarmTasks();
          setTimeout(() => {
            this.notifyDialog5 = false;
          }, 10000);
        }
      });
    },

    taskDetailDia(tableName) {
      this.dialogVisible2 = false;
      this.notifyDialog = false;
      this.notifyDialog2 = false;
      this.notifyDialog4 = false;
      this.notifyDialog5 = false;
      this.dialogVisible2 = false;
      this.isShow = false;
      this.notifyDialog = false;
      this.notifyDialog2 = false;
      this.notifyDialog4 = false;
      setTimeout(() => {
        //如果当前路由不是msgSend 则跳转
        if (this.$route.path != '/msgSend') {
          this.$router.push({
            path: '/msgSend',
            query: { tableName },
          });
        }else {
          //反之呢 传tableName给当前页面
          this.$bus.emit('msgSend', { tableName });
        }
      }, 200);
    },
    taskDetailDia2() {
      this.notifyDialog2 = false;
      this.taskDetail2({ id: this.userApproveId, hasRead: 0 });
    },
    taskDetailDia4() {
      this.notifyDialog4 = false;
      this.taskDetail4({ id: this.policeId, hasRead: 0 });
    },

    taskDetail(row) {
      this.geitWithId(row);

      // getPropertyCirculationDetail(row.id).then(resData => {
      //   this.form = resData.data;
      //   console.log(resData.data, 'resData.data');
      //   if (this.form.status) {
      //     if (this.form.status == 1) {
      //       this.form.statusName = '待反馈';
      //     } else if (this.form.status == 2) {
      //       this.form.statusName = '已完成';
      //     } else if (this.form.status == 3) {
      //       this.form.statusName = '反馈中';
      //     } else if (this.form.status == 12) {
      //       this.form.statusName = '已驳回';
      //     } else if (this.form.status == 10) {
      //       this.form.statusName = '已移交';
      //     } else if (this.form.status == 14) {
      //       this.form.statusName = '已撤销';
      //     }
      //   }
      // });
    },
    taskDetail2(row) {
      this.dialogVisible2 = true;
      console.log(row.id, 'row.id');
      console.log('aaaaaaaaaaaaa', row.hasRead);
      //不需要删除数据
      // return;
      //如果当前登录的是总辖区的账号就删除数据，其他的辖区就修改已读的状态
      let deptId = this.$store.getters.deptId;
      if (deptId == 1) {
        deleteUnReadApprovers(row.id).then((res) => {
          if (res.data == true) {
            this.$bus.emit('userApproverTrigger', {
              flag: false,
              hasRead: row.hasRead,
            });
          }
        });
      } else {
        updateUnReadApprovers(row.id).then((res) => {
          if (res.code == 200) {
            this.$bus.emit('userApproverTrigger', {
              flag: false,
              hasRead: row.hasRead,
            });
          }
        });
      }
      getApproversDetail(row.id).then((resData) => {
        console.log(resData, 'resData.data');
        this.form2 = resData.data;
        // console.log(this.form2,"aaaaaaaaaaaaaaaaaaaaaa");
      });
    },
    taskDetail4(row) {
      this.dialogVisible4 = true;
      console.log(row.id, 'row.id');
      console.log('aaaaaaaaaaaaa', row.hasRead);
      this.detailId = row.id;
      this.rowData = row;
      this.status = row.status;
      console.log('进来了');
      // return;
      //修改为已读状态
      updateUnReadPolice(row.id).then((res) => {
        if (res.code == 200) {
          this.$bus.emit('userPoliceTrigger', {
            flag: false,
            hasRead: row.hasRead,
          });
        }
      });
      // getUnReadPoliceTasks(row.id).then(resData => {
      //   console.log(resData, 'resData.data');
      //   this.form4 = resData.data;
      // });
    },
    taskDetail3(row) {
      this.form3 = row;
      console.log(this.form3, 'aaaaaaaaaaaaaaaaaaaaaa');
      //该状态不删除数据 readText 0未读 1已读
      getReadText(row.id).then((resData) => {
        console.log(resData, 'resData.data');
        this.isShow = true;
      });
    },
    async geitWithId(row) {
      const [detailsRes, taskRes] = await Promise.all([
        getPoliceCirculationDetailsWithId(row.id),
        this.isTotalJurisdictionAccount(this.$store.getters.deptId)
          ? deleteUnreadAlarmTask(row.id)
          : updateUnReadAlarmTask(row.id),
      ]);

      this.rowDataPro = detailsRes.data;
      console.log();
      this.detailIdPro = detailsRes.data.withoutRepetitionId;

      if (taskRes.data === true || taskRes.code === 200) {
        this.$bus.emit('taskAlarmTrigger', {
          flag: false,
          hasRead: row.hasRead,
        });
      }

      this.dialogVisible = true;
    },

    isTotalJurisdictionAccount(deptId) {
      return deptId === 1
        ? Promise.resolve(true)
        : Promise.reject(new Error('Not total jurisdiction account'));
    },

    // 打开个人信息设置界面
    openUserInfo() {
      this.dialogShow.userInfoShow = true;
    },
    // 修改密码
    changePwd() {
      this.dialogShow.pwdShow = true;
    },

    open() {
      this.dialogShow.pwdShow = true;
      this.dialogShow.hintShow = false;
    },
    openpwd() {
      this.dialogShow.hintShow = true;
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      this.$store.dispatch('user/logout');
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
      this.$store.dispatch('tagsView/delAllViews');
      console.log('触发退出登录事件');
      this.$bus.emit('loginOutEvent');
      sessionStorage.removeItem('token');
    },

    // getAlarmTasks(){
    //   // 获取三个维度的数据并相加未读数量
    //   Promise.all([
    //     getUnreadAlarmTasks(),
    //     getUnReadApprovers(),
    //     getUnReadPoliceTasks(),
    //     getUnReadPingServe()
    //   ]).then(([alarmTasksResponse, approversResponse,getUnReadPoliceTasks, pingServeResponse]) => {
    //     this.gridData = alarmTasksResponse.data;
    //     this.approverData = approversResponse.data;
    //     this.policeData = getUnReadPoliceTasks.data;
    //     this.pingData = pingServeResponse.data;

    //     this.msgNum =
    //       this.gridData.filter(item => item.hasRead === 0).length +
    //       this.approverData.filter(item => item.hasRead === 0).length +
    //       this.policeData.filter(item => item.hasRead === 0).length +
    //       this.pingData.filter(item => item.readText === 0).length;
    //   }).catch(error => {
    //     console.error(error);
    //   });
    // },
    getAlarmTasks() {
      this.msgNum = 0;
      let uid = this.$store.getters.uid;
      Promise.all([
        pliceMsgCount(uid),
        populationMsgCount(uid),
        alarmMsgCount(uid),
        registerMsgCount(this.$store.getters.deptId),
        getUnReadPingCount(),
      ]).then(
        ([TaskRes, ReportTaskRes, alarmRes, registerRes, ReadPingRes]) => {
          this.msgNum =
            TaskRes.data +
            ReportTaskRes.data +
            alarmRes.data +
            registerRes.data +
            ReadPingRes.data;
        }
      );
    },
    goPath(path) {
      this.visible = false;
      this.$router.push({
        path: '/' + path + '',
      });
    },
    //计时器
    getTask: function (e) {
      this.taskType = e;
    },
  },
  created() {
    this.updatePWD();
    this.isColor = this.isShowHomeStyle ? '#fff' : '';
  },
};
</script>

<style lang="scss" scoped>
.tishi {
  background: pink;
  width: 100px;
  height: 100px;
}

.homeNavbar {
  background: #fff;
}

.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    display: flex;
    align-items: center;

    justify-content: space-between;
    height: 100%;
    // line-height: 50px;
    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: flex;
      align-items: center;
      padding: 0 8px;
      // height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      div {
        display: flex;
        align-items: center;
        flex: 1;

        span {
          display: block;
          white-space: nowrap;
        }
      }

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .homeRightMenuItem {
      color: #fff;
    }

    .avatar-container {
      margin-right: 30px;
      // margin-left: 180px;
      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        div {
          display: flex;

          span {
            font-size: 1.1rem;
            display: inline-block;
          }
        }

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          // position: absolute;
          // right: -20px;
          // top: 20px;
          font-size: 12px;
        }
      }
    }
  }
}

.showText {
  white-space: pre-wrap;
  text-align: left;
  font-family: Microsoft Yahei;
  font-size: 18px;
}

.content1 {
  width: 100%;
  height: 30rem;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.weixin {
  text-align: center;
  width: 10rem;
  height: 10rem;

  img {
    width: 100%;
  }
}

.wechat {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.transmit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.33rem;

  .el-icon-search {
    font-size: 30px;
  }

  .button {
  }

  .search {
  }

  .searchSelect {
    width: 250px;
  }
}

.el-icon-bell {
  position: relative;

  .item {
    position: absolute;
    right: -18px;
    top: 0;
  }
}

/deep/ .customClass {
  width: 600px;
  height: 800px;
}

.videogroup {
  position: relative;
  flex: 1;
  margin-left: 1.33rem;
  // background-color: #dcdcdcff;
  // box-shadow: 2px 1px 5px #c9c5c5;
  border: 1px solid #dcdcdcff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player {
  position: relative;
}

//呼叫中
.Calling {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: tag-fade-in 0.8s ease-out forwards;

  div {
    margin-bottom: 50px;
  }
}

.time {
  color: #fff;
  z-index: 10000;
  position: absolute;
  bottom: 100px;
}

.stopButton {
  position: absolute;
  bottom: 40px;
}

.callButton {
  position: absolute;
  left: 30px;
  background-color: #ffffff;
  backdrop-filter: blur(15px); /* 调整模糊程度，以适应您的需求 */
  .Button {
    cursor: pointer;
    display: flex;

    div {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 14px;
        margin-left: 10px;
        color: #0052d9ff;
      }
    }
  }

  .Button2 {
    display: flex;
    cursor: not-allowed;

    div {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 14px;
        margin-left: 10px;
        color: #00000066;
      }
    }
  }

  ::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5)
    );
    filter: blur(15px);
    z-index: -1;
  }
}

.videoSuccess {
  width: 100%;
  height: 100%;
  background: #000000;
  position: relative;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
}

.video {
  display: flex;
  background: black;
  position: relative;
  // border: 1px solid lightgrey;
  width: 90%;
  height: 90%;
  margin: 10px 0;
}

.audio {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.video-local {
  display: flex;
  background: black;
  position: absolute;
  border: 1px solid lightgrey;
  height: 120px;
  width: 120px;
  bottom: 100px;
  left: 10px;
  transform: scaleX(-1);
}

.toggle-aduio {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0px;
  z-index: 11111;
  right: -30px;
}

.full-screen {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 111;
}

.button {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 150px;
  background: rgba(0, 0, 0, 0.9);
  // border-top: 1px solid #DCDCDCFF;
}

.demonstration {
  color: #fff;
  width: 100%;
  text-align: center;
  // position: absolute;
  // top: 30px;
  // margin: 50px 0;
}

/deep/ .loadingbutton {
  width: 0px !important;
  height: 0px;
  background: linear-gradient(to right, #ffffff, #ffffff);
  border: 0px solid;
}

/deep/ .disabled-row {
  pointer-events: none;
  opacity: 0.4;
}
</style>
