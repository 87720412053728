<template>
  <div />
</template>

<script>
import TBaseControl from '@/components/trina/base//TBaseControl';
import { getValueDataList } from '@/api/component';
import { get, post } from '@/components/trina/utils/request';
export default {
  name: 'TBaseGroup',
  extends: TBaseControl,
  props: {
    // value: {
    //   required: true
    // }, // 接受外部v-model传入的值
    defaultValue: {
      type: String|Number,
      default: null
    },
    type: {
      type: String,
      default: 'local' // local/remote
    },
    valueKey: {
      type: String,
      default: 'value'
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    options: {
      type: Array,
      default: function () {
        return [];
      }
    },
    fetch: Function, // 必须返回指定格式的数据,
    method: {
      type: String,
      default: 'post',
      validator: value => {
        const methodTypes = ['get', 'post'];
        return methodTypes.indexOf(value.toLowerCase()) !== -1;
      }
    },
    url: { // type='remote'有效
      type: String,
      default: ''
    },
    valsetId: {// type='valueSet'有效
      type: String,
      default: ''
    },
    params: {
      type: Object,
      default: function() {
        return {}
      }
    },
    autoSelected: {// 针对select有效
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      total: 0,
      isActivated: false,
      queryParam: {},
      groupData: [],
      // mvalue: ''
    };
  },
  // watch: {
  //   mvalue(val, oldVal) {
  //     console.log('-mvalue-', val, oldVal);
  //     if (val != oldVal) {
  //       this.$emit('input', this.mvalue);
  //     }
  //   },
  //   value(val, oldVal) {
  //     console.log('-value-', val, oldVal);
  //     this.mvalue = val;
  //   }
  // },
  mounted() {
    this.queryParam = this.params;
    this.getOptions();
    this.isActivated = true;
  },
  activated() {
    if (!this.isActivated) {
      this.getOptions();
    }
    else {
      this.isActivated = false;
    }
  },
  methods: {
    getOptions() {
      // console.log('type', this.type, this.options);
      if (this.queryParam instanceof Object) {
        this.queryParam = this.queryParam;
      }
      if (this.type === 'local') {
        this.groupData = this.options;
        this.setSelectValue();
      } else if (this.type === 'valset') { // 通过值集来获取数据来源
        this.getValueDataList();
      } else if (this.type === 'remote') { // 通过远程接口URL来获取数据来源
        const { method, url, queryParam } = this;
        let requestObject = null;
        if (fetch) {
          requestObject = fetch(queryParam);
        } else {
          if (method === 'get') {
            requestObject = get(url, { queryParam });
          } else {
            requestObject = post(url, queryParam);
          }

          requestObject.then(
            response => {
              if (response && response.data) {
                const responseData = response.data;
                this.groupData = responseData.data;
                this.total = responseData.total ? responseData.total : 0;
                this.setSelectValue();
                // 没有默认值，autoSelected=true
                if (!this.defaultValue && this.autoSelected && this.groupData && this.groupData.length > 0) {
                  this.defaultValue = this.groupData[0].value;
                }
              }
            })
            .catch(error => {
              this.groupData = [];
              this.total = 0;
            });
        }
      }
      if (!this.groupData) {
        this.groupData = []
      }
    },
    getValueDataList() {
      getValueDataList(this.valsetId, this.queryParam).then(response => {
        const responseData = response.data;
        this.groupData = responseData.list;
        this.total = responseData.total ? responseData.total : 0;
        this.setSelectValue();
        // 没有默认值，autoSelected=true
        if (!this.defaultValue && this.autoSelected && this.groupData && this.groupData.length > 0) {
          if(this.groupData[0].value) {
            this.modelValue = this.groupData[0].value;
          } else {
            this.modelValue = this.groupData[0].id;
          }
        }
      }).catch(error => {
        console.log('Get valueSet data failed.', error);
        this.groupData = [];
        this.total = 0;
      });
    },
    setSelectValue() {
      this.modelValue = this.defaultValue;
    },
    setParams(params) {
      this.queryParam = params;
    }
  }
};
</script>

<style scoped>
</style>
