var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "560px",
            title: _vm.title,
            visible: _vm.visible,
            "close-on-click-modal": false,
            "modal-append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("my-form", {
            ref: "userInfoForm",
            attrs: {
              id: "userInfoForm",
              "col-num": 1,
              items: _vm.formQuerDef.items,
              btns: _vm.formQuerDef.btns,
              rules: _vm.formQuerDef.rules,
            },
            on: { "btn-click": _vm.btnClick },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }