var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-checkbox-group",
    {
      ref: _vm.name,
      attrs: { name: _vm.name },
      on: { change: _vm.change },
      model: {
        value: _vm.modelValue,
        callback: function ($$v) {
          _vm.modelValue = $$v
        },
        expression: "modelValue",
      },
    },
    _vm._l(_vm.groupData, function (option, idx) {
      return _c("el-checkbox", { key: idx, attrs: { label: option.value } }, [
        _vm._v(_vm._s(option.label)),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }