var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "bgWrapper", staticClass: "mt-2" },
    [
      _c(
        "viewer",
        { staticClass: "main-div", attrs: { images: _vm.fileList } },
        _vm._l(_vm.dataList, function (item, index) {
          return _c("img", {
            key: index,
            staticClass: "main-div",
            attrs: {
              src: `${item}`,
              height: _vm.finalPreviewSize,
              width: _vm.finalPreviewSize,
            },
          })
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }