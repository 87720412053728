/**
 * Created by Evan on 19/7/31.
 */
import moment from 'moment';
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time == 'object') {
    date = time;
  } else {
    if (('' + time).length === 10)
      time = parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === 'a')
      return [
        '一',
        '二',
        '三',
        '四',
        '五',
        '六',
        '日'
      ][value - 1];
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

export function formatTime(time, option) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) { // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

// 格式化时间
export function getQueryObject(url) {
  url = url == null ?
    window.location.href :
    url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 *get getByteLen
 * @param {Sting} val input value
 * @returns {number} output value
 */
export function getByteLen(val) {
  let len = 0;
  for (let i = 0; i < val.length; i++) {
    if (val[i].match(/[^\x00-\xff]/ig) != null) {
      len += 1;
    } else {
      len += 0.5;
    }
  }
  return Math.floor(len);
}

export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

export function param(json) {
  if (!json)
    return '';
  return cleanArray(Object.keys(json).map(key => {
    if (json[key] === undefined)
      return '';
    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
  })).join('&');
}

export function param2Obj(url) {
  const search = url.split('?')[1];
  if (!search) {
    return {}
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
}

export function html2Text(val) {
  const div = document.createElement('div');
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

export function objectMerge(target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (typeof target !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  for (const property in source) {
    if (source.hasOwnProperty(property)) {
      const sourceProperty = source[property];
      if (typeof sourceProperty === 'object') {
        target[property] = objectMerge(target[property], sourceProperty);
        continue;
      }
      target[property] = sourceProperty;
    }
  }
  return target;
}

export function scrollTo(element, to, duration) {
  if (duration <= 0)
    return;
  const difference = to - element.scrollTop;
  const perTick = difference / duration * 10;
  setTimeout(() => {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop === to)
      return;
    scrollTo(element, to, duration - 10);
  }, 10);
}

export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

export const pickerOptions = [{
  text: '今天',
  onClick(picker) {
    const end = new Date();
    const start = new Date(new Date().toDateString());
    end.setTime(start.getTime());
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近一周',
  onClick(picker) {
    const end = new Date(new Date().toDateString());
    const start = new Date();
    start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近一个月',
  onClick(picker) {
    const end = new Date(new Date().toDateString());
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    picker.$emit('pick', [start, end]);
  }
}, {
  text: '最近三个月',
  onClick(picker) {
    const end = new Date(new Date().toDateString());
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    picker.$emit('pick', [start, end]);
  }
}]

export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

export function debounce(func, wait, immediate) {
  let timeout,
    args,
    context,
    timestamp,
    result;
  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;
    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout)
          context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout)
      timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone');
  }
  const targetObj = source.constructor === Array ? [] : {};
  for (const keys in source) {
    if (source.hasOwnProperty(keys)) {
      if (source[keys] && typeof source[keys] === 'object') {
        targetObj[keys] = source[keys].constructor === Array ? [] : {};
        targetObj[keys] = deepClone(source[keys]);
      } else {
        targetObj[keys] = source[keys];
      }
    }
  }
  return targetObj;
}

export function clearNoNum(str) {
  let value = str.toString()
  if (value != '' && value.toString().substr(0, 1) == '.') {
    value = "";
  }
  value = value.replace(/^0*(0\.|[1-9])/, '$1'); //解决 粘贴不生效
  value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
  value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
  value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
  if (value.indexOf(".") < 0 && value != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    if (value.substr(0, 1) == '0' && value.length == 2) {
      value = value.substr(1, value.length);
    }
  }
  return value
}


export function clearNoNumIncludeNegative(str) {
  let value = str.toString()
  if (value != '' && value.substr(0, 1) == '.') {
    value = "";
  }
  let notegative = value.substr(0, 1) == '-'
  value = value.replace(/^0*(0\.|[1-9])/, '$1'); //解决 粘贴不生效
  value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
  value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
  value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两个小数
  if (value.indexOf(".") < 0 && value != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    if (value.substr(0, 1) == '0' && value.length == 2) {
      value = value.substr(1, value.length);
    }
  }
  if (notegative && value != 0) {
    return `-${value}`
  } else {
    return value
  }
}


export function clearNoNumInt(str) {
  let value = str.toString()
  if (value != '' && value.substr(0, 1) == '.') {
    value = "";
  }
  value = value.replace(/^0*(0\.|[1-9])/, '$1').replace('-', ''); //解决 粘贴不生效
  value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
  value = value.replace(/\.{1,}/g, ""); //清除小数点
  value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2'); //
  if (value.indexOf(".") < 0 && value != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    if (value.substr(0, 1) == '0' && value.length == 2) {
      value = value.substr(1, value.length);
    }
  }
  return value
}

export function resetFields(object) {
  let data = {
    ...object
  }
  Object.keys(data).forEach(v => {
    if (Array.isArray(data[v])) {
      data[v] = []
    } else if (typeof data[v] === 'object') {
      data[v] = {}
    } else if (Number.isInteger(data[v])) {
      data[v] = 0
    } else {
      data[v] = ''
    }
  })
  return data
}

export function today() {
  const date = new Date();
  return parseTime(date, '{y}-{m}-{d}');
}
export function todays() {
  const date = new Date();
  return parseTime(date, '{y}{m}{d}{h}{i}{s}');
}

export function yesterday() {
  const date = new Date();
  date.setTime(date.getTime() - 3600 * 1000 * 24);
  return parseTime(date, '{y}-{m}-{d}');
}

export function thisweek() {
  const end = new Date();
  const start = new Date();
  //start.setTime(start.getTime() - (new Date().getDay() - 1) * 24 * 60 * 60 * 1000);
  start.setTime(start.getTime() - (start.getDay() - 1) * 24 * 60 * 60 * 1000);
  end.setTime(end.getTime() + (7 - end.getDay()) * 24 * 60 * 60 * 1000);
  return [
    parseTime(start, '{y}-{m}-{d}'),
    parseTime(end, '{y}-{m}-{d}')
  ];
}

export function lastweek() {
  const end = new Date();
  const start = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7 - (start.getDay() - 1) * 24 * 60 * 60 * 1000);
  end.setTime(end.getTime() - 3600 * 1000 * 24 * 7 + (7 - end.getDay()) * 24 * 60 * 60 * 1000);
  return [
    parseTime(start, '{y}-{m}-{d}'),
    parseTime(end, '{y}-{m}-{d}')
  ];
}

export function thismonth() {
  const end = new Date();
  const start = new Date();
  return [
    `${start.getFullYear()}-${ (start.getMonth() + 1) < 10
      ? `0${ (start.getMonth() + 1)}`
      : (start.getMonth() + 1)}-01`,
    parseTime(end, '{y}-{m}-{d}')
  ];
}

export function lastmonth() {
  const nowdays = new Date();
  let year = nowdays.getFullYear();
  let month = nowdays.getMonth();
  if (month === 0) {
    month = 12;
    year = year - 1
  }
  if (month < 10) {
    month = '0' + month;
  }
  return [
    `${year}-${month}-01`,
    `${year}-${month}-${new Date(year, month, 0).getDate()}`
  ];
}

//加法
export function accAdd(arg1, arg2) {
  let r1, r2, m, c;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace('.', ''));
      arg2 = Number(arg2.toString().replace('.', '')) * cm;
    } else {
      arg1 = Number(arg1.toString().replace('.', '')) * cm;
      arg2 = Number(arg2.toString().replace('.', ''));
    }
  } else {
    arg1 = Number(arg1.toString().replace('.', ''));
    arg2 = Number(arg2.toString().replace('.', ''));
  }
  return (arg1 + arg2) / m;
}


//减法
export function accSub(arg1, arg2) {
  let r1, r2, m, n;
  try {
    r1 = arg1.toString().split('.')[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split('.')[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
  n = (r1 >= r2) ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}


//乘法
function accMul(arg1, arg2) {
  let m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split('.')[1].length;
  } catch (e) {}
  try {
    m += s2.split('.')[1].length;
  } catch (e) {}
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
}

//除法
export function accDiv(arg1, arg2) {
  let t1 = 0,
    t2 = 0,
    r1,
    r2;
  try {
    t1 = arg1.toString().split('.')[1].length;
  } catch (e) {}
  try {
    t2 = arg2.toString().split('.')[1].length;
  } catch (e) {}
  r1 = Number(arg1.toString().replace('.', ''));
  r2 = Number(arg2.toString().replace('.', ''));
  return (r1 / r2) * Math.pow(10, t2 - t1);
}


//加深颜色
export function setDarkColor(color, lv) {
  const r = /^\#?[0-9a-fA-F]{6}$/;
  //test方法检查在字符串中是否存在一个模式，如果存在则返回true，否则返回false
  if (!r.test(color)) return window.alert('输入错误的hex颜色值');
  //replace替换查找的到的字符串
  color = color.replace('#', '');
  //match得到查询数组
  let hxs = color.match(/../g);
  for (let i = 0; i < 3; i++) hxs[i] = parseInt(hxs[i], 16);
  let rgbc = hxs
  //floor 向下取整
  for (let i = 0; i < 3; i++) rgbc[i] = Math.floor(rgbc[i] * (1 - lv));
  let hexs = [rgbc[0].toString(16), rgbc[1].toString(16), rgbc[2].toString(16)];
  for (let i = 0; i < 3; i++)
    if (hexs[i].length == 1) hexs[i] = "0" + hexs[i];
  return '#' + hexs.join('');
}


//浅色颜色
export function setLightColor(color, lv) {
  const r = /^\#?[0-9a-fA-F]{6}$/;
  //test方法检查在字符串中是否存在一个模式，如果存在则返回true，否则返回false
  if (!r.test(color)) return window.alert('输入错误的hex颜色值');
  //replace替换查找的到的字符串
  color = color.replace('#', '');
  //match得到查询数组
  let hxs = color.match(/../g);
  for (let i = 0; i < 3; i++) hxs[i] = parseInt(hxs[i], 16);
  let rgbc = hxs
  //floor 向下取整
  for (var i = 0; i < 3; i++) rgbc[i] = Math.floor((255 - rgbc[i]) * lv + rgbc[i]);
  let hexs = [rgbc[0].toString(16), rgbc[1].toString(16), rgbc[2].toString(16)];
  for (let i = 0; i < 3; i++)
    if (hexs[i].length == 1) hexs[i] = "0" + hexs[i];
  return '#' + hexs.join('');
}

export function HexToRgb(str, alpha) {
  const r = /^\#?[0-9a-fA-F]{6}$/;
  //test方法检查在字符串中是否存在一个模式，如果存在则返回true，否则返回false
  if (!r.test(str)) return window.alert("输入错误的hex颜色值");
  //replace替换查找的到的字符串
  str = str.replace("#", "");
  //match得到查询数组
  let hxs = str.match(/../g);
  for (var i = 0; i < 3; i++) hxs[i] = parseInt(hxs[i], 16);
  return `rgba(${hxs[0]},${hxs[1]},${hxs[2]}${alpha?`,${alpha}`:''})`;
}

const dateFormat = 'YYYY-MM-DD';
export function getCurrentMonthFirstDay() {
  const firstDay = moment(new Date(), dateFormat).startOf('month').format(dateFormat);
  return firstDay;
}
export function getCurrentDate() {
  const currentDate = moment().format(dateFormat);
  return currentDate;
}

/**
 * 当前日期的前一天
 * @type {string}
 */

export function getYesterday() {
  const yesterday = moment().subtract(1, 'days').format(dateFormat);
  return yesterday;
}
const dateFormat2 = 'YYYY-MM-DD HH:mm:ss';
export function getCurrentMonthFirstDate() {
  return moment(new Date(), dateFormat2).startOf('month').format(dateFormat2);
}


// 当天的0点时间
const dateFormat3 = 'YYYY-MM-DD HH:mm:ss';
export function getTodayStart() {
  return moment().startOf('day').format(dateFormat3);
}

// 当天的23:59:59
const dateFormat4 = 'YYYY-MM-DD HH:mm:ss';
export function getdayEnd() {
  return moment().endOf('day').format(dateFormat4);
}


export function getCurrentMonthLastDay() {
  const lastDay = moment(new Date(), dateFormat).endOf('month').format(dateFormat);
  return lastDay;
}

const dateFormatYMD = 'YYYY/MM/DD/';
export function getCurrentDayStr() {
  return moment(new Date()).format(dateFormatYMD) + moment(new Date()).utc();
}
