/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}




export function generateUniqueId() {
  const timestamp = Date.now(); // 获取当前时间戳
  const randomNum = Math.floor(Math.random() * 1000); // 生成一个6位的随机数
  return parseInt(`${timestamp}${randomNum}`);
}


/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

// 获取当前时间 yyyy-MM-dd HH:mm:ss
export function getNow(){
  let now = new Date();
  let year = now.getFullYear();
  let mouth = now.getMonth() + 1 < 10? "0" + (now.getMonth() + 1): now.getMonth() + 1;
  let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate();
  let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours();
  let min = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes();
  let sec = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds();
  console.log( `${year}-${mouth}-${date} ${hour}:${min}:${sec}`);
  return `${year}-${mouth}-${date} ${hour}:${min}:${sec}`
};

//message提示语
export const messageTips = (that,msg,type) => that.$message({
  message: msg,
  type: type
})

export function getTreeDeepArr(key, treeData) {
  //根据后台返回的单独id计算出层级选择器需要的复合id数组数据
  let arr = []; // 在递归时操作的数组
  let returnArr = []; // 存放结果的数组
  let depth = 0; // 定义全局层级
  // 定义递归函数
  function childrenEach(childrenData, depthN) {
    for (var j = 0; j < childrenData.length; j++) {
      depth = depthN; // 将执行的层级赋值 到 全局层级

      arr[depthN] = childrenData[j].id;

      if (childrenData[j].id == key) {
        // returnArr = arr; // 原写法不行, 因 此赋值存在指针关系
        returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
        break;
      } else {
        if (childrenData[j].children) {
          depth++;
          childrenEach(childrenData[j].children, depth);
        }
      }
    }
    return returnArr;
  }
  return childrenEach(treeData, depth);
}

export function today() {
  const date = new Date();
  return parseTime(date, '{y}-{m}-{d}');
}

export function yesterday() {
  const date = new Date();
  date.setTime(date.getTime() - 3600 * 1000 * 24);
  return parseTime(date, '{y}-{m}-{d}');
}

export function thisweek() {
  const end = new Date();
  const start = new Date();
  //start.setTime(start.getTime() - (new Date().getDay() - 1) * 24 * 60 * 60 * 1000);
  start.setTime(start.getTime() - (start.getDay() - 1) * 24 * 60 * 60 * 1000);
  end.setTime(end.getTime() + (7 - end.getDay()) * 24 * 60 * 60 * 1000);
  return [
    parseTime(start, '{y}-{m}-{d}'),
    parseTime(end, '{y}-{m}-{d}')
  ];
}

export function lastweek() {
  const end = new Date();
  const start = new Date();
  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7 - (start.getDay() - 1) * 24 * 60 * 60 * 1000);
  end.setTime(end.getTime() - 3600 * 1000 * 24 * 7 + (7 - end.getDay()) * 24 * 60 * 60 * 1000);
  return [
    parseTime(start, '{y}-{m}-{d}'),
    parseTime(end, '{y}-{m}-{d}')
  ];
}

export function thismonth() {
  const end = new Date();
  const start = new Date();
  return [
    `${start.getFullYear()}-${ (start.getMonth() + 1) < 10
      ? `0${ (start.getMonth() + 1)}`
      : (start.getMonth() + 1)}-01`,
    parseTime(end, '{y}-{m}-{d}')
  ];
}

export function lastmonth() {
  const nowdays = new Date();
  let year = nowdays.getFullYear();
  let month = nowdays.getMonth();
  if (month === 0) {
    month = 12;
    year = year - 1
  }
  if (month < 10) {
    month = '0' + month;
  }
  return [
    `${year}-${month}-01`,
    `${year}-${month}-${new Date(year, month, 0).getDate()}`
  ];
}


/**
 * 计算时间差（天、小时、分钟、秒）
 * @param time
 */
export function diffTime(diff) {
  //计算出相差天数
  var days=Math.floor(diff/(24*3600*1000));

  //计算出小时数
  var leave1=diff%(24*3600*1000);    //计算天数后剩余的毫秒数
  var hours=Math.floor(leave1/(3600*1000));
  //计算相差分钟数
  var leave2=leave1%(3600*1000);        //计算小时数后剩余的毫秒数
  var minutes=Math.floor(leave2/(60*1000));

  //计算相差秒数
  var leave3=leave2%(60*1000);      //计算分钟数后剩余的毫秒数
  var seconds=Math.round(leave3/1000);

  var returnStr = seconds + "秒";
  if(minutes>0) {
    returnStr = minutes + "分" + returnStr;
  }
  if(hours>0) {
    returnStr = hours + "小时" + returnStr;
  }
  if(days>0) {
    returnStr = days + "天" + returnStr;
  }
  return returnStr;
}

export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

// 替换邮箱字符
export function regEmail(email) {
  if (String(email).indexOf('@') > 0) {
    const str = email.split('@')
    let _s = ''
    if (str[0].length > 3) {
      for (var i = 0; i < str[0].length - 3; i++) {
        _s += '*'
      }
    }
    var new_email = str[0].substr(0, 3) + _s + '@' + str[1]
  }
  return new_email
}

// 替换手机字符
export function regMobile(mobile) {
  if (mobile.length > 7) {
    var new_mobile = mobile.substr(0, 3) + '****' + mobile.substr(7)
  }
  return new_mobile
}

export function GetDistance(lng1, lat1, lng2, lat2) {
  var radLat1 = lat1 * Math.PI / 180.0;
  var radLat2 = lat2 * Math.PI / 180.0;
  var a = radLat1 - radLat2;
  var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
  var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
  s = s * 6378.137;// EARTH_RADIUS;
  s = Math.round(s * 10000) / 10;
  return s;
}

export function getDateBetweenEvery (start, end, type, that) {
  try {
    console.log(start, end)
    if (start.indexOf(" ") > -1) {
      console.log('开始日期不符合规范')
      return
    } else if (end.indexOf(" ") > -1) {
      console.log('结束日期不符合规范')
      return
    }
    var result = [];
    var beginDay = start.split("-");
    var endDay = end.split("-");
    if (beginDay.length < 2 || endDay.length < 2) {
      console.log('结束日期不符合规范')
      return
    }
    if (start === end) { // 同一天
      return [start, end]
    }
    var diffDay = new Date();
    var dateList = new Array;
    var i = 0;
    diffDay.setDate(beginDay[2]);
    diffDay.setMonth(beginDay[1]-1);
    diffDay.setFullYear(beginDay[0]);
    result.push(start);
    while(i == 0){
      var countDay = diffDay.getTime() + 24 * 60 * 60 * 1000;
      diffDay.setTime(countDay);
      dateList[2] = diffDay.getDate();
      dateList[1] = diffDay.getMonth() + 1;
      dateList[0] = diffDay.getFullYear();
      if(String(dateList[1]).length == 1){dateList[1] = "0"+dateList[1]};
      if(String(dateList[2]).length == 1){dateList[2] = "0"+dateList[2]};
      result.push(dateList[0]+"-"+dateList[1]+"-"+dateList[2]);
      if(dateList[0] == endDay[0] && dateList[1] == endDay[1] && dateList[2] == endDay[2]){ i = 1;
      }
    };

    if (type === 'month') {
      if (result.length > 0) {
        let arr = []
        for (let i = 0; i < result.length; i++) {
          let date = result[i].split("-")
          arr[i] = date[0] + "-" + date[1]
        }
        result = that._.uniq(arr)
      }
    }
    return result;
  } catch (e) {
    console.log(e, 213)
  }
};

export function getCurrentDomain() {
  return window.location.protocol + '//' + window.location.host
}

/**
 * 去除html标签 空格 保留文本
 */
export function removeHtmlTag(str) {
  if (!str) return ''
  return str.replace(/<[^>]+>/g, '').replaceAll('&nbsp;', '')
}

export function jsonSimpleClone(obj) {
  return JSON.parse(JSON.stringify(obj))
}
