import request from '@/utils/request';
import md5 from 'js-md5';

export function saveUserInfo(params) {
    const data = {
        userName: params.userName,
        loginName: params.loginName,
        email: params.email,
        mobile: params.mobile
    }
    return request({
        url: '/web/system/user/saveUserInfo',
        method: 'post',
        data
    });
}

export function getCurrentUserInfo() {
    return request({
        url: '/system/api/V1/web/account/getCurrentUserInfo',
        method: 'get'
    });
}

export function changePwd(data) {
    // data.oldPassword = md5(data.oldPassword);
    // data.newPassword = md5(data.newPassword);
    return request({
        url: '/web/system/user/changePassword',
        method: 'post',
        data
    });
}
