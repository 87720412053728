<template>
  <div id="app">
    <!-- {{allkey}} -->
    <router-view/>
    <audio id="audio" preload="auto">
      <source src="https://images.cyoubike.com/notify.wav">
    </audio>
    <el-button @click="aplayAudio" v-show="false"/>


    <el-dialog
        :visible.sync="notifyDialog3"
        title="智慧助手"
        append-to-body
        width="800px"
    >
      <div style="width: 100%">
          <span>{{
              this.msgContent
            }}</span>
      </div>
      <div style="width: 100%; text-align: center; margin-top: 20px">
        <el-button type="primary" @click="handleRowClick3()"
        >查看详情
        </el-button>
      </div>
    </el-dialog>


    <el-dialog
        :visible.sync="notifyDialog4"
        title="表单管理"
        append-to-body
        width="800px"
    >
      <div style="width: 100%">
          <span>{{ "【" + this.msgTitle + "】" + this.msgContent }}</span>
      </div>
      <div style="width: 100%; text-align: center; margin-top: 20px">
        <el-button type="primary" @click="handleRowClick4()"
        >查看详情
        </el-button>
      </div>
    </el-dialog>


    <el-dialog
        :visible.sync="notifyDialog"
        title="告警通知"
        append-to-body
        width="800px"
    >
      <div style="width: 100%">
          <span>{{
              this.msgContent
            }}</span>
      </div>
      <div style="width: 100%; text-align: center; margin-top: 20px">
        <el-button type="primary" @click="handleRowClick()"
        >查看详情
        </el-button>
      </div>
    </el-dialog>











    <el-dialog
      :visible.sync="notifyDialogv2"
      title="纠纷通知"
      append-to-body
      width="800px"
    >
      <div style="width: 100%">
          <span>{{
              this.msgContentv2
            }}</span>
      </div>
      <div style="width: 100%; text-align: center; margin-top: 20px">
        <el-button type="primary" @click="handleRowClickV2(2)"
        >查看详情
        </el-button>
      </div>
    </el-dialog>

    <!-- 音视频拨打接听主界面，可以提取到一个组件中  start -->
    <el-dialog
      :visible.sync="showPbx"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      custom-class="customClass"
      v-dialogDrag
    >
      <div
        class="videogroup"
        :style="{ height: contentHeight - 110 - isHeight + 'px' }"
      >
        <div
          v-if="sipState == 0"
          style="text-align: center; color: #00000066; font-size: 18px"
        >
          <!-- 从左侧选择人员进行语音、视频通话 -->
        </div>
        <div v-else style="width: 100%; height: 100%">
          <div v-show="sipState == 0">
            <div>
              <!-- 呼叫中 -->
            </div>
          </div>
          <div
            style="width: 100%; height: 100%"
            class="player"
            ref="player"
            @mouseenter="showElement"
            @mouseleave="hideElement"
          >
            <div
              class="full-screen"
              @click="fullScreenFun"
              v-if="isElementVisible"
            >
              <img v-if="!iFullscreen" src="@/assets/音视频全屏.png" alt=""/>
              <img v-else src="@/assets/音视频全屏收缩.png" alt=""/>
            </div>
            <div class="Calling" v-show="sipState == 1">
              <div>{{ title }}呼叫'{{ titleName }}'中...</div>
              <el-button class="stopButton" type="danger" @click="handleCancelCall"
              >挂断
              </el-button
              >
            </div>
            <div v-show="sipState == 2" class="videoSuccess">
              <div v-show="title == '视频'" class="video">
                <div id="videoRemote">
                </div>
                <div class="video-local">
                  <div id="videoLocal" width="100%">
                  </div>
                </div>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="点击切换语音通话"
                  placement="top"
                >
                  <el-image
                    title="点击切换语音通话"
                    @click="requestVideo2Audio"
                    class="toggle-aduio"
                    :src="require('@/assets/images/toggleAudio.png')"
                    fit="fill"
                  ></el-image>
                </el-tooltip>
              </div>
              <div v-show="title == '语音'" class="audio">
                <el-image
                  style="width: 120px; height: 120px; margin-bottom: 20px"
                  :src="audioUrl"
                  :fit="'fill'"
                ></el-image>
                <div class="demonstration">正在与'{{ titleName }}'通话中</div>
              </div>
              <div class="button">
                <div class="time">{{ str || '00:00' }}</div>
                <el-button class="stopButton" type="danger" @click="hangupClick"
                >挂断
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 音视频拨打接听主界面，可以提取到一个组件中  end -->

  </div>
</template>
<script>
import {Client} from '@stomp/stompjs';
import {getToken} from '@/utils/auth';
import {
  setItem
} from './components/trina/table/localPageIndex.js'
import TRTC from "trtc-sdk-v5";
import waitUrl from "@/assets/wait.mp3";
import hangUrl from "@/assets/hang.mp3";
import { getUserInfo} from '@/api/modules/user';

export default {
  watch: {
    sipState(val) {
      console.log('sipState --> ', val);
      if (val == 2) {
        this.receipt();
        if (this.startAudio) {
          this.startAudio.pause();
        }
        if (this.timerCount) {
          console.log("取消计时器", this.timerCount)
          clearInterval(this.timerCount)
        }
      } else {
        if (val == 1) {
          this.startAudio = new Audio();
          this.startAudio.src = waitUrl;
          this.startAudio.loop = true;
          this.startAudio.play();
        }
        if (val == 0) {
          if (this.startAudio) {
            this.startAudio.pause();
          }
          let audio = new Audio();
          audio.src = hangUrl;
          audio.play();
          if (this.timerCount) {
            console.log("取消计时器3", this.timerCount)
            clearInterval(this.timerCount)
          }
        }
        this.reset();
      }
    },
  },
  data() {
    return {
      notifyDialog4: false,
      modelId:null,
      notifyDialog3:false,
      initFlag: false,
      stompClient: '',
      alarmMsg: {},
      //音视频相关参数 end
      showPbx: false,
      notifyDialog: false,
      notifyDialogv2: false,
      msgContent: "",
      msgTitle: "",
      msgId: "",
      msgContentv2: "",
      // 0 未通话 1 呼叫中 2 通话中
      sipState: 0,
      audioUrl: require('@/assets/images/audio.png'),
      title: '',
      titleName: '',
      iFullscreen: false,
      trtc: "",
      strRoomId: "",
      isElementVisible: "",
      isHeight: 0,
      contentHeight: 0,
      startAudio: null,
      //计时器
      str: '',
      h: 0, //定义时，分，秒，毫秒并初始化为0；
      m: 0,
      ms: 0,
      s: 0,
      time: 0,
      timerCount: null,
      timerCountVal: 0,
      confirmDialog: "",
      createRoomResult: {},
      //音视频相关参数 end
    };
  },

  created() {
    setItem(null, null, true)
    if (getToken()) {
      console.log("走了几遍啊")
      this.connectChannel()
      this.initTrtc()
    }
  },
  beforeDestroy() {
    console.log("APP即将销毁...")
    if (this.sipState !== 0) {
      const _this = this;
      if (_this.title === '视频') {
        console.log("停止本地视频")
        _this.trtc.stopLocalVideo();
      }
      console.log("停止本地语音")
      _this.trtc.stopLocalAudio();
      //退出房间
      _this.trtc.exitRoom();
      //取消监听
      console.log("取消bus监听")
      this.$bus.off("handleCall", this.handleCall)
      this.$bus.off("loginEvent", this.handleLoginEvent)
      this.$bus.off("loginOutEvent", this.handleLoginOutEvent)
    }
    if (this.timerCount) {
      clearInterval(this.timerCount)
    }
  },
  mounted() {
    //开启监听
    console.log("开启bus监听")
    this.$bus.on("handleCall", this.handleCall)
    this.$bus.on("loginEvent", this.handleLoginEvent)
    this.$bus.on("loginOutEvent", this.handleLoginOutEvent)

    this.setContentHeight(); // 初始化内容区域的高度
    window.addEventListener('resize', this.setContentHeight); // 监听窗口大小变化
  },

  updated() {
  },

  methods: {
    handleRowClick3(){
      this.notifyDialog3 = false

      this.$router.push({name:"SmartController",params:{
          id:this.modelId
      }})

    },
    handleRowClick4(){
      this.notifyDialog4 = false

      this.$router.push({name:"FromManager",
        params:{
          id:this.msgId
        }
      })
    },
    handleLoginEvent() {
      this.connectChannel()
      this.initTrtc()
    },
    handleLoginOutEvent() {
      this.disConnectChannel()
    },
    //开始
    receipt: function () {
      this.time = setInterval(this.timer, 50);
    },
    reset: function () {
      //重置
      clearInterval(this.time);
      this.h = 0;
      this.m = 0;
      this.ms = 0;
      this.s = 0;
      this.str = '00:00:00';
    },
    timer: function () {
      //定义计时函数
      this.ms = this.ms + 50; //毫秒
      if (this.ms >= 1000) {
        this.ms = 0;
        this.s = this.s + 1; //秒
      }
      if (this.s >= 60) {
        this.s = 0;
        this.m = this.m + 1; //分钟
      }
      if (this.m >= 60) {
        this.m = 0;
        this.h = this.h + 1; //小时
      }
      this.str =
        this.toDub(this.h) +
        ':' +
        this.toDub(this.m) +
        ':' +
        this.toDub(this.s) +
        ''
    },
    toDub: function (n) {
      //补0操作
      if (n < 10) {
        return '0' + n;
      } else {
        return '' + n;
      }
    },
    setContentHeight() {
      this.isHeight = 0;
      this.contentHeight = window.innerHeight - 180; // 设置内容区域的高度为窗口的高度
    },
    showElement() {
      console.log('showElement');
      // clearTimeout(this.timeoutId);
      this.isElementVisible = true;
    },
    hideElement() {
      console.log('hideElement');
      // this.timeoutId = setTimeout(() => {
      this.isElementVisible = false;
      // }, 5000);
    },
    fullScreenFun() {
      var fullscreenEnabled =
        document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.msFullscreenEnabled;

      if (fullscreenEnabled) {
        let de = this.$refs.player;

        if (this.iFullscreen) {
          //关闭全屏
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
          this.iFullscreen = false;
          return;
        } else {
          //打开全屏
          if (de.requestFullscreen) {
            de.requestFullscreen();
          } else if (de.mozRequestFullScreen) {
            de.mozRequestFullScreen();
          } else if (de.webkitRequestFullScreen) {
            de.webkitRequestFullScreen();
          }
          this.iFullscreen = true;
        }
      }
    },
    connectChannel() {
      let _this = this;
      if (_this.stompClient) {
        console.log("websocket已创建")
        return
      }
      const url = process.env.VUE_APP_SIP_CALL_WEBSOCKET;
      console.log("连接websocket -->", url)
      _this.stompClient = new Client({
        brokerURL: url,
        reconnectDelay: 3000,
        connectHeaders: {
          Authorization: getToken(), // 获取token
        },
        onConnect: () => {
          console.log("WS已链接...")
          _this.subscribe();

          //音视频相关监听
          _this.subscribeRegisterResult();
          _this.subscribeInvite();
          _this.subscribeCreateRoomResult();
          _this.subscribeCancelWaiting();
          _this.subscribeVideo2AudioResult();
          _this.subscribeAnswerResult();

          //音视频注册
          _this.stompClient.publish({destination: '/app/register', body: JSON.stringify({'userId': this.xy_userId})});
        },
        onDisconnect: () => {
          console.log("WS断开链接...")
        }
      });
      //激活websocket
      _this.stompClient.activate()
    },
    initTrtc() {
      //创建音视频对象
      if (this.trtc) {
        console.log("TRTC已创建")
        return
      }
      this.trtc = TRTC.create();
      this.subscribeTrtcEvent();
    },
    subscribeTrtcEvent() {
      this.trtc.on(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, event => {
        console.log("对方关闭了语音")
      });

      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, event => {
        this.$message({type: 'info', message: '对方关闭了视频',});
      });

      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, event => {
        const userId = event.userId;
        console.log("远程视频可用.. " + userId)
        const streamType = event.streamType;
        this.trtc.startRemoteVideo({userId, streamType, view: "videoRemote"});
      });

      this.trtc.on(TRTC.EVENT.REMOTE_USER_ENTER, event => {
        this.sipState = 2
        const userId = event.userId;
        console.log("用户进入了房间userId:", userId)
        this.$message({type: 'info', message: '已接通',});
      });

      this.trtc.on(TRTC.EVENT.REMOTE_USER_EXIT, event => {
        const userId = event.userId;
        console.log("用户退出了房间userId:", userId)
        this.hangupClick();
      });

      this.trtc.on(TRTC.EVENT.KICKED_OUT, event => {
        if (event.reason === 'kick') {
          this.$message({type: 'info', message: '你被踢出了房间',});
        }
        console.log("房间解散了 reason:", event.reason)
        this.hangupClick();
      });
      //监听网络状态
      this.trtc.on(TRTC.EVENT.NETWORK_QUALITY, event => {
        if (event.uplinkNetworkQuality > 3) {
          this.$message({type: 'info', message: '您的网络不佳',});
        }
        if (event.downlinkNetworkQuality > 3) {
          this.$message({type: 'info', message: '对方网络不佳',});
        }
        console.log(`network-quality, uplinkNetworkQuality:${event.uplinkNetworkQuality}, downlinkNetworkQuality: ${event.downlinkNetworkQuality}`)
        console.log(`uplink rtt:${event.uplinkRTT} loss:${event.uplinkLoss}`)
        console.log(`downlink rtt:${event.downlinkRTT} loss:${event.downlinkLoss}`)
      })

    },
    answerCall(strRoomId) {
      this.stompClient.publish(
        {
          destination: '/app/answer',
          body: JSON.stringify({"strRoomId": this.strRoomId})
        }
      );
    },
    //进入音视频房间
    enterRoom(enterRoomParam) {
      let _this = this;
      let currentUserId;
      console.log("enterRoomParam -->", enterRoomParam)
      if (enterRoomParam.command === "call_in") {
        currentUserId = enterRoomParam.receiverUserId
      }
      if (enterRoomParam.command === "call_out") {
        currentUserId = enterRoomParam.ownerId
      }
      this.strRoomId = enterRoomParam.strRoomId
      _this.trtc.enterRoom({
        strRoomId: enterRoomParam.strRoomId,
        sdkAppId: enterRoomParam.sdkAppId,
        userId: currentUserId,
        userSig: enterRoomParam.userSig,
        scene: "rtc",
        role: "anchor",
      }).then(res => {
        console.log("trtc.enterRoom ==>", res)
        //视频
        if (enterRoomParam.callType === 'video') {
          console.log("本地视频 --> ")
          _this.trtc.startLocalAudio()
          _this.trtc.startLocalVideo({
            view: 'videoLocal',
            option: {
              //profile配置 https://cloud.tencent.com/document/product/647/74680
              option: {profile: '360p'}
            }
          })
        } else {
          //音频
          _this.trtc.startLocalAudio()
        }
      }).catch(err => {
        console.log("无法拨打音视频 ==> ", err)
        _this.$message({type: 'info', message: '无法拨打音视频',});
        if (_this.timerCount) {
          clearInterval(_this.timerCount)
        }
      })
    },
    handleCancelCall() {
      this.$message({
        type: 'info',
        message: '已取消呼叫',
      });
      this.cancelCall()
      this.sipState = 0;
      this.showPbx = false;
    },
    // 挂断电话
    hangupClick() {
      const _this = this;
      if (_this.title === '视频') {
        console.log("停止本地视频")
        _this.trtc.stopLocalVideo();
      }
      console.log("停止本地语音")
      _this.trtc.stopLocalAudio();
      //退出房间
      _this.trtc.exitRoom();
      if (_this.sipState === 1) {
        _this.$message({
          type: 'info',
          message: '已取消',
        });
        _this.cancelCall()
      } else if (this.sipState === 2) {
        _this.$message({
          type: 'info',
          message: '通话结束',
        });
        _this.exitRoom()
      } else if (this.sipState === 99) {
        _this.$message({
          type: 'info',
          message: '对方无应答',
        });
      }
      _this.sipState = 0;
      _this.showPbx = false;
    },
    handleToggleAudio() {
      //关闭本地摄像头
      this.trtc.stopLocalVideo()
      //关闭远程摄像头
      this.trtc.stopRemoteVideo({userId: '*'});
      this.title = '语音';
    },
    cancelCall() {
      this.stompClient.publish(
        {
          destination: '/app/cancel',
          body: JSON.stringify({"strRoomId": this.strRoomId})
        }
      );
    },
    cancelCallV2() {
      this.stompClient.publish(
        {
          destination: '/app/cancel',
          body: JSON.stringify({"strRoomId": this.strRoomId,"timeout":30})
        }
      );
    },
    rejectCall() {
      this.stompClient.publish(
        {
          destination: '/app/reject',
          body: JSON.stringify({"strRoomId": this.strRoomId})
        }
      );
    },
    exitRoom() {
      this.stompClient.publish(
        {
          destination: '/app/exitRoom',
          body: JSON.stringify({"strRoomId": this.strRoomId})
        }
      );
    },
    handleCall(type, row, showConfirm) {
      console.log("即将拨打电话 ==> ", type, row, showConfirm)
      let _this = this;
      _this.titleName = row.userName
      _this.title = type === 'video' ? '视频' : '语音';
      //有弹框确认
      if (showConfirm) {
        _this.$confirm(`确定${this.title}呼叫'${row.userName}'?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            console.log('呼叫 ... ' + row.id)
            _this.timerCountVal = 30
            _this.timerCount = setInterval(_this.onTimerCount, 1000)
            _this.stompClient.publish(
              {
                destination: '/app/createRoom',
                body: JSON.stringify({'targetUserIds': [row.id], "callType": type})
              }
            );
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消呼叫',
            });
          });
      } else {
        //无弹框确认
        _this.stompClient.publish(
          {
            destination: '/app/createRoom',
            body: JSON.stringify({'targetUserIds': [row.id], "callType": type})
          }
        );
      }
    },
    onTimerCount() {
      this.timerCountVal = this.timerCountVal - 1
      console.log("倒计时中 -->", this.timerCountVal)
      if (this.timerCountVal < 0) {
        console.log("取消计时器2")
        clearInterval(this.timerCount)
        this.$message({type: 'info', message: '无人接听',});
        this.cancelCallV2();
        //没做区分 99 是超时状态
        this.sipState = 99;
        this.hangupClick()
      }
    },
    disConnectChannel() {
      console.log("WS即将断开...")
      if (this.stompClient) {
        this.stompClient.deactivate()
        this.stompClient = null
      }
      location.reload()
    },
    handleRowClick(){

      // 查看当前路由界面
      let path = this.$router.history.current.path;

      console.log(this.$router.history.current.path, 'name');

      if(path == '/overviewPro'  ||  path == '/bike/realTimeControlQiao' ){

        this.$bus.$emit('alarmMsg', this.alarmMsg)

        this.notifyDialog = false

      }else {
        this.notifyDialog = false

        this.$confirm('查看告警信息需要跳转到新的页面，确定要跳转吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {

          getUserInfo().then((res) => {

            if(res.data.expandMap.dashboardId == 1){
                this.$router.push({
                  path: '/overviewPro',
                  query: {
                    map: { alarmLon:this.alarmMsg.alarmLon,alarmLat:this.alarmMsg.alarmLat }
                  }
                });
            }else{
                this.$router.push({
                path: '/bike/realTimeControlQiao',
                  query: {
                    map: { alarmLon:this.alarmMsg.alarmLon,alarmLat:this.alarmMsg.alarmLat }
                  }
              });
            }
            ;
          });
        })
      }
    },
    handleRowClickV2(type){
      this.notifyDialogv2 = false
      this.$router.push({
        path: '/msgSend',
        query: { type },
      });
    },
    aplayAudio() {
      const audio = document.getElementById("audio");
      audio.currentTime = 0;
      audio.play();
    },
    subscribeRegisterResult() {
      this.stompClient.subscribe("/user/trtc/registerResult", response => {
        console.log("注册成功:" + response.body)
      });
    },
    subscribeCancelWaiting() {
      this.stompClient.subscribe("/user/trtc/cancelWaiting", response => {
        this.$message({type: 'info', message: '对方取消了通话',});
        console.log("对方取消了通话:" + response.body)
        this.sipState = 0
        this.showPbx = false;
        if (this.confirmDialog) {
          console.log('confirmDialog -->', this.confirmDialog)
          //TODO 关闭对话框
          this.$confirm.close()
        }
      });
    },
    requestVideo2Audio() {
      this.stompClient.publish(
        {
          destination: '/app/video2Audio',
          body: JSON.stringify({"strRoomId": this.strRoomId})
        }
      );
    },
    subscribeVideo2AudioResult() {
      this.stompClient.subscribe("/user/trtc/video2AudioResult", response => {
        this.handleToggleAudio()
      });
    },
    subscribeAnswerResult() {
      this.stompClient.subscribe("/user/trtc/answerResult", response => {
        console.log("对方已进入房间 --> ", this.createRoomResult)
        this.enterRoom(this.createRoomResult)
      });
    },
    subscribeOrderMessage() {
      let _this = this;
      this.stompClient.subscribe("/user/order/message", function (response) {
        let body = JSON.parse(response.body);
        //通过eventbus发送事件
        _this.$bus.emit('calcDistance', body);
      });
    },
    subscribeInvite() {
      let _this = this;
      _this.stompClient.subscribe("/user/trtc/invite", response => {
        console.log("邀请进入房间", response.body)
        let result = JSON.parse(response.body)
        if (result.code === 200) {
          _this.titleName = result.data.ownerName
          _this.sipState = 1
          const callType = result.data.callType === 'video' ? '视频' : '语音';
          _this.title = callType;
          _this.confirmDialog = _this.$confirm(`确定接听'${result.data.ownerName}'的${callType}电话吗?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              _this.showPbx = true
              _this.enterRoom(result.data)
              _this.answerCall(result.data.strRoomId)
            })
            .catch(() => {
              _this.sipState = 0
              _this.rejectCall()
              this.$message({
                type: 'info',
                message: '已拒绝',
              });
            });
        }
      })
    },
    subscribeCreateRoomResult() {
      let _this = this;
      _this.stompClient.subscribe("/user/trtc/createRoomResult", response => {
        let result = JSON.parse(response.body)
        console.log("createRoomResult ===> ", result)
        if (result.code === 200) {
          _this.showPbx = true
          _this.sipState = 1
          _this.createRoomResult = result.data
          _this.strRoomId = _this.createRoomResult.strRoomId
          // _this.enterRoom(result.data)
        } else {
          //错误提示
          this.$message({type: 'info', message: result.data,});
          if (this.timerCount) {
            console.log("取消计时器4", this.timerCount)
            clearInterval(this.timerCount)
          }
        }
      })
    },
    //订阅消息
    subscribe() {
      let _this = this;
      this.stompClient.subscribe('/user/queue/messagePopulation', function (response) {
        _this.aplayAudio();
        let body = JSON.parse(response.body)
        let val = {
          flag: true,
          desc: body.submitDesc,
          id: body.id,
          titleTrue: body.titleTrue,
          rowDataPro: body,
          hasRead: body.hasRead
        }
        console.log(body, 'taskAlarmTrigger')
        _this.$bus.emit('taskAlarmTrigger', val)
      });
      this.stompClient.subscribe('/user/queue/Approve', function (response) {
        _this.aplayAudio();
        let body = JSON.parse(response.body)
        let val = {
          flag: true,
          userName: body.userName,
          mobile: body.mobile,
          id: body.id,
          hasRead: body.hasRead
        }
        _this.$bus.emit('userApproverTrigger', val)
        console.log(body, 'bodyuserApproverTrigger')
      });
      this.stompClient.subscribe('/user/queue/messageAlarm', function (response) {
        let body = JSON.parse(response.body)
        _this.msgContent=body.msgContent;
         _this.alarmMsg=body;
        _this.notifyDialog=true;
        console.log('messageAlarm', body)
      });


      this.stompClient.subscribe('/user/queue/smartmMsg', function (response) {
        let body = JSON.parse(response.body)
        console.log('smartmMsg', body)
        _this.msgContent =body.content;
        _this.modelId = body.modelId;
        //  _this.alarmMsg=body;
        _this.notifyDialog3=true;

      });


      this.stompClient.subscribe('/user/queue/formManagerMsg', function (response) {
        let body = JSON.parse(response.body)
        console.log('formManagerMsg', body)
        _this.msgTitle = body.msgTitle;
        _this.msgContent = body.msgContent;
        _this.msgId = body.formManagerId;
        _this.notifyDialog4 = true;
      });


      this.stompClient.subscribe('/user/queue/messagePolice', function (response) {
        _this.aplayAudio();
        let body = JSON.parse(response.body)
        let val = {
          flag: true,
          descAll: body.submitDesc,
          titleAll: body.titleAll,
          id: body.id,
          hasRead: body.hasRead,
          allData: body
        }
        _this.$bus.emit('userPoliceTrigger', val)
        console.log(body, 'userPoliceTrigger发任务的')
      });
      this.stompClient.subscribe('/user/queue/messageDispute', function (response) {
        _this.aplayAudio();
        let body = JSON.parse(response.body)
        _this.msgContentv2=body.msgContent;
        _this.disputeMsg=body;
        _this.notifyDialogv2=true;
        console.log(body, 'messageDispute纠纷调处的信息')
      });
    },
  },
  // destroyed(){
  //   clearAllItem()
  // }
}


</script>
<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  //overflow: hidden;
}

.videogroup {
  position: relative;
  flex: 1;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: tag-fade-in2 0.7s ease-out forwards;
}

/deep/ .customClass {
  width: 500px;
  background-color: transparent !important;
  box-shadow: none !important;

  .el-dialog__body {
    padding: 20px !important;
    border-radius: 10px;
  }


}

/deep/ .el-dialog {
    background-color: none !important;
    box-shadow: none !important;
  }



.el-icon-bell {
  position: relative;

  .item {
    position: absolute;
    right: -18px;
    top: 0;
  }
}

@keyframes tag-fade-in2 {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.player {
  position: relative;
}

//呼叫中
.Calling {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: tag-fade-in 0.8s ease-out forwards;

  div {
    margin-bottom: 50px;
  }
}

.time {
  color: #fff;
  z-index: 10000;
  position: absolute;
  bottom: 100px;
}

.stopButton {
  position: absolute;
  bottom: 40px;
}

.callButton {
  position: absolute;
  left: 30px;
  background-color: #ffffff;
  backdrop-filter: blur(15px); /* 调整模糊程度，以适应您的需求 */
  .Button {
    cursor: pointer;
    display: flex;

    div {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 14px;
        margin-left: 10px;
        color: #0052d9ff;
      }
    }
  }

  .Button2 {
    display: flex;
    cursor: not-allowed;

    div {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 14px;
        margin-left: 10px;
        color: #00000066;
      }
    }
  }

  ::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5)
    );
    filter: blur(15px);
    z-index: -1;
  }
}

.videoSuccess {
  width: 100%;
  height: 100%;
  background: #000000;
  position: relative;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
}

.video {
  display: flex;
  background: black;
  position: relative;
  // border: 1px solid lightgrey;
  width: 100%;
  height: 100%;
}

.audio {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.video-local {
  display: flex;
  background: black;
  position: absolute;
  border: 1px solid lightgrey;
  height: 120px;
  width: 120px;
  bottom: 30px;
  left: 20px;
  transform: scaleX(-1);
  z-index: 10000000000;

}

.toggle-aduio {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 35px;
  z-index: 11111;
  right: 30px;
  z-index: 99999999999999;
  cursor: pointer;

}

.full-screen {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 111;
  cursor: pointer;
}

.button {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 150px;
  // background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  // border-top: 1px solid #DCDCDCFF;
}

.demonstration {
  color: #fff;
  width: 100%;
  text-align: center;
  // position: absolute;
  // top: 30px;
  // margin: 50px 0;
}

/deep/ .loadingbutton {
  width: 0px !important;
  height: 0px;
  background: linear-gradient(to right, #ffffff, #ffffff);
  border: 0px solid;
}

// /deep/ .disabled-row {
//   pointer-events: none;
//   opacity: 0.4;
// }

.cancelButtonClass {
  background-color: #1890ff;
  border-color: #1890ff;
}
#videoRemote {
  margin: 0 auto;
}
#videoLocal {
}
</style>
