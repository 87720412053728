<template>
  <div />
</template>
<script>
export default {
  name: 'TControl',
  data() {},
  methods: {
    checkPrivage(privageCode) {
      // 如果权限code不为空，则检查是否有这个权限
      if (privageCode && privageCode.length > 0) {
        return this.$store.getters.permissionKeys.includes(privageCode);
      }

      return true;
    }
  }
};
</script>
