import request from '@/utils/request'

export function getDeptTree(data) {
    return request({
        url: '/web/dept/deptTree',
        method: 'post',
        data
    })
}

export function saveDept(data) {
    return request({
        url: '/web/dept/save',
        method: 'post',
        data
    })
}


export function delDept(id) {
    return request({
        url: '/web/dept/delete/' + id,
        method: 'post'
    })
}

export function findUserByDeptId(deptId) {
    return request({
        url: '/web/dept/findUserByDeptId/' + deptId,
        method: 'post'
    })
}
export function getAllDeptIds(deptId) {
    return request({
        url: '/web/dept/allDeptIds?deptId=' + deptId,
        method: 'get'
    })
}
export function getChildDeptId(deptId) {
    return request({
        url: '/web/dept/getChildDeptId?deptId=' + deptId,
        method: 'get'
    })
}



export function getDeptInformationId(deptId) {
  return request({
      url: 'app/v1/dept/getDeptInformationIdV2?deptId=' + deptId,
      method: 'get'
  })
}

export function userCallListAllTrois(data) {
  return request({
      url: 'app/v1/dept/userCallListAllTrois',
      method: 'post',
      data
  })
}

export function getDeptConfig(params) {
    return request({
        url: '/web/deptConfig/getDeptConfig',
        method: 'get',
        params
    })
}


// 获取辖区子孙级人员
export function findDescendantUsersByDepartmentId(deptId) {
  return request({
      url: '/web/system/user/findDescendantUsersByDepartmentId/'+ deptId,
      method: 'get',
  })
}




export function getInfoById(id){
    return request({
      url: '/web/dept/getInfoById/'+ id,
      method: 'get'
    })
}

export function saveDeptConfig(data) {
    return request({
        url: '/web/deptConfig/save',
        method: 'post',
        data
    })
}

//根据parentId查询子辖区部门id
export function getDeptListByPid(pid) {
    return request({
        url: '/web/dept/list?pid=' + pid,
        method: 'get'
    })
}

export function getAppResourceList(deptId) {
    return request({
        url: `/web/dept/getAppResourceList/${deptId}`,
        method: 'get'
    })
}

export function getDeptResourceIdListV2(deptId) {
  return request({
      url: `web/dept/getDeptResourceIdListV2/${deptId}`,
      method: 'get'
  })
}



export function updateStatus(data) {
    return request({
        url: `/web/dept/updateStatus/`,
        method: 'post',
        data
    })
}
//批量修改父级辖区
export function updateManyDeptIds(data) {
  return request({
    url: '/web/dept/updateManyDeptIds',
    method: 'POST',
    data
  })
}

//复制辖区配置信息
export function batchCopyDeptDetail(data) {
  return request({
    url: '/web/dept/batchCopyDeptDetail',
    method: 'POST',
    data
  })
}

//批量修改总览样式
export function batchDeptDashboardId(data) {
  return request({
    url: '/web/dept/batchDeptDashboardId',
    method: 'POST',
    data
  })
}

//批量修改总览标题
export function batchDeptTitle(data) {
  return request({
    url: '/web/dept/batchDeptTitle',
    method: 'POST',
    data
  })
}


//获取辖区配置信息
export function searchDeptById(id) {
  return request({
    url: 'app/v1/dept/searchDeptById/' + id,
    method: 'get',
  })
}

//获取辖区配置信息
export function batchDeleteDept(data) {
  return request({
    url: '/web/dept/batchDeleteDept',
    method: 'post',
    data
  })
}


//批量修改账号信息
export function batchUpdateRole(data) {
  return request({
    url: 'web/system/user/batchUpdateRole',
    method: 'post',
    data
  })
}





//辖区账号列表
export function selectAdminiStratorUserList(data) {
  return request({
    url: 'web/system/user/selectAdminiStratorUserList',
    method: 'post',
    data
  })
}

//辖区树形数据
export function superiorDepartmentList(data) {
  return request({
    url: 'app/v1/dept/superiorDepartmentList',
    method: 'post',
    data
  })
}


// 批量修改音视频权限
export function batchStatusDept(data) {
  return request({
    url: '/web/dept/batchStatusDept',
    method: 'post',
    data
  });
}


// 批量APP主题色
export function batchDeptSystemColor(data) {
  return request({
    url: '/web/dept/batchDeptSystemColor',
    method: 'post',
    data
  });
}


//修改部门信息
export function updateDeptConfig(data) {
  return request({
    url: 'web/deptConfig/updateDeptConfig',
    method: 'post',
    data
  })
}

export function allIOStatusDept(data) {
  return request({
    url: 'web/deptConfig/allIOStatusDept',
    method: 'post',
    data
  })
}
export function batchUpdateWatermarkContent(data) {
  return request({
    url: 'web/deptConfig/batchUpdateWatermarkContent',
    method: 'post',
    data
  })
}

// export function saveDeptLogo(deptId,data) {
//   return request({
//     url: `/web/dept/saveDeptLogo/${deptId}`,
//     method: 'post',
//     data
//   })
// }
export function updateDeptLogo(id, deptLogo) {
    const data = {
        id,
        deptLogo,
    }
    return request({
        url: '/web/dept/updateDeptLogo',
        method: 'post',
        data
    })
}
export function updateDeptLogos(data) {
  return request({
    url: '/web/dept/updateDeptLogos',
    method: 'post',
    data
  })
}

export function updatbackgroundLogo(id, backgroundLogo) {
    const data = {
        id,
        backgroundLogo,
    }
    return request({
        url: '/web/dept/updatbackgroundLogo',
        method: 'post',
        data
    })
}

export function updattestBackground(id, testBackground) {
    const data = {
        id,
        testBackground,
    }
    return request({
        url: '/web/dept/updattestBackground',
        method: 'post',
        data
    })
}

export function updatbackgroundColor(id, backgroundColor) {
    const data = {
        id,
        backgroundColor,
    }
    return request({
        url: '/web/dept/updatbackgroundColor',
        method: 'post',
        data
    })
}

export function batchUpdateLogo(data) {
  return request({
    url: '/web/dept/batchUpdateLogo',
    method: 'post',
    data
  })
}

export function generateQRCode(id) {
  return request({
    url: '/web/register/qrcode?deptId=' + id,
    method: 'post'
  })
}

export function deleteQRCode(id) {
  return request({
    url: '/web/register/qrcode?deptId=' + id,
    method: 'delete'
  })
}

export function getDeptResourceIdList(deptId) {
    return request({
        url: '/web/dept/getDeptResourceIdList?deptId=' + deptId,
        method: 'get'
    })
}

export function getResourceNameList(deptId) {
    return request({
        url: '/web/dept/getResourceNameList?deptId=' + deptId,
        method: 'get'
    })
}
export function setMenuAlias(data) {
    return request({
        url: '/web/appMenuAlias/setAlias',
        method: 'post',
      data
    })
}

export function updateResourceName(data) {
    return request({
        url: '/web/dept/updateResourceName',
        method: 'post',
        data
    })
}

export function saveDeptAppResource(deptId, resources) {
    const data = {
        deptId,
        resources
    }
    return request({
        url: '/web/dept/saveDeptAppResource',
        method: 'post',
        data
    })
}
export function saveDeptIdsAppResource(data) {
    return request({
        url: '/web/dept/saveDeptIdsAppResource',
        method: 'post',
        data
    })
}

/**
 * 批量删除二维码
 * @param data
 * @returns {*}
 */
export function batchDeleteGrcode(data) {
    return request({
        url: '/web/register/batchDeleteGrcode',
        method: 'post',
        data
    })
}

/**
 * 批量生成二维码
 * @param data
 * @returns {*}
 */
export function batchInsertQrcode(data) {
    return request({
        url: '/web/register/batchInsertQrcode',
        method: 'post',
        data
    })
}
export function getDeptConfigText(configType, content, isShow) {
    const data = {
        configType
    }
    return request({
        url: '/web/deptConfig/getDeptConfigText',
        method: 'post',
        data
    })
}

export function saveDeptConfigText(configType, content, isShow) {
    const data = {
        configType,
        content,
        isShow,
    }
    return request({
        url: '/web/deptConfig/saveConfigText',
        method: 'post',
        data
    })
}

export function getDeptTreeByType(deptId, type) {
  return request({
    url: '/web/dept/getDeptTreeByType?deptId='+deptId+"&type="+type,
    method: 'get'
  })
}
