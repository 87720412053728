var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "el-form",
          {
            ref: "myForm",
            attrs: {
              id: "myForm",
              model: _vm.params,
              rules: _vm.rules,
              "label-position": _vm.labelPosition,
              "label-width": _vm.labelWidth ? _vm.labelWidth + "px" : "",
              size: _vm.size,
              "hide-required-asterisk": _vm.hideRequiredAsterisk,
            },
          },
          [
            _c(
              "el-row",
              { attrs: { gutter: 10 } },
              [
                _vm._l(_vm.items, function (item, index) {
                  return [
                    item.itemType === "hidden"
                      ? [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.params[item.prop],
                                expression: "params[item.prop]",
                              },
                            ],
                            key: index * 100,
                            attrs: { date: "", type: "hidden" },
                            domProps: { value: _vm.params[item.prop] },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.params,
                                  item.prop,
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      : [
                          _c(
                            "el-col",
                            {
                              key: index,
                              attrs: { span: item.span || _vm.span },
                            },
                            [
                              (item.visible == undefined ? true : item.visible)
                                ? _c(
                                    "el-form-item",
                                    {
                                      ref: item.prop + "_item",
                                      refInFor: true,
                                      style: {
                                        animationDelay: `${(index / 2) * 0.1}s`,
                                      },
                                      attrs: {
                                        id: item.prop + "_item",
                                        label: item.label,
                                        prop: item.prop,
                                      },
                                    },
                                    [
                                      item.itemType === "t-select"
                                        ? [
                                            _c("t-select", {
                                              ref: item.prop,
                                              refInFor: true,
                                              attrs: {
                                                id: item.prop,
                                                isPopover: item.isPopover,
                                                name: item.prop,
                                                placeholder: item.placeholder,
                                                options: item.options,
                                                multiple: item.multiple,
                                                clearable:
                                                  item.clearable || true,
                                                filterable:
                                                  item.filterable || true,
                                                "value-key": item.valueKey,
                                                "label-key": item.labelKey,
                                                "default-value":
                                                  item.defaultValue,
                                                type: item.type,
                                                "valset-id": item.valsetId,
                                                url: item.url,
                                                params: item.params,
                                                fetch: item.fetch,
                                                "auto-selected":
                                                  item.autoSelected,
                                              },
                                              on: { change: _vm.tGroupChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "t-treeselect"
                                        ? [
                                            _c("t-treeselect", {
                                              ref: item.prop,
                                              refInFor: true,
                                              style:
                                                _vm.itemStyle +
                                                (item.itemWidth
                                                  ? `width: ${item.itemWidth}px;`
                                                  : ""),
                                              attrs: {
                                                SetDeptId: item.SetDeptId,
                                                defaultValue: item.defaultValue,
                                                id: item.prop,
                                                name: item.prop,
                                                size: item.size
                                                  ? item.size
                                                  : _vm.size,
                                                multiple: item.multiple,
                                                disabled: item.disabled,
                                                placeholder: item.placeholder,
                                                options: item.options,
                                                type: item.type,
                                                "valset-id": item.valsetId,
                                                url: item.url,
                                                params: item.params,
                                                fetch: item.fetch,
                                                "auto-selected":
                                                  item.autoSelected,
                                                "class-name": item.className,
                                                clearable: "",
                                              },
                                              on: { select: _vm.tGroupChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "t-input"
                                        ? [
                                            _c(
                                              "t-input",
                                              {
                                                ref: item.prop,
                                                refInFor: true,
                                                style:
                                                  _vm.itemStyle +
                                                  (item.itemWidth
                                                    ? `width: ${item.itemWidth}px;`
                                                    : ""),
                                                attrs: {
                                                  id: item.prop,
                                                  name: item.prop,
                                                  size: item.size
                                                    ? item.size
                                                    : _vm.size,
                                                  readonly: item.readonly,
                                                  disabled: item.disabled,
                                                  placeholder: item.placeholder,
                                                  minlength: item.minLength,
                                                  maxlength: item.maxlength,
                                                  clearable:
                                                    item.clearable || true,
                                                  type: item.type || "text",
                                                  min: item.min,
                                                  max: item.max,
                                                  "prefix-icon":
                                                    item.prefixIcon,
                                                  "suffix-icon":
                                                    item.suffixIcon,
                                                },
                                                on: {
                                                  change: _vm.controlChange,
                                                },
                                                model: {
                                                  value: _vm.params[item.prop],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.params,
                                                      item.prop,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "params[item.prop]",
                                                },
                                              },
                                              [
                                                item.slot
                                                  ? _c(
                                                      "template",
                                                      { slot: item.slot },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.slotText)
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ]
                                        : item.itemType === "select"
                                        ? [
                                            _c(
                                              "el-select",
                                              {
                                                ref: item.prop,
                                                refInFor: true,
                                                attrs: {
                                                  id: item.prop,
                                                  name: item.prop,
                                                  placeholder: item.placeholder,
                                                  multiple: item.multiple,
                                                  clearable:
                                                    item.clearable || true,
                                                  filterable:
                                                    item.filterable || true,
                                                  "value-key": item.valueKey,
                                                },
                                                on: {
                                                  change: _vm.controlChange,
                                                },
                                                model: {
                                                  value: _vm.params[item.prop],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.params,
                                                      item.prop,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "params[item.prop]",
                                                },
                                              },
                                              _vm._l(
                                                item.options,
                                                function (option, idx) {
                                                  return _c("el-option", {
                                                    key: idx,
                                                    attrs: {
                                                      label: option.label,
                                                      value: option.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        : item.itemType === "t-checkbox"
                                        ? [
                                            _c("t-checkbox", {
                                              ref: item.prop,
                                              refInFor: true,
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                options: item.options,
                                                "value-key": item.valueKey,
                                                "label-key": item.labelKey,
                                                "default-value":
                                                  item.defaultValue,
                                                type: item.type,
                                                "valset-id": item.valsetId,
                                                url: item.url,
                                                params: item.params,
                                                fetch: item.fetch,
                                              },
                                              on: { change: _vm.tGroupChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "t-radio"
                                        ? [
                                            _c("t-radio", {
                                              ref: item.prop,
                                              refInFor: true,
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                disabled: item.disabled,
                                                options: item.options,
                                                "value-key": item.valueKey,
                                                "label-key": item.labelKey,
                                                "default-value":
                                                  item.defaultValue,
                                                type: item.type,
                                                "valset-id": item.valsetId,
                                                url: item.url,
                                                params: item.params,
                                                fetch: item.fetch,
                                              },
                                              on: { change: _vm.tGroupChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                            item.tooltip
                                              ? _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: item.tooltip,
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-question",
                                                      staticStyle: {
                                                        "margin-left": "15px",
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        : item.itemType === "switch"
                                        ? [
                                            _c("el-switch", {
                                              ref: item.prop,
                                              refInFor: true,
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                width: item.width,
                                                "active-color":
                                                  item.activeColor,
                                                "inactive-color":
                                                  item.inactiveColor,
                                                "active-value":
                                                  item.activeValue,
                                                "inactive-value":
                                                  item.inactiveValue,
                                                "active-text": item.activeText,
                                                "inactive-text":
                                                  item.inactiveText,
                                                "active-icon-class":
                                                  item.activeIconClass,
                                                "inactive-icon-class":
                                                  item.inactiveIconClass,
                                              },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "checkbox"
                                        ? [
                                            _c("el-checkbox", {
                                              ref: item.prop,
                                              refInFor: true,
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "checkboxGroup"
                                        ? [
                                            _c(
                                              "el-checkbox-group",
                                              {
                                                ref: item.prop,
                                                refInFor: true,
                                                on: {
                                                  change: _vm.controlChange,
                                                },
                                                model: {
                                                  value: _vm.params[item.prop],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.params,
                                                      item.prop,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "params[item.prop]",
                                                },
                                              },
                                              _vm._l(
                                                item.options,
                                                function (option, idx) {
                                                  return _c(
                                                    "el-checkbox",
                                                    {
                                                      key: idx,
                                                      attrs: {
                                                        label: option.value,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(option.label)
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        : item.itemType === "radio" ||
                                          item.itemType === "radioGroup"
                                        ? [
                                            _c(
                                              "el-radio-group",
                                              {
                                                ref: item.prop,
                                                refInFor: true,
                                                on: {
                                                  change: _vm.controlChange,
                                                },
                                                model: {
                                                  value: _vm.params[item.prop],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.params,
                                                      item.prop,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "params[item.prop]",
                                                },
                                              },
                                              _vm._l(
                                                item.options,
                                                function (option, idx) {
                                                  return _c(
                                                    "el-radio",
                                                    {
                                                      key: idx,
                                                      attrs: {
                                                        label: option.value,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(option.label)
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        : item.itemType === "date"
                                        ? [
                                            _c("el-date-picker", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              style:
                                                _vm.itemStyle +
                                                (item.itemWidth
                                                  ? `width: ${item.itemWidth}px;`
                                                  : ""),
                                              attrs: {
                                                id: item.prop,
                                                type: "date",
                                                "value-format": "yyyy-MM-dd",
                                                name: item.prop,
                                                placeholder: item.placeholder,
                                                size: item.size
                                                  ? item.size
                                                  : _vm.size,
                                                clearable:
                                                  item.clearable || true,
                                                disabled: item.disabled,
                                                readonly: item.readonly,
                                                editable: item.editable,
                                                "picker-options":
                                                  item.pickerOptions || {},
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "year"
                                        ? [
                                            _c("el-date-picker", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              style:
                                                _vm.itemStyle +
                                                (item.itemWidth
                                                  ? `width: ${item.itemWidth}px;`
                                                  : ""),
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                placeholder: item.placeholder,
                                                type: "year",
                                                "value-format": "yyyy",
                                                size: item.size
                                                  ? item.size
                                                  : _vm.size,
                                                clearable:
                                                  item.clearable || true,
                                                disabled: item.disabled,
                                                readonly: item.readonly,
                                                editable: item.editable,
                                                "picker-options":
                                                  item.pickerOptions || {},
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "month"
                                        ? [
                                            _c("el-date-picker", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              style:
                                                _vm.itemStyle +
                                                (item.itemWidth
                                                  ? `width: ${item.itemWidth}px;`
                                                  : ""),
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                placeholder: item.placeholder,
                                                type: "month",
                                                "value-format": "yyyy-MM",
                                                size: item.size
                                                  ? item.size
                                                  : _vm.size,
                                                clearable:
                                                  item.clearable || true,
                                                disabled: item.disabled,
                                                readonly: item.readonly,
                                                editable: item.editable,
                                                "picker-options":
                                                  item.pickerOptions || {},
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "week"
                                        ? [
                                            _c("el-date-picker", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              style:
                                                _vm.itemStyle +
                                                (item.itemWidth
                                                  ? `width: ${item.itemWidth}px;`
                                                  : ""),
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                placeholder: item.placeholder,
                                                type: "week",
                                                "value-format": "yyyy-WW",
                                                size: item.size
                                                  ? item.size
                                                  : _vm.size,
                                                clearable:
                                                  item.clearable || true,
                                                disabled: item.disabled,
                                                readonly: item.readonly,
                                                editable: item.editable,
                                                "picker-options":
                                                  item.pickerOptions || {},
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "dates"
                                        ? [
                                            _c("el-date-picker", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              style:
                                                _vm.itemStyle +
                                                (item.itemWidth
                                                  ? `width: ${item.itemWidth}px;`
                                                  : ""),
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                placeholder: item.placeholder,
                                                type: "dates",
                                                "value-format": "yyyy-MM-dd",
                                                size: item.size
                                                  ? item.size
                                                  : _vm.size,
                                                clearable:
                                                  item.clearable || true,
                                                disabled: item.disabled,
                                                readonly: item.readonly,
                                                editable: item.editable,
                                                "picker-options":
                                                  item.pickerOptions || {},
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "daterange"
                                        ? [
                                            _c("el-date-picker", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              style:
                                                _vm.itemStyle +
                                                (item.itemWidth
                                                  ? `width: ${item.itemWidth}px;`
                                                  : ""),
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                type: "daterange",
                                                "range-separator": "-",
                                                "value-format": "yyyy-MM-dd",
                                                "start-placeholder":
                                                  item.startPlaceholder ||
                                                  "开始日期",
                                                "end-placeholder":
                                                  item.endPlaceholder ||
                                                  "结束日期",
                                                size: item.size
                                                  ? item.size
                                                  : _vm.size,
                                                clearable:
                                                  item.clearable || true,
                                                disabled: item.disabled,
                                                readonly: item.readonly,
                                                editable: item.editable,
                                                "picker-options":
                                                  item.pickerOptions || {},
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "monthrange"
                                        ? [
                                            _c("el-date-picker", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              style:
                                                _vm.itemStyle +
                                                (item.itemWidth
                                                  ? `width: ${item.itemWidth}px;`
                                                  : ""),
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                type: "monthrange",
                                                "range-separator": "-",
                                                "value-format": "yyyy-MM",
                                                "start-placeholder":
                                                  item.startPlaceholder ||
                                                  "开始月份",
                                                "end-placeholder":
                                                  item.endPlaceholder ||
                                                  "结束月份",
                                                size: item.size
                                                  ? item.size
                                                  : _vm.size,
                                                clearable:
                                                  item.clearable || true,
                                                disabled: item.disabled,
                                                readonly: item.readonly,
                                                editable: item.editable,
                                                "picker-options":
                                                  item.pickerOptions || {},
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "datetime"
                                        ? [
                                            _c("el-date-picker", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              style:
                                                _vm.itemStyle +
                                                (item.itemWidth
                                                  ? `width: ${item.itemWidth}px;`
                                                  : ""),
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                type: "datetime",
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                "range-separator": "-",
                                                "start-placeholder":
                                                  item.startPlaceholder ||
                                                  "开始日期",
                                                "end-placeholder":
                                                  item.endPlaceholder ||
                                                  "结束日期",
                                                size: item.size
                                                  ? item.size
                                                  : _vm.size,
                                                clearable:
                                                  item.clearable || true,
                                                disabled: item.disabled,
                                                readonly: item.readonly,
                                                editable: item.editable,
                                                "picker-options":
                                                  item.pickerOptions || {},
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "datetimerange"
                                        ? [
                                            _c("el-date-picker", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              style:
                                                _vm.itemStyle +
                                                (item.itemWidth
                                                  ? `width: ${item.itemWidth}px;`
                                                  : ""),
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                type: "datetimerange",
                                                "range-separator": "-",
                                                "value-format":
                                                  "yyyy-MM-dd HH:mm:ss",
                                                "start-placeholder":
                                                  item.startPlaceholder ||
                                                  "开始时间",
                                                "end-placeholder":
                                                  item.endPlaceholder ||
                                                  "结束时间",
                                                size: item.size
                                                  ? item.size
                                                  : _vm.size,
                                                clearable:
                                                  item.clearable || true,
                                                disabled: item.disabled,
                                                readonly: item.readonly,
                                                editable: item.editable,
                                                "default-time":
                                                  item.defaultTime,
                                                "picker-options":
                                                  item.pickerOptions || {},
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "time"
                                        ? [
                                            _c("el-time-select", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                "value-format": "HH:mm",
                                                "picker-options":
                                                  item.pickerOptions || {
                                                    start: "00:00",
                                                    step: "00:30",
                                                    end: "23:30",
                                                  },
                                                clearable:
                                                  item.clearable || true,
                                                readonly: item.readonly,
                                                disabled: item.disabled,
                                                placeholder:
                                                  item.placeholder ||
                                                  "选择时间",
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "timerange"
                                        ? [
                                            _c("el-time-picker", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                "is-range": "",
                                                "range-separator": "-",
                                                placeholder: item.placeholder,
                                                clearable:
                                                  item.clearable || true,
                                                readonly: item.readonly,
                                                disabled: item.disabled,
                                                "start-placeholder":
                                                  item.startPlaceholder ||
                                                  "开始时间",
                                                "end-placeholder":
                                                  item.endPlaceholder ||
                                                  "结束时间",
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "number"
                                        ? [
                                            item.tip
                                              ? _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: item.tip,
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input-number", {
                                                      ref: item.prop,
                                                      refInFor: true,
                                                      staticClass: "width100",
                                                      attrs: {
                                                        id: item.prop,
                                                        name: item.prop,
                                                        min: item.min || 0,
                                                        max: item.max,
                                                        step: item.step,
                                                        clearable:
                                                          item.clearable ||
                                                          true,
                                                        readonly: item.readonly,
                                                        disabled: item.disabled,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.controlChange,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.params[item.prop],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.params,
                                                            item.prop,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "params[item.prop]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c("el-input-number", {
                                                  ref: item.prop,
                                                  refInFor: true,
                                                  staticClass: "width",
                                                  attrs: {
                                                    id: item.prop,
                                                    name: item.prop,
                                                    min: item.min || 0,
                                                    max: item.max,
                                                    step: item.step,
                                                    clearable:
                                                      item.clearable || true,
                                                    readonly: item.readonly,
                                                    disabled: item.disabled,
                                                  },
                                                  on: {
                                                    change: _vm.controlChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.params[item.prop],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.params,
                                                        item.prop,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "params[item.prop]",
                                                  },
                                                }),
                                          ]
                                        : item.itemType === "textarea"
                                        ? [
                                            _c("el-input", {
                                              ref: item.prop,
                                              refInFor: true,
                                              staticClass: "width100",
                                              attrs: {
                                                id: item.prop,
                                                titlt: "item.prop",
                                                name: item.prop,
                                                "show-word-limit": "",
                                                type: "textarea",
                                                placeholder: item.placeholder,
                                                maxlength: item.maxlength,
                                                rows: item.rows,
                                                clearable:
                                                  item.clearable || true,
                                                readonly: item.readonly,
                                                disabled: item.disabled,
                                                autosize: { minRows: 2 },
                                              },
                                              on: { change: _vm.controlChange },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "lagrulValue"
                                        ? [
                                            _vm.params[item.prop].split(";")
                                              .length !== 1
                                              ? _c(
                                                  "span",
                                                  _vm._l(
                                                    _vm.params[item.prop]
                                                      .split(";")
                                                      .slice(
                                                        0,
                                                        _vm.params[
                                                          item.prop
                                                        ].split(";").length - 1
                                                      ),
                                                    function (items, index) {
                                                      return _c(
                                                        "p",
                                                        {
                                                          key: items,
                                                          staticClass: "alert",
                                                          attrs: {
                                                            id: item.prop,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(items) + " "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "san",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.delData(
                                                                      item.prop,
                                                                      index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("x")]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _c("span", [
                                                  _vm._v(
                                                    "添加常用语，下发任务更快哦~"
                                                  ),
                                                ]),
                                            item.intButtomCon
                                              ? [
                                                  item.intButtomCon.type ===
                                                  "button"
                                                    ? [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  item
                                                                    .intButtomCon
                                                                    .otherInt
                                                                    .length ===
                                                                  0,
                                                                expression:
                                                                  "(item.intButtomCon.otherInt.length === 0)",
                                                              },
                                                            ],
                                                            style: {
                                                              width:
                                                                item
                                                                  .intButtomCon
                                                                  .width,
                                                            },
                                                            on: {
                                                              click:
                                                                item
                                                                  .intButtomCon
                                                                  .events.click,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item
                                                                  .intButtomCon
                                                                  .text
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          item.intButtomCon
                                                            .otherInt,
                                                          function (
                                                            btn,
                                                            index
                                                          ) {
                                                            return [
                                                              Array.isArray(btn)
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      key: btn.nid,
                                                                    },
                                                                    [
                                                                      _vm._l(
                                                                        btn,
                                                                        function (
                                                                          chil,
                                                                          ind
                                                                        ) {
                                                                          return [
                                                                            chil.itemType ===
                                                                            "input"
                                                                              ? _c(
                                                                                  "el-input",
                                                                                  {
                                                                                    key: chil.nid,
                                                                                    style:
                                                                                      {
                                                                                        width:
                                                                                          chil.width,
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        clearable:
                                                                                          item.clearable ||
                                                                                          true,
                                                                                        type: "text",
                                                                                      },
                                                                                    model:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .params[
                                                                                            chil
                                                                                              .prop
                                                                                          ],
                                                                                        callback:
                                                                                          function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.$set(
                                                                                              _vm.params,
                                                                                              chil.prop,
                                                                                              $$v
                                                                                            )
                                                                                          },
                                                                                        expression:
                                                                                          "params[chil.prop]",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : chil.itemType ===
                                                                                "button"
                                                                              ? _c(
                                                                                  "el-button",
                                                                                  {
                                                                                    key:
                                                                                      chil.nid +
                                                                                      ind,
                                                                                    style:
                                                                                      {
                                                                                        width:
                                                                                          chil.width,
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.updateParams(
                                                                                            item.prop,
                                                                                            btn[0]
                                                                                              .prop
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        chil.text
                                                                                      ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        }
                                                                      ),
                                                                    ],
                                                                    2
                                                                  )
                                                                : btn instanceof
                                                                  Object
                                                                ? _c(
                                                                    "el-input",
                                                                    {
                                                                      key: index,
                                                                      attrs: {
                                                                        clearable:
                                                                          item.clearable ||
                                                                          true,
                                                                        type: "text",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          }
                                                        ),
                                                      ]
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                          ]
                                        : item.itemType === "editor"
                                        ? [
                                            _c("Tinymce", {
                                              ref: item.prop,
                                              refInFor: true,
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                height: item.height,
                                              },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                      item.itemType === "t-upload-pic"
                                        ? [
                                            _c("t-upload-pic", {
                                              ref: item.prop,
                                              refInFor: true,
                                              attrs: {
                                                id: item.prop,
                                                name: item.name,
                                                placeholder: item.placeholder,
                                                "list-type": item.listType,
                                                action: item.action,
                                                accept: item.accept,
                                                headers: item.headers,
                                                data: item.data,
                                                multiple: item.multiple,
                                                limit: item.limit,
                                                "auto-upload": item.autoUpload,
                                                disabled: item.disabled,
                                                "file-list": item.fileList,
                                                "on-preview": item.onPreview,
                                                "on-error": item.onError,
                                                "on-progress": item.onProgress,
                                                "on-change": item.onChange,
                                                "before-remove":
                                                  item.beforeRemove,
                                                "on-remove": item.onRemove,
                                                "on-success": item.onSuccess,
                                                "before-upload":
                                                  item.beforeUpload,
                                                "http-request":
                                                  item.httpRequest,
                                                "on-exceed": item.onExceed,
                                              },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "t-color-input"
                                        ? [
                                            _c("t-color-input", {
                                              ref: item.prop,
                                              refInFor: true,
                                              attrs: {
                                                id: item.prop,
                                                name: item.prop,
                                                placeholder: item.placeholder,
                                                minlength: item.minLength,
                                                maxlength: item.maxlength,
                                                clearable:
                                                  item.clearable || true,
                                                readonly: item.readonly,
                                                disabled: item.disabled,
                                              },
                                              model: {
                                                value: _vm.params[item.prop],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.params,
                                                    item.prop,
                                                    $$v
                                                  )
                                                },
                                                expression: "params[item.prop]",
                                              },
                                            }),
                                          ]
                                        : item.itemType === "t-file"
                                        ? _vm._l(
                                            _vm.params[item.prop],
                                            function (file, i) {
                                              return _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "inline-block",
                                                  },
                                                },
                                                [
                                                  file.contextType.indexOf(
                                                    "image"
                                                  ) > -1
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c("el-image", {
                                                            staticStyle: {
                                                              width: "140px",
                                                              height: "140px",
                                                            },
                                                            attrs: {
                                                              src: file.fileUrl,
                                                              "preview-src-list":
                                                                [file.fileUrl],
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : file.contextType.indexOf(
                                                        "video"
                                                      ) > -1
                                                    ? _c("div", [
                                                        _c("video", {
                                                          staticStyle: {
                                                            width: "140px",
                                                            height: "140px",
                                                          },
                                                          attrs: {
                                                            src: file.fileUrl,
                                                            controls:
                                                              "controls",
                                                          },
                                                        }),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            }
                                          )
                                        : item.itemType === "input"
                                        ? [
                                            _c(
                                              "el-input",
                                              {
                                                ref: "input",
                                                refInFor: true,
                                                staticStyle: { "z-index": "9" },
                                                attrs: {
                                                  id: item.prop,
                                                  name: item.prop,
                                                  placeholder: item.placeholder,
                                                  minlength: item.minLength,
                                                  "show-password":
                                                    item.password,
                                                  autocomplete: "new-password",
                                                  maxlength: item.maxlength,
                                                  clearable:
                                                    item.clearable || true,
                                                  readonly: item.readonly,
                                                  disabled: item.disabled,
                                                  type: item.type || "text",
                                                  min: item.min,
                                                  max: item.max,
                                                  "prefix-icon":
                                                    item.prefixIcon,
                                                  "suffix-icon":
                                                    item.suffixIcon,
                                                  "show-word-limit": "",
                                                },
                                                on: {
                                                  change: _vm.controlChange,
                                                  focus: function ($event) {
                                                    return _vm.focusSelect(
                                                      item.isSelect,
                                                      index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: _vm.params[item.prop],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.params,
                                                      item.prop,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "params[item.prop]",
                                                },
                                              },
                                              [
                                                item.slot
                                                  ? _c(
                                                      "template",
                                                      { slot: item.slot },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.slotText)
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                  ]
                }),
                _vm._t("append"),
              ],
              2
            ),
          ],
          1
        ),
        _vm.btns && _vm.btns.length > 0
          ? _c(
              "el-row",
              { attrs: { gutter: 5, justify: "center" } },
              [
                _c(
                  "el-col",
                  { staticClass: "colBtn" },
                  [
                    _vm._l(_vm.btns, function (btn, index) {
                      return [
                        _vm.checkBtnPrivage(btn.code)
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "preventReClick",
                                    rawName: "v-preventReClick",
                                  },
                                ],
                                key: index,
                                attrs: {
                                  size: btn.size,
                                  loading: btn.loading,
                                  type: btn.type || "primary",
                                  plain: btn.plain || false,
                                  round: btn.round || false,
                                  circle: btn.circle || false,
                                  disabled: btn.disabled || false,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.btnClick(
                                      btn.event,
                                      btn.isValidate
                                    )
                                  },
                                },
                              },
                              [
                                btn.icon
                                  ? _c("i", { class: btn.icon })
                                  : _vm._e(),
                                btn.isSvgIcon
                                  ? _c("svg-icon", {
                                      attrs: { "icon-class": btn.icon },
                                    })
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(btn.label) + " "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }